<template>
  <div
    id="top-bar"
    :class="{
      'showing-partial-error': showingPartialError,
      'showing-full-error': showingFullError,
    }"
  >
    <div
      v-if="showMenuButton"
      class="menu-button"
      :class="{
        'vehicle-is-moving': vehicleIsMoving,
      }"
      @click="onMenuButtonTap"
    >
      <inline-svg name="menu"></inline-svg>
    </div>
    <IconButton
      v-if="showBackButton"
      iconName="arrow-back"
      :customClasses="{ 'back-button': true }"
      @click="onBackButtonTap"
    />
  </div>
</template>

<script>
import { track } from '../analytics/segment';
import { NAV_VIEWS } from '../store/modules/navigation';

import IconButton from './common/IconButton.vue';

export default {
  components: {
    IconButton,
  },
  props: {
    showingPartialError: {
      type: Boolean,
      default: false,
    },
    showingFullError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    operatorLoginShouldShowBackButton() {
      return (
        this.$store.getters['navigation/activeView'] === NAV_VIEWS.MY_SWIFTLY ||
        !this.$store.getters.showLoginOverlay
      );
    },
    vehicleIsMoving() {
      return this.$store.getters.vehicleIsMoving;
    },
    showMenuButton() {
      switch (this.$route.name) {
        case 'agency-selection':
          return false;
        case 'my-swiftly':
          return false;
        case 'settings':
          return false;
        case 'operator-login':
          return !this.operatorLoginShouldShowBackButton;
        case 'permanent-vehicle-assignment':
          return false;
        default:
          return true;
      }
    },
    showBackButton() {
      switch (this.$route.name) {
        case 'my-swiftly':
          return true;
        case 'settings':
          return true;
        case 'operator-login':
          return this.operatorLoginShouldShowBackButton;
        case 'permanent-vehicle-assignment':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    onMenuButtonTap() {
      if (this.vehicleIsMoving) {
        return;
      }
      this.$router.push({ name: 'my-swiftly' });
    },
    onBackButtonTap() {
      const isShowingConfirmationDialog =
        !this.$store.getters.showLoginSelections;
      if (this.$route.name === 'operator-login') {
        if (isShowingConfirmationDialog) {
          this.$store.commit('showLoginSelections', true);
        } else {
          this.$store.commit('showLoginOverlay', true);
        }
      } else {
        if (this.$route.name === 'permanent-vehicle-assignment') {
          track('permanent-vehicle-assignment-cancelled');
        }
        this.$router.back();
      }
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_variables.styl"
@require "../styl/_colors.styl"

#top-bar {
    z-index 5
    position fixed
    top 0
    right 0
    width 100%
    text-align center

    display flex
    flex-direction column
    justify-content center

    &.showing-partial-error:not(.showing-full-error) {
        top $partial-error-height
    }

    .menu-button {
        position absolute
        top 2rem
        left 2rem
        width 5rem
        height 5rem
        background-color $button-color
        border-radius 50%
        cursor pointer

        display flex
        flex-direction column
        justify-content center

        i {
            font-size 3.6rem
            color $text-default
        }

        &.vehicle-is-moving {
            background-color $outer-space4
            i > svg {
                opacity 0.38
            }
        }
    }

    .back-button {
      position absolute
      top 2rem
      left 2rem
      width 5rem
      height 5rem
      padding 0
    }
}
</style>
