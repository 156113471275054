<template>
  <AnimatedBottomDrawer
    :expandDrawer="showExpandedMessageList"
    @close="closeDrawer"
  >
    <template>
      <ul
        :class="{
          'message-list': true,
          'message-list--expanded': showExpandedMessageList,
        }"
      >
        <li
          v-for="messageItem in messagesList"
          :key="messageItem.message"
          :class="{
            'message-list__item': true,
            'message-list__item--expanded': showExpandedMessageList,
          }"
        >
          <IconButton
            :iconName="messageItem.icon"
            iconClass="message-icon-small"
            :text="messageItem.message"
            :customTextClasses="{ 'message-body': true }"
            @click="
              handleMessageButtonClick(
                messageItem.category,
                messageItem.message,
              )
            "
          />
        </li>
        <li v-if="!showExpandedMessageList" class="message-list__item">
          <IconButton
            iconName="more"
            iconClass="message-icon-small"
            text="All messages"
            :customTextClasses="{ 'message-body': true }"
            @click="expandDrawer"
          />
        </li>
      </ul>
    </template>
  </AnimatedBottomDrawer>
</template>
<script>
import AnimatedBottomDrawer from '../common/AnimatedBottomDrawer.vue';

export default {
  components: { AnimatedBottomDrawer },
  props: {},
  data() {
    return {
      showExpandedMessageList: false,
      cannedMessagesList: [
        {
          category: 'FYI',
          icon: 'bathroom',
          message: 'Bathroom break',
          shouldDisplayInShortList: true,
        },
        {
          category: 'FYI',
          icon: 'farebox',
          message: 'Farebox issues',
          shouldDisplayInShortList: true,
        },
        {
          category: 'FYI',
          icon: 'wheelchair',
          message: 'Wheelchair',
          shouldDisplayInShortList: true,
        },
        {
          category: 'FYI',
          icon: 'traffic',
          message: 'Traffic',
          shouldDisplayInShortList: false,
        },
        {
          category: 'Shift',
          icon: '10-7',
          message: '10-7 stop shift',
          shouldDisplayInShortList: false,
        },
        {
          category: 'Shift',
          icon: '10-8',
          message: '10-8 start shift',
          shouldDisplayInShortList: false,
        },
        {
          category: 'Shift',
          icon: '10-9',
          message: '10-9 back to base',
          shouldDisplayInShortList: false,
        },
      ],
    };
  },
  computed: {
    cannedMessagesShortList() {
      return this.cannedMessagesList.filter(
        (messageItem) => messageItem.shouldDisplayInShortList,
      );
    },
    messagesList() {
      return this.showExpandedMessageList
        ? this.cannedMessagesList
        : this.cannedMessagesShortList;
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close-drawer');
    },
    expandDrawer() {
      this.showExpandedMessageList = !this.showExpandedMessageList;
    },
    handleMessageButtonClick(category, message) {
      this.$emit('send-message', `${category}: ${message}`);
      this.closeDrawer();
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../../styl/_colors.styl"

.message-list {
  margin 3rem 0
  padding 0
  list-style none
  display flex
  flex-wrap wrap

  &--expanded {
    height 100%
    margin 0
  }
}

.message-list__item {
  display flex
  flex-direction column
  align-items center
  justify-content start
  flex 1 2 15%
  button {
    padding 1rem
  }
  >>>.message-body {
    color $text-default
    padding-top 2rem
    font-weight 400
  }
}

.message-list__item--expanded {
  flex-direction row
  justify-content center
  flex 1 2 21%

  button {
    padding 0
    display flex
    flex-direction column
    align-items center
    justify-content center
  }
}
@media screen and (max-width 600px) {
  >>>.message-icon-small {
    height 50px
    width 50px
  }

  >>>.message-body {
    font-size 1.5rem
  }
}

@media screen and (min-width 601px){
  >>>.message-body {
    font-size 2.8rem
  }
}

@media (orientation: landscape) and (max-height 600px) {
  >>>.message-icon-small {
    height 50px
    width 50px
  }

  >>>.message-body {
    font-size 1.5rem
  }
}
</style>
