export const computeHeaderStatus = ({
  headwayStatus,
  isError,
  isHeadway,
  isOffRoute,
  isOnDetour,
  isTimingUnavailable,
  isYetToDepart,
  otpStatus,
  showOtp,
}) => {
  if (isError) {
    return 'error';
  } else if (!showOtp) {
    return null;
  } else if (isYetToDepart) {
    return 'depart';
  } else if (isOffRoute) {
    return 'off-route';
  } else if (isOnDetour) {
    return 'detour';
  } else if (isTimingUnavailable) {
    return 'timing-unavailable';
  } else if (isHeadway && headwayStatus !== 'unknown') {
    return headwayStatus;
  } else if (!isHeadway && otpStatus != null) {
    return otpStatus;
  }
  return null;
};
