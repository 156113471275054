import sleep from '../utils/sleep';

let getters = null;
let _useLocal = !window.isUsingSegment; // Defined (or not) in mainHeader.js

const _isSupportedSegmentOperation = (operationName) => {
  if (typeof window.analytics !== 'object') {
    return false;
  }
  if (typeof window.analytics[operationName] !== 'function') {
    return false;
  }
  return true;
};

const _loadAnalyticsLibrary = async (writeKey) => {
  if (!writeKey) {
    return;
  }
  // Source: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/
  (function () {
    const analytics = (window.analytics = window.analytics || []);
    if (analytics.initialize) {
      return;
    }
    if (analytics.invoked) {
      return;
    }
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
      'addSourceMiddleware',
      'addIntegrationMiddleware',
      'setAnonymousId',
      'addDestinationMiddleware',
    ];
    analytics.factory = function (method) {
      return function () {
        const args = Array.prototype.slice.call(arguments);
        args.unshift(method);
        analytics.push(args);
        return analytics;
      };
    };
    for (let i = 0; i < analytics.methods.length; i++) {
      const key = analytics.methods[i];
      analytics[key] = analytics.factory(key);
    }
    analytics.load = function (key, options) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
      const first = document.getElementsByTagName('script')[0];
      first.parentNode.insertBefore(script, first);
      analytics._loadOptions = options;
    };
    analytics._writeKey = writeKey;
    analytics.SNIPPET_VERSION = '4.13.2';
    analytics.load(writeKey);
    analytics.page();
  })();
  const LOADING_TIMEOUT_MILLISECONDS = 15 * 1000; // 15 seconds
  const CHECK_EVERY_MILLISECONDS = 500;
  let millisecondsWaited = 0;
  while (
    !window.analytics.initialized &&
    millisecondsWaited < LOADING_TIMEOUT_MILLISECONDS
  ) {
    await sleep(CHECK_EVERY_MILLISECONDS);
    millisecondsWaited += CHECK_EVERY_MILLISECONDS;
  }
  return window.analytics.initialized;
};

export const init = async (_getters) => {
  console.log('Initializing Segment...');
  getters = _getters;
  const isNativeEnvironment = window.cordova != null;
  const hasUnusedSegmentKey =
    _useLocal && typeof process.env.SEGMENT_ANALYTICS_KEY === 'string';
  if (isNativeEnvironment && hasUnusedSegmentKey) {
    const analyticsSuccessfullyLoaded = await _loadAnalyticsLibrary(
      process.env.SEGMENT_ANALYTICS_KEY,
    );
    _useLocal = !analyticsSuccessfullyLoaded;
  }
  if (_useLocal) {
    console.log(
      'No SEGMENT_ANALYTICS_KEY present. Segment will log events to console.',
    );
  } else {
    console.log('Segment initialized!');
  }
};

// Adds a green tag prefix to logged statements
export const logLocally = (...args) => {
  console.log(
    '%c [Segment] ',
    'background-color: rgb(71, 184, 129); color: #fff; border-radius: 2px;',
    ...args,
  );
};

export const identifyUser = () => {
  const { uuid, analyticsUserTraits: traits } = getters;
  const userId = uuid || 'unregistered device';
  if (_useLocal) {
    logLocally(`Segment identifying user "${userId}"`, { traits });
    return;
  }
  if (!_isSupportedSegmentOperation('identify')) {
    return;
  }
  window.analytics.identify(userId, traits);
};

export const group = () => {
  const currentAgencyKey = getters.currentAgencyKey;
  if (!currentAgencyKey) {
    return;
  }
  const groupId = currentAgencyKey;
  const traits = { groupType: 'agencyKey', groupValue: currentAgencyKey };
  if (_useLocal) {
    logLocally(`Segment group event "${groupId}"`, { traits });
    return;
  }
  window.analytics.group(groupId, traits);
};

export const track = (eventName, eventProperties = {}) => {
  if (typeof eventName !== 'string' || !eventName) {
    return;
  }
  const properties = {
    ...eventProperties,
    ...getters.analyticsEventProperties,
  };
  if (_useLocal) {
    logLocally(`Segment tracking event "${eventName}"`, properties);
    return;
  }
  if (!_isSupportedSegmentOperation('track')) {
    return;
  }
  window.analytics.track(eventName, properties);
};

export const page = (pageName, properties = {}) => {
  if (typeof pageName !== 'string' || !pageName) {
    return;
  }
  if (_useLocal) {
    logLocally(`Segment page event "${pageName}"`, properties);
    return;
  }
  if (!_isSupportedSegmentOperation('page')) {
    return;
  }
  window.analytics.page(pageName, properties);
};
