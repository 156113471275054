import * as Segment from '../analytics/segment';
import { ONBOARD_APP_AVL_LOGGING } from '../optimizely/feature-flags';

const activeTimeouts = {};

class VueDispatchInterval {
  constructor($store, actionName, delay) {
    this.$store = $store;
    this.actionName = actionName;
    this.delay = delay;
  }
  start() {
    if (activeTimeouts[this.actionName] != null) {
      return;
    }
    activeTimeouts[this.actionName] = setTimeout(this.run.bind(this), 0);
    // Doing this here because we only really care about the first time we start the interval (see the early return above).
    if (
      this.actionName === 'sendLocationUpdate' &&
      this.$store.getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)
    ) {
      Segment.track('location-reporting-started', {
        vehicle: this.$store.getters.currentVehicleId,
      });
    }
  }
  stop() {
    clearTimeout(activeTimeouts[this.actionName]);
    activeTimeouts[this.actionName] = null;
    if (
      this.actionName === 'sendLocationUpdate' &&
      this.$store.getters.userHasFeatureAccess(ONBOARD_APP_AVL_LOGGING)
    ) {
      Segment.track('location-reporting-stopped', {
        vehicle: this.$store.getters.currentVehicleId,
      });
    }
  }
  updateDelay(delay) {
    this.delay = delay;
  }
  async run() {
    const start = performance.now();
    await this.$store.dispatch(this.actionName);
    const duration = performance.now() - start;
    activeTimeouts[this.actionName] = setTimeout(
      this.run.bind(this),
      Math.max(this.delay - duration, 0),
    );
  }
}

export default VueDispatchInterval;
