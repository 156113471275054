export const NAV_VIEWS = {
  MAP: 'map',
  PASSENGER_COUNTING: 'passenger-counting',
  MESSAGES: 'messages',
  MY_SWIFTLY: 'my-swiftly',
  TIMEPOINTS: 'timepoints',
};

const resettableState = () => ({
  activeView: NAV_VIEWS.TIMEPOINTS,
  lastView: undefined,
  viewConfig: {},
});

export const navigation = {
  namespaced: true,
  state: resettableState(),
  mutations: {
    resetState(state) {
      Object.assign(state, resettableState());
    },
    setView(state, view) {
      if (view === state.activeView) {
        return;
      }
      state.lastView = state.activeView;
      state.activeView = view;
    },
    setViewConfig(state, viewConfig) {
      Object.assign(state.viewConfig, viewConfig);
    },
  },
  actions: {
    reset({ commit }) {
      commit('resetState');
    },
    setActiveView({ commit }, view) {
      // When set from native nav bar, the action's argument comes through as an object
      commit('setView', typeof view === 'object' ? view.view : view);
    },
    setViewConfig({ commit }, viewConfig) {
      const sendAppNavEvent = window.NativeAPI?.sendAppNavEvent;
      if (sendAppNavEvent != null) {
        sendAppNavEvent(viewConfig);
      }
      commit('setViewConfig', viewConfig);
    },
  },
  getters: {
    activeView(state) {
      return state.activeView;
    },
    lastView(state) {
      return state.lastView;
    },
  },
};
