const resettableState = () => ({
  passengerCountTotal: 0,
});

export const passengerCounting = {
  namespaced: true,
  state: resettableState(),
  mutations: {
    passengerCountTotal(state, count) {
      state.passengerCountTotal = count;
    },
    resetState(state) {
      Object.assign(state, resettableState());
    },
  },
  actions: {
    increment({ commit, getters }) {
      const { passengerCountTotal } = getters;
      commit('passengerCountTotal', passengerCountTotal + 1);
    },
    decrement({ commit, getters }) {
      const { passengerCountTotal } = getters;
      if (passengerCountTotal > 0) {
        commit('passengerCountTotal', passengerCountTotal - 1);
      }
    },
    reset({ commit }) {
      commit('resetState');
    },
  },
  getters: {
    passengerCountTotal(state) {
      return state.passengerCountTotal;
    },
  },
};
