<template>
  <div class="animated-bottom-drawer">
    <div class="darkening-overlay" @click="closeDrawer" />
    <transition name="slide-drawer-wrapper" @after-leave="onAfterLeave">
      <div
        v-show="showDrawer"
        :class="{
          'drawer': true,
          'drawer--expand': expandDrawer,
        }"
      >
        <button class="drawer__button" @click="closeDrawer">
          <div class="bar" />
        </button>
        <div class="drawer__content">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    expandDrawer: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showDrawer: false,
    };
  },
  mounted() {
    this.showDrawer = true;
  },
  methods: {
    closeDrawer() {
      this.showDrawer = false;
    },
    onAfterLeave() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../../styl/_colors.styl"

.animated-bottom-drawer {
  z-index 4
  position fixed
  top 0
  left 0
  height 100%
  width 100%

  .drawer {
    position fixed
    bottom 0
    left 0
    width 100%
    border-top-left-radius 3rem
    border-top-right-radius 3rem
    background-color $background-color-2
    transition 0.2s
    display flex
    flex-direction column
    justify-content start
  }

  .drawer--expand {
    height 70%
  }

  .drawer__button {
    height 5.5rem
    width 100%
    display flex
    align-items center
    justify-content center
    background-color transparent
    border none
    .bar {
      min-width 15%
      max-width 20%
      height 1.2rem
      background-color $button-color-2
      border-radius 1rem
      border none
    }
  }

  .drawer__content {
    width 100%
    height 100%
  }
}

@media(orientation: portrait) {
  .drawer {
    height 28%
  }
}

@media(orientation: landscape) {
  .drawer {
    height 40%
  }
}

.slide-drawer-wrapper-leave-active,
.slide-drawer-wrapper-enter-active {
  transition 1000s ease
}
.slide-drawer-wrapper-enter ,
.slide-drawer-wrapper-leave-to {
  transform translate(0, 100%)
}
</style>
