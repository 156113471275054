<template>
  <div id="my-swiftly">
    <div class="my-swiftly__header">
      <div class="my-swiftly__heading">My Swiftly</div>
    </div>
    <div class="my-swiftly__items">
      <IconButton
        iconName="settings"
        text="Settings"
        :disabled="isSettingsDisabled"
        :customClasses="{ 'my-swiftly__item': true }"
        :customTextClasses="{ 'my-swiftly__item-text': true }"
        @click="showSettings"
      />
      <IconButton
        v-if="showLogoutOption"
        iconName="exit"
        :text="logoutText"
        :customClasses="{ 'my-swiftly__item': true }"
        :customTextClasses="{ 'my-swiftly__item-text': true }"
        @click="logout"
      />
      <IconButton
        iconName="bug_report"
        text="Diagnostics"
        :customClasses="{ 'my-swiftly__item': true }"
        :customTextClasses="{ 'my-swiftly__item-text': true }"
        @click="toggleDebuggingOverlay"
      />
    </div>
    <DebuggingOverlay
      v-show="showDebuggingOverlay"
      @close-debugging-overlay="toggleDebuggingOverlay"
    />
  </div>
</template>
<script>
import * as Segment from '../analytics/segment';

import DebuggingOverlay from './DebuggingOverlay.vue';

export default {
  components: {
    DebuggingOverlay,
  },
  props: {},
  data() {
    return {
      showDebuggingOverlay: false,
    };
  },
  computed: {
    showBackButton() {
      return this.$route.name !== 'vehicle';
    },
    showLogoutOption() {
      return this.$route.name === 'vehicle';
    },
    currentOperatorDisplayName() {
      return this.$store.getters.currentOperatorDisplayName;
    },
    isSettingsDisabled() {
      return (
        !this.$store.getters.isOnline ||
        this.$store.getters.serverErrorLoginInfo
      );
    },
    logoutText() {
      return this.currentOperatorDisplayName != null
        ? `Log out - ${this.currentOperatorDisplayName}`
        : 'Log out';
    },
  },
  methods: {
    showSettings() {
      if (this.$route.name === 'settings') {
        return;
      }
      this.$router.push({
        name: 'settings',
        params: {
          agencyKey: this.$store.getters.currentAgencyKey,
          vehicleId: this.$store.getters.currentVehicleId,
          blockId: this.$store.getters.currentBlockId,
        },
      });
    },
    logout() {
      const reason = 'User tapped logout from menu';
      this.$store.dispatch('logoutVehicle', {
        vc: this,
        reason,
      });
    },
    toggleDebuggingOverlay() {
      const visible = !this.showDebuggingOverlay;
      this.showDebuggingOverlay = visible;
      Segment.track('toggle-debug-overlay', { visible });
    },
  },
};
</script>
<style lang="stylus" scoped>
@require "../styl/_colors.styl"
@require "../styl/_variables.styl"

#my-swiftly {
  display flex
  flex-direction column
  width 100%
  height 100%
  padding 0 62px
  background $background-color
  // needs to be above nav bar so debugging overlay can display on top of nav bar
  z-index 4
}

.my-swiftly {
  &__header {
    display flex
    justify-content center
    align-items center
    min-height 5.4rem
    margin-top 20px
  }

  &__back {
    position absolute
    top 2rem
    left 2rem
    width 5rem
    height 5rem
    padding 0
  }

  &__heading {
    font-size 2.8rem
    font-weight 400
    color $text-default
  }

  &__items {
    display flex
    flex-direction column
    flex 1
    margin-top 32px
    margin-bottom 2rem
  }

  &__item {
    display flex
    align-items center
    min-height 5.4rem
    padding 24px 0
    border-top 1px solid $border-default
    background none
    font-size 2.4rem
    font-weight 400
    color $text-default
    line-height 0

    >>> i {
      width 24px
    }

    &:disabled {
      color $disabled-color

      >>> & path {
        stroke $disabled-color
      }
    }

    &:first-of-type {
      border-top none
    }

    &:last-of-type {
      margin-top auto
      border-top none
    }

    >>> &-text {
      margin-top 0
      margin-left 28px
    }
  }

  &__icon {
    margin-right 24px
  }

  &__logout-operator {
    font-weight 700
  }
}
</style>
