<template>
  <div class="component-loading-animation" :class="`size-${size}`">
    <div class="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation',
  props: { size: { required: false, default: 'medium' } },
};
</script>

<style lang="stylus">
@require "../../styl/_colors.styl"

.component-loading-animation {

    // Default sizing (medium)
    .lds-ring {
        display inline-block
        position relative
        width 64px
        height 64px
    }
    .lds-ring div {
        box-sizing border-box
        display block
        position absolute
        width 51px
        height 51px
        margin 6px
        border 6px solid
        border-radius 50%
        animation lds-ring 1.2s cubic-bezier(0.6, 0, 0.12, 0.96) infinite
        border-color $seashell transparent transparent transparent
        opacity 0.9
    }
    .lds-ring divnth-child(1) {
        animation-delay -0.45s
    }
    .lds-ring divnth-child(2) {
        animation-delay -0.3s
    }
    .lds-ring divnth-child(3) {
        animation-delay -0.15s
    }

    @keyframes lds-ring {
        0% {
            transform rotate(0deg)
        }
        100% {
            transform rotate(360deg)
        }
    }

    // Alternate sizing (small)
    &.size-small {
        .lds-ring {
            width  40px
            height 40px
        }
        .lds-ring div {
            width  28px
            height 28px
            margin 6px
            border-width 4px
        }
    }
}
</style>
