import { distance, point } from '@turf/turf';
import _ from 'lodash';

import { fixPwsEmail } from '../analytics/fix-pws-email';
import * as ErrorReporter from '../error-reporter';
import Optimizely from '../optimizely';
import {
  ONBOARD_APP_NAVIGATION_AUDIO,
  ONBOARD_APP_TURN_BY_TURN,
} from '../optimizely/feature-flags';
import { buildStopsList } from '../utils/build-stops-list';
import convertAdjustmentsToAlertText from '../utils/convert-adjustments-to-alert-text';
import { coordinatesToGeoJsonLine } from '../utils/coordinates-to-geojson-line';
import {
  APP_OPERATOR_FORMAT,
  TRANSITIME_OPERATOR_FORMAT,
  formatOperator,
} from '../utils/format-operator';
import { sanitizeVehicleIds } from '../utils/sanitize-vehicle-ids';
import { convertTransitimeTimeToHumanFriendly } from '../utils/transitime-time';

const VEHICLE_IS_MOVING_MPH_THRESHOLD = 10;
const VEHICLE_IS_AT_STOP_METERS_THRESHOLD = 50;

const _getDistanceInMeters = (latLonArray1, latLonArray2) => {
  const from = point([latLonArray1[1], latLonArray1[0]]);
  const to = point([latLonArray2[1], latLonArray2[0]]);
  const kilometers = distance(from, to, { units: 'kilometers' });
  return Math.round(kilometers * 1000);
};

const _parseDateStringToEpochMilliseconds = (dateString) => {
  try {
    return new Date(dateString).getTime();
  } catch (error) {
    ErrorReporter.captureException(error);
    return null;
  }
};

export const agencyConfigs = (state) => JSON.parse(state.agencyConfigs);

export const agenciesByKey = (state, getters) =>
  getters.agencyConfigs.reduce((acc, config) => {
    acc[config.key] = config;
    return acc;
  }, {});

export const currentAgencyConfig = (state, getters) =>
  getters.currentAgencyKey != null
    ? getters.agenciesByKey[getters.currentAgencyKey]
    : null;

export const uuid = (state) => state.uuid;
export const registrationStatus = (state) => state.registrationStatus;

export const isRegisteredDevice = (state, getters) => {
  const registrationStatus = getters.registrationStatus;
  const hasNoSupportedAgencies = getters.hasNoSupportedAgencies;
  if (hasNoSupportedAgencies) {
    return false;
  }
  return registrationStatus === 'registered';
};

export const currentAppHash = (state) => {
  if (typeof state.appHash === 'string' && state.appHash) {
    return state.appHash;
  }
  // As a fallback, check localStorage
  const currentAppHash = localStorage.getItem('appHash');
  if (typeof currentAppHash === 'string' && currentAppHash) {
    return currentAppHash;
  }
  return null;
};

export const host = () => process.env.ONBOARD_APP_HOST || 'default';

export const supportedAgencies = (state, getters) =>
  _.sortBy(getters.agencyConfigs, 'displayName');

export const hasSingleSupportedAgency = (state, { supportedAgencies }) =>
  supportedAgencies.length === 1 && supportedAgencies[0].key !== '*';

export const hasNoSupportedAgencies = (state, getters) =>
  !_.get(getters.supportedAgencies, ['0', 'key']);

export const isOnline = (state) => state.isOnline;

export const serverErrorVehicles = (state) => state.serverErrorVehicles;
export const serverErrorBlocks = (state) => state.serverErrorBlocks;
export const serverErrorTripInfo = (state) => state.serverErrorTripInfo;
export const serverErrorVehicleInfo = (state) => state.serverErrorVehicleInfo;

export const serverErrorLoginInfo = (state, getters) => {
  const serverErrorVehicles = getters.serverErrorVehicles;
  const serverErrorBlocks = getters.serverErrorBlocks;
  return serverErrorVehicles || serverErrorBlocks;
};

export const vehicleAssignmentError = (state) => state.vehicleAssignmentError;

export const sendGeolocationUpdates = (state, getters) =>
  !getters.isZeroTouchLoginEnabled && state.sendGeolocationUpdates;
export const geolocationEnabledState = (state) => state.geolocationEnabledState;

export const enableServiceAdjustments = (state) =>
  state.enableServiceAdjustments;

export const tripDisplayFormat = (state) => state.tripDisplayFormat;
export const operatorAssignmentFormat = (state) =>
  state.operatorAssignmentFormat;

export const showTopBar = (state) => state.showTopBar;
export const showLoginOverlay = (state) => state.showLoginOverlay;
export const showLoginSelections = (state) => state.showLoginSelections;
export const showRouteStops = (state) => state.showRouteStops;
export const showTimepointsOnlyInOtpView = (state) =>
  state.showTimepointsOnlyInOtpView;
export const showTimepointsOnlyInMapView = (state) =>
  state.showTimepointsOnlyInMapView;
export const showOperatorLogin = (state) => state.showOperatorLogin;
export const headwayMode = (state) => state.headwayMode;
export const developerMode = (state) => state.developerMode;

export const currentAgencyKey = (state) => state.currentAgencyKey;
export const agencyKeyForRequest = (state, getters) =>
  getters.currentAgencyConfig?.aliasOf ?? state.currentAgencyKey;
export const currentVehicleId = (state) => state.currentVehicleId;
export const permanentlyAssignedVehicleId = (state) =>
  state.permanentlyAssignedVehicleId;
export const currentRouteKey = (state) => state.currentRouteKey;
export const currentBlockId = (state) => state.currentBlockId;

export const hasConfirmedActiveAssignment = (state, getters) => {
  const currentVehicleId = getters.currentVehicleId;
  return currentVehicleId.length > 0;
};

export const hasPermanentAssignment = (state, getters) =>
  getters.permanentlyAssignedVehicleId.length !== 0;

export const currentVehicleInfoTimestamp = (state) =>
  state.currentVehicleInfoTimestamp;
export const currentVehicleInfoAttemptTimestamp = (state) =>
  state.currentVehicleInfoAttemptTimestamp;

export const wakeLockStatus = (state) => state.wakeLockStatus;

export const agencyKeys = (state) => JSON.parse(state.agencyKeys);
export const currentOperator = (state) => JSON.parse(state.currentOperator);
// Note: This won't necessarily match the name in get-agencies, _especially_ if an `aliasOf` is used.
export const currentAgencyInfo = (state) => JSON.parse(state.currentAgencyInfo);
export const currentAgencyTransitimeConfig = (state) =>
  JSON.parse(state.currentAgencyTransitimeConfig);
export const currentRoutes = (state) => JSON.parse(state.currentRoutes);
// Note: Only updated during Sign In and Permanent vehicle assignment
export const currentVehicles = (state) => JSON.parse(state.currentVehicles);
export const currentBlocks = (state) => JSON.parse(state.currentBlocks);
export const serviceAdjustments = (state) =>
  JSON.parse(state.serviceAdjustments);

export const currentOperatorIds = (state) => {
  const defaultOperatorIds = [
    {
      key: null,
      firstName: '',
      lastName: '',
      displayName: 'Anonymous operator',
    },
  ];
  const operatorIds = _.sortBy(
    JSON.parse(state.currentOperatorIds),
    (operator) =>
      formatOperator(operator, APP_OPERATOR_FORMAT.DISPLAY_NAME).toLowerCase(),
  );
  return operatorIds.concat(defaultOperatorIds);
};

export const currentOperatorDisplayName = (state, getters) => {
  const operator = getters.currentOperator;
  if (operator == null || operator.key == null) {
    return null;
  }
  return formatOperator(
    operator,
    APP_OPERATOR_FORMAT.DISPLAY_NAME_OR_KEY_WITH_OPERATOR_TAG,
  );
};

export const isNativeApp = () => typeof window.cordova === 'object';

export const isNativeAppWithMapSupport = (state, getters) => {
  const currentTripRouteKey = getters.currentTripRouteKey;
  if (typeof window.cordova !== 'object') {
    return false;
  }
  if (typeof window.NativeAPI !== 'object') {
    return false;
  }
  if (typeof window.NativeAPI.showMap !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.hideMap !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.drawTripGeojsonLine !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.removeTripGeojsonLine !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.drawTripPolylines !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.removeTripPolylines !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.drawTripStops !== 'function') {
    return false;
  }
  if (typeof window.NativeAPI.removeTripStops !== 'function') {
    return false;
  }
  const isAgencyMapEnabled =
    getters.currentAgencyConfig?.defaultSettings.enableNativeMap ?? false;
  if (!isAgencyMapEnabled) {
    return false;
  }
  const restrictNativeMapToSpecificRoutes =
    getters.currentAgencyConfig?.defaultSettings
      .restrictNativeMapToSpecificRoutes ?? [];
  if (restrictNativeMapToSpecificRoutes.length) {
    return restrictNativeMapToSpecificRoutes.includes(currentTripRouteKey);
  }
  return true;
};

export const hasOperatorIds = (state) =>
  !_.isEmpty(JSON.parse(state.currentOperatorIds));

export const disableMapboxMatching = (state, getters) => {
  const currentTripRouteKey = getters.currentTripRouteKey;
  const disableMapboxMatching =
    getters.currentAgencyConfig?.defaultSettings.disableMapboxMatching ?? false;
  const restrictDisableMapboxMatchingToSpecificRoutes =
    getters.currentAgencyConfig?.defaultSettings
      .restrictDisableMapboxMatchingToSpecificRoutes ?? [];
  if (
    disableMapboxMatching &&
    restrictDisableMapboxMatchingToSpecificRoutes.length
  ) {
    return restrictDisableMapboxMatchingToSpecificRoutes.includes(
      currentTripRouteKey,
    );
  }
  return disableMapboxMatching;
};

export const agencyAvlReportingInterval = (state, getters) => {
  const interval = parseInt(
    getters.currentAgencyConfig?.defaultSettings.avlReportingInterval ?? '10',
  );
  return 1000 * interval;
};

export const isPassengerCountingEnabled = (state, getters) => {
  const { currentAgencyConfig, currentTripRouteKey } = getters;
  if (currentAgencyConfig == null) {
    return false;
  }
  const { defaultSettings } = currentAgencyConfig;
  if (
    defaultSettings.passengerCountingEnabled &&
    defaultSettings.passengerCountingRoutes != null
  ) {
    return defaultSettings.passengerCountingRoutes.includes(
      currentTripRouteKey,
    );
  }
  return defaultSettings.passengerCountingEnabled;
};

export const agencyPassengerCountingButtons = (state, getters) => {
  const agencyConfig = getters.currentAgencyConfig;
  if (
    agencyConfig == null ||
    !agencyConfig.defaultSettings.passengerCountingEnabled
  ) {
    return null;
  }
  const buttons = agencyConfig.defaultSettings.passengerCountingButtons;
  if (!Array.isArray(buttons) || buttons.length === 0) {
    throw new Error('Passenger counting enabled, but buttons are not defined');
  }
  const MAXIMUM_ALLOWABLE_BUTTONS = 8;
  if (buttons.length > MAXIMUM_ALLOWABLE_BUTTONS) {
    throw new Error('Passenger counting maximum allowable buttons exceeded');
  }
  return buttons;
};

export const isNavigationEnabled = (
  state,
  { currentAgencyConfig, currentTripRouteKey, userHasFeatureAccess },
) =>
  userHasFeatureAccess(ONBOARD_APP_TURN_BY_TURN) ||
  (currentAgencyConfig?.defaultSettings.turnByTurnRoutes.includes(
    currentTripRouteKey,
  ) ??
    false);

export const isNavigationAudioEnabled = (
  state,
  { currentAgencyConfig, userHasFeatureAccess },
) =>
  userHasFeatureAccess(ONBOARD_APP_NAVIGATION_AUDIO) ||
  (currentAgencyConfig?.defaultSettings.enableNavigationAudio ?? false);

// TODO(haysmike) Remove - this is either a duplicate of `lastValidVehicleInfo` or it's invalid
export const currentVehicleInfo = (state) =>
  JSON.parse(state.currentVehicleInfo);
export const lastValidVehicleInfo = (state) =>
  JSON.parse(state.lastValidVehicleInfo);
export const currentTripInfo = (state) => JSON.parse(state.currentTripInfo);
export const lastTripLastVehicleInfo = (state) =>
  JSON.parse(state.lastTripLastVehicleInfo);
export const matchedTripPathResponse = (state) =>
  JSON.parse(state.matchedTripPathResponse);
export const currentTripCoordinates = (state) =>
  JSON.parse(state.currentTripCoordinates);
export const currentRouteInfo = (state) => JSON.parse(state.currentRouteInfo);

export const currentRouteStopsById = (state, getters) => {
  const currentRouteInfo = getters.currentRouteInfo;
  const currentTripDirectionId = getters.currentTripDirectionId;
  if (
    currentRouteInfo == null ||
    currentRouteInfo.directions == null ||
    currentTripDirectionId == null
  ) {
    return null;
  }
  const currentRouteDirection = currentRouteInfo.directions.find(
    (direction) => direction.id === currentTripDirectionId,
  );
  if (currentRouteDirection == null) {
    return null;
  }
  return currentRouteDirection.stops.reduce((acc, stop) => {
    acc[stop.id] = stop;
    return acc;
  }, {});
};

export const mapMatchedTripPolylines = (state, { matchedTripPathResponse }) =>
  matchedTripPathResponse?.polylines ?? [];

export const mapMatchedDetourPolylines = (state, { matchedTripPathResponse }) =>
  matchedTripPathResponse?.detourPolylines ?? [];

export const mapMatchedClosedPolylines = (state, { matchedTripPathResponse }) =>
  matchedTripPathResponse?.closedPolylines ?? [];

const doesScheduleContainStopId = (schedule, stopId) =>
  schedule.some((scheduledStop) => scheduledStop.stopId === stopId);

export const serviceAdjustmentsForCurrentTrip = (state, getters) => {
  const currentTripId = getters.currentTripId;
  // Avoid getters.currentTripSchedule because it uses this getter (infinite loop)
  const currentTripInfo = getters.currentTripInfo;
  const currentTripSchedule = currentTripInfo?.schedule ?? [];
  const adjustmentsForCurrentTrip = [];
  const serviceAdjustments = getters.serviceAdjustments;
  if (!Array.isArray(serviceAdjustments)) {
    return [];
  }
  for (const adjustment of serviceAdjustments) {
    if (!adjustment.routeShortNames.includes(currentTripInfo?.routeShortName)) {
      continue;
    }
    switch (adjustment.adjustmentType) {
      case 'CLOSE_STOPS': {
        const closedStopIds = adjustment.details.stopIds;
        closedStopIds.some((closedStopId) => {
          if (
            closedStopId &&
            doesScheduleContainStopId(currentTripSchedule, closedStopId)
          ) {
            adjustmentsForCurrentTrip.push(adjustment);
            return true;
          }
          return false;
        });
        break;
      }
      case 'DETOUR_V0': {
        const stopPaths = currentTripInfo?.tripPattern?.stopPaths ?? [];
        const stopIds = stopPaths.map(({ stopId }) => stopId);
        const detourRoutesDirectionDetails =
          adjustment.details.detourRouteDirectionDetails.filter(
            ({ routeShortName, direction }) =>
              routeShortName === currentTripInfo?.routeShortName &&
              direction === currentTripInfo?.directionId,
          );
        detourRoutesDirectionDetails.some(({ routeExit, routeEntry }) => {
          const isRouteExitValid = stopIds.some(
            (stopId, index) =>
              index > 0 &&
              stopIds[index - 1] === routeExit.previousStopId &&
              stopId === routeExit.nextStopId,
          );
          const isRouteEntryValid =
            routeEntry == null ||
            stopIds.some(
              (stopId, index) =>
                index > 0 &&
                stopIds[index - 1] === routeEntry.previousStopId &&
                stopId === routeEntry.nextStopId,
            );
          if (isRouteExitValid && isRouteEntryValid) {
            adjustmentsForCurrentTrip.push(adjustment);
            return true;
          }
          return false;
        });
        break;
      }
      default: {
        const adjustmentTripId = adjustment.details.tripId;
        if (adjustmentTripId !== currentTripId) {
          continue;
        }
        const adjustmentStopId = adjustment.details.stopId;
        if (
          adjustmentStopId &&
          doesScheduleContainStopId(currentTripSchedule, adjustmentStopId)
        ) {
          adjustmentsForCurrentTrip.push(adjustment);
        }
      }
    }
  }
  return adjustmentsForCurrentTrip;
};

export const detourDetailsForCurrentTrip = (state, getters) => {
  const detourDetailsForCurrentTrip = [];
  const currentTripRouteKey = getters.currentTripRouteKey;
  const currentTripDirectionId = getters.currentTripDirectionId;
  const serviceAdjustments = getters.serviceAdjustments;
  if (!_.isArray(serviceAdjustments)) {
    return detourDetailsForCurrentTrip;
  }
  for (const adjustment of serviceAdjustments) {
    const adjustmentType = _.get(adjustment, 'adjustmentType');
    if (adjustmentType !== 'DETOUR_V0') {
      continue;
    }
    const adjustmentRoutes = _.get(adjustment, 'routeShortNames', []);
    if (!adjustmentRoutes.includes(currentTripRouteKey)) {
      continue;
    }
    const detourRouteDirectionDetails = _.get(
      adjustment,
      ['details', 'detourRouteDirectionDetails'],
      [],
    );
    for (const detourRouteDirectionDetail of detourRouteDirectionDetails) {
      if (detourRouteDirectionDetail.routeShortName !== currentTripRouteKey) {
        continue;
      }
      const directionId = _.get(detourRouteDirectionDetail, 'direction');
      if (directionId !== currentTripDirectionId) {
        continue;
      }
      detourDetailsForCurrentTrip.push(detourRouteDirectionDetail);
    }
  }
  return detourDetailsForCurrentTrip;
};

export const filterVehiclesFunction = (state, getters) => {
  const DEFAULT_VALUE = null;
  const currentAgencyTransitimeConfig = getters.currentAgencyTransitimeConfig;
  const regExpKey = 'transitime.avl.shouldNotAssignVehicleIdRegEx';
  if (!currentAgencyTransitimeConfig) {
    return DEFAULT_VALUE;
  }
  if (!currentAgencyTransitimeConfig[regExpKey]) {
    return DEFAULT_VALUE;
  }
  try {
    const regExp = new RegExp(currentAgencyTransitimeConfig[regExpKey]);
    return (vehicle) => {
      const vehicleId = _.get(vehicle, 'id');
      if (typeof vehicleId !== 'string') {
        return false;
      }
      const shouldFilter = regExp.test(vehicleId);
      if (shouldFilter) {
        return false;
      }
      return true;
    };
  } catch (error) {
    ErrorReporter.captureException(error);
  }
  return DEFAULT_VALUE;
};

export const filterUnassignedVehicles = (state, getters) =>
  !getters.sendGeolocationUpdates;

export const blockAssignmentCalls = (state, getters) =>
  !getters.sendGeolocationUpdates;

export const currentTripRouteKey = (state, getters) => {
  const DEFAULT_VALUE = '';
  const currentTripInfo = getters.currentTripInfo;
  return _.get(currentTripInfo, 'routeShortName', DEFAULT_VALUE);
};

export const currentTripHeadsign = (state, getters) => {
  const DEFAULT_VALUE = '';
  const currentTripInfo = getters.currentTripInfo;
  return _.get(currentTripInfo, ['headsign'], DEFAULT_VALUE);
};

export const getAdjustmentsForStopId = (state, getters) => (stopId) => {
  const serviceAdjustmentsForCurrentTrip =
    getters.serviceAdjustmentsForCurrentTrip;
  if (stopId == null || !serviceAdjustmentsForCurrentTrip) {
    return null;
  }
  const adjustments = serviceAdjustmentsForCurrentTrip.reduce(
    (acc, serviceAdjustment) => {
      const { adjustmentType, details } = serviceAdjustment;
      switch (adjustmentType) {
        case 'DETOUR_V0': {
          const { detourRouteDirectionDetails } = details;
          const filteredDetourRouteDirectionDetails =
            detourRouteDirectionDetails.filter(
              ({ routeShortName, direction }) =>
                getters.currentTripInfo?.routeShortName === routeShortName &&
                getters.currentTripInfo?.directionId === direction,
            );
          const isExit = filteredDetourRouteDirectionDetails.some(
            ({ routeExit }) => routeExit.previousStopId === stopId,
          );
          if (isExit) {
            acc.push({
              adjustmentType: 'DETOUR_EXIT',
              adjustment: serviceAdjustment,
            });
          }
          const isEntry = filteredDetourRouteDirectionDetails.some(
            ({ routeEntry }) => routeEntry?.nextStopId === stopId,
          );
          if (isEntry) {
            acc.push({
              adjustmentType: 'DETOUR_ENTRY',
              adjustment: serviceAdjustment,
            });
          }
          const isSkipped = filteredDetourRouteDirectionDetails
            .flatMap(({ skippedStops }) => skippedStops)
            .some((skippedStopId) => skippedStopId === stopId);
          if (isSkipped) {
            acc.push({
              adjustmentType: 'CLOSE_STOPS',
              adjustment: serviceAdjustment,
            });
          }
          break;
        }
        default: {
          const adjustmentStopIds = details.stopIds ?? [];
          if (details.stopId === stopId || adjustmentStopIds.includes(stopId)) {
            acc.push(serviceAdjustment);
          }
        }
      }
      return acc;
    },
    [],
  );
  return adjustments.length === 0 ? null : adjustments;
};

export const getAdjustmentsForCurrentStopId = (state, getters) =>
  getters.getAdjustmentsForStopId(getters.currentStopId);

export const currentTripDirectionId = (state, getters) => {
  const DEFAULT_VALUE = null;
  const currentTripInfo = getters.currentTripInfo;
  return _.get(currentTripInfo, 'directionId', DEFAULT_VALUE);
};

export const currentTripSchedule = (state, getters) => {
  const currentTripInfo = getters.currentTripInfo;
  if (currentTripInfo == null || currentTripInfo.schedule == null) {
    return null;
  }
  for (const scheduledStop of currentTripInfo.schedule) {
    // TODO(haysmike) Don't modify state in getters!
    scheduledStop.adjustments = getters.getAdjustmentsForStopId(
      scheduledStop.stopId,
    );
  }
  return currentTripInfo.schedule;
};

export const currentTripStopPaths = (state, getters) => {
  const DEFAULT_VALUE = [];
  const currentTripInfo = getters.currentTripInfo;
  return _.get(currentTripInfo, ['tripPattern', 'stopPaths'], DEFAULT_VALUE);
};

export const currentTripStopPathsByStopId = (state, getters) =>
  _.keyBy(getters.currentTripStopPaths, 'stopId');

export const currentTripStopsGeoJson = (state, getters) => {
  const tripStops = buildStopsList({
    limitToPriorityStops: getters.showTimepointsOnlyInMapView,
    currentTripStopPathsByStopId: getters.currentTripStopPathsByStopId,
    fullSchedule: getters.currentTripSchedule || [],
  });
  const geoJson = [];
  for (const stop of tripStops) {
    const { lat, lon } = stop;
    if (lat == null || lon == null) {
      return;
    }
    const { adjustments, stopName, stopId, isPriorityStop } = stop;
    const adjustmentType = adjustments?.[0]?.adjustmentType;
    const properties = adjustmentType != null ? { adjustmentType } : null;
    geoJson.push({
      lat,
      lon,
      name: stopName || stopId || '...',
      adjustmentsAlertText: convertAdjustmentsToAlertText(adjustments),
      isPriorityStop,
      feature: point([lon, lat], properties),
    });
    if (adjustmentType === 'DETOUR_EXIT') {
      const newStops =
        adjustments?.[0].adjustment.details.detourRouteDirectionDetails.flatMap(
          (detourDetails) => detourDetails.newStops,
        );
      for (const { latLon, stopName, stopId } of newStops) {
        geoJson.push({
          lat: latLon[0],
          lon: latLon[1],
          name: stopName || stopId || '...',
          adjustmentsAlertText: '',
          isPriorityStop: true,
          feature: point([latLon[1], latLon[0]]),
        });
      }
    }
  }
  return geoJson;
};

export const currentTripPathGeoJson = (state, getters) => {
  const coordinates = getters.currentTripCoordinates;
  if (coordinates == null) {
    return { original: [], detour: [], closed: [] };
  }

  const stopPaths = getters.currentTripStopPaths;
  const original = [];
  for (const [stopPathIndex, stopPath] of stopPaths.entries()) {
    const { locations } = stopPath;
    if (!_.isArray(locations)) {
      continue;
    }
    original.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [],
      },
    });
    for (const location of locations.values()) {
      original[stopPathIndex].geometry.coordinates.push([
        location.lon,
        location.lat,
      ]);
    }
  }

  const { detour, closed } = coordinates;
  return {
    original,
    detour: detour.map(coordinatesToGeoJsonLine),
    closed: closed.map(coordinatesToGeoJsonLine),
  };
};

export const currentBlockInfo = (state, getters) => {
  const { currentBlocks, currentBlockId } = getters;
  if (currentBlocks == null) {
    return null;
  }
  return currentBlocks
    .flatMap((routeBlocks) => routeBlocks.block)
    .find((block) => block.id === currentBlockId);
};

export const currentPosition = (state) => JSON.parse(state.currentPosition);

export const memoryInfo = (state) => JSON.parse(state.memoryInfo);

export const heapSizeLimit = (state, getters) => {
  const memoryInfo = getters.memoryInfo;
  return _.get(memoryInfo, 'jsHeapSizeLimit', '...');
};

export const totalJavaScriptHeapSize = (state, getters) => {
  const memoryInfo = getters.memoryInfo;
  return _.get(memoryInfo, 'totalJSHeapSize', '...');
};

export const usedJavaScriptHeapSize = (state, getters) => {
  const memoryInfo = getters.memoryInfo;
  return _.get(memoryInfo, 'usedJSHeapSize', '...');
};

export const gpsLat = (state, { currentPosition }) =>
  currentPosition?.coords.latitude;

export const gpsLon = (state, { currentPosition }) =>
  currentPosition?.coords.longitude;

export const gpsLatLonArray = (state, getters) => {
  const lat = getters.gpsLat;
  const lon = getters.gpsLon;
  if (!Number.isFinite(lat) || !Number.isFinite(lon)) {
    return null;
  }
  return [lat, lon];
};

export const gpsHeading = (state, getters) => {
  const agencyConfig = getters.currentAgencyConfig;
  const position = getters.currentPosition;
  if (
    agencyConfig == null ||
    agencyConfig.defaultSettings.disableHeading ||
    position == null ||
    position.coords == null ||
    !Number.isFinite(position.coords.heading)
  ) {
    return null;
  }
  return position.coords.heading;
};

export const gpsSpeed = (state, getters) => {
  const position = getters.currentPosition;
  return _.get(position, ['coords', 'speed'], null);
};

export const gpsSpeedInMilesPerHour = (state, getters) => {
  const speedInMetersPerSecond = getters.gpsSpeed;
  if (!_.isFinite(speedInMetersPerSecond)) {
    return null;
  }
  const MPS_TO_MPH_COEFFICENT = 2.23694;
  return MPS_TO_MPH_COEFFICENT * speedInMetersPerSecond;
};

export const gpsTimestamp = (state, getters) => {
  const position = getters.currentPosition;
  return _.get(position, 'timestamp', null);
};

export const vehicleIsMoving = (state, getters) => {
  const disableSafeDrivingMode = state.disableSafeDrivingMode;
  const gpsSpeedInMilesPerHour = getters.gpsSpeedInMilesPerHour;
  return (
    !disableSafeDrivingMode &&
    _.isFinite(gpsSpeedInMilesPerHour) &&
    gpsSpeedInMilesPerHour > VEHICLE_IS_MOVING_MPH_THRESHOLD
  );
};

export const shouldHideScreen = (state, getters) =>
  getters.vehicleIsMoving &&
  getters.currentAgencyConfig?.defaultSettings.hideScreenWhenVehicleIsMoving;

export const enableNativeMapGestures = (state, getters) =>
  !getters.vehicleIsMoving;

export const currentTripStartDisplayName = (state, getters) => {
  const DEFAULT_VALUE = '...';
  const tripDetails = getters.currentTripInfo;
  const firstStopName = _.get(tripDetails, ['schedule', 0, 'stopName']);
  if (!firstStopName) {
    return DEFAULT_VALUE;
  }
  return `Starts at ${firstStopName}`;
};

export const activeBlocksForRoute = (state, getters) => (routeKey) => {
  const DEFAULT_VALUE = [];
  const activeBlocksForRoute = _.find(
    getters.currentBlocks,
    (activeBlocksForRoute) => activeBlocksForRoute.shortName === routeKey,
  );
  return _.get(activeBlocksForRoute, 'block', DEFAULT_VALUE);
};

export const vehicleIdsWithAssignment = (state, getters) => {
  const vehicleIdsWithAssignment = [];
  getters.currentBlocks.forEach((blocksForRoute) => {
    if (!_.isArray(blocksForRoute.block)) {
      return;
    }
    blocksForRoute.block.forEach((block) => {
      const vehicleId = _.get(block, ['vehicle', '0', 'id']);
      if (!vehicleId) {
        return;
      }
      if (vehicleId.indexOf('schedBasedVehicle') !== -1) {
        return;
      }
      vehicleIdsWithAssignment.push(vehicleId);
    });
  });
  return vehicleIdsWithAssignment;
};

export const randomVehicleIdWithAssignment = (state, getters) => {
  const vehicleIds = getters.vehicleIdsWithAssignment;
  if (_.isEmpty(vehicleIds)) {
    return null;
  }
  return vehicleIds[Math.floor(Math.random() * vehicleIds.length)];
};

export const tripsForRoute =
  (state, { tripDisplayFormat, activeBlocksForRoute }) =>
  (routeKey) => {
    const currentTrips = activeBlocksForRoute(routeKey)
      .filter(
        ({ isCanceled, isRestCanceled, isTripCanceled, wasEndServed }) =>
          !isCanceled && !isTripCanceled && !isRestCanceled && !wasEndServed,
      )
      .map((block) => {
        const blockId = _.get(block, ['id']);
        const tripId = _.get(block, ['trip', 'id']);
        const startTime = _.get(block, ['trip', 'startTime']);
        const headsign = _.get(block, ['trip', 'headsign']);
        const departureStopName = _.get(block, ['trip', 'firstStopName']);
        const vehicle = _.get(block, ['vehicle', '0'], null);
        // Schedule-based vehicles aren't real vehicles - they're vehicles
        // created by our system to generate predictions.
        const assignedVehicle =
          vehicle != null && !vehicle.scheduleBased ? vehicle : null;

        let displayPrefix;
        try {
          displayPrefix = convertTransitimeTimeToHumanFriendly(startTime);
        } catch (error) {
          ErrorReporter.capture({
            level: 'error',
            messageOrException: 'Invalid start time detected for trip',
            extraContext: { error, trip: block.trip },
          });
          displayPrefix = 'Trip';
        }
        let displayName;
        switch (tripDisplayFormat) {
          case 'miami-style':
            displayName =
              departureStopName != null
                ? `${displayPrefix} from ${departureStopName}`
                : displayPrefix;
            break;
          case 'agency-selection':
          default:
            displayName = `${displayPrefix} to ${headsign} (${tripId})`;
        }

        return {
          startTime,
          headsign,
          blockId,
          tripId,
          assignedVehicle,
          displayName,
        };
      });

    return _.sortBy(currentTrips, ['startTime', 'headsign']);
  };

export const tripsForRouteByTripId = (state, getters) => (routeKey) => {
  const tripsForRoute = getters.tripsForRoute(routeKey);
  return _.keyBy(tripsForRoute, 'tripId');
};

export const blockIdForRouteAndTrip =
  (state, getters) => (routeKey, tripId) => {
    const DEFAULT_VALUE = '';
    const tripsForRouteByTripId = getters.tripsForRouteByTripId(routeKey);
    return _.get(tripsForRouteByTripId, [tripId, 'blockId'], DEFAULT_VALUE);
  };

export const assignedVehicleForRouteAndTrip =
  (state, getters) => (routeKey, tripId) => {
    const tripsForRouteByTripId = getters.tripsForRouteByTripId(routeKey);
    return tripsForRouteByTripId[tripId]?.assignedVehicle;
  };

export const currentAgencyDisplayName = (state, getters) =>
  getters.currentAgencyConfig?.displayName ?? '';

export const allowableSecondsEarly = (state, getters) => {
  const DEFAULT_VALUE = 60;
  const currentAgencyInfo = getters.currentAgencyInfo;
  const allowableSecondsEarly = _.get(
    currentAgencyInfo,
    ['allowableEarlySchAdhSecs'],
    DEFAULT_VALUE,
  );
  // invert since schedule adherence seconds will be negative if early
  return allowableSecondsEarly * -1;
};

export const allowableSecondsLate = (state, getters) => {
  const DEFAULT_VALUE = 240;
  const currentAgencyInfo = getters.currentAgencyInfo;
  const allowableSecondsLate = _.get(
    currentAgencyInfo,
    ['allowableLateSchAdhSecs'],
    DEFAULT_VALUE,
  );
  // do not invert since schedule adherence seconds will be positive if late
  return allowableSecondsLate;
};

export const currentRouteKeys = (state, getters) => {
  const currentRoutes = getters.currentRoutes;
  return _.chain(currentRoutes)
    .map((route) => route.shortName)
    .uniq()
    .sortBy([
      (routeShortName) => (routeShortName && routeShortName.length) || 0,
      (routeShortName) => routeShortName,
    ])
    .value();
};

export const routeFullName =
  (state, getters) =>
  (routeKey = getters.currentRouteKey) => {
    const currentRoutes = getters.currentRoutes;
    const currentRoute = _.find(
      currentRoutes,
      (route) => route.shortName === routeKey,
    );
    return _.get(currentRoute, 'name', '');
  };

export const activeTripByVehicleId = function (state, getters) {
  const currentVehicleId = getters.currentVehicleId;
  const currentFilteredTrips = getters.currentFilteredTrips;
  return _.filter(
    currentFilteredTrips,
    (trip) => trip.vehicleId === currentVehicleId,
  );
};

// Note: Only updated during Sign In and Permanent vehicle assignment
export const currentVehicleIds = (state, getters) => {
  const { currentVehicles, filterUnassignedVehicles } = getters;
  const filteredVehicles = filterUnassignedVehicles
    ? currentVehicles.filter(({ routeShortName }) => routeShortName != null)
    : currentVehicles;
  return sanitizeVehicleIds(filteredVehicles);
};

// Note: Only updated during Sign In and Permanent vehicle assignment
export const currentVehiclesById = (state, getters) => {
  const currentVehicles = getters.currentVehicles;
  return _.keyBy(currentVehicles, 'id');
};

export const currentVehicleHeadsign = (state, getters) =>
  getters.currentOrLastValidVehicleInfo?.headsign ?? '';

// TODO(haysmike) Remove - just use `lastValidVehicleInfo` everywhere
export const currentOrLastValidVehicleInfo = (state, getters) =>
  getters.currentVehicleInfo != null
    ? getters.currentVehicleInfo
    : getters.lastValidVehicleInfo;

export const currentTripId = (state, { lastValidVehicleInfo }) =>
  lastValidVehicleInfo?.tripId ??
  lastValidVehicleInfo?.lastKnownTripIdOnAssignment ??
  '';

export const currentStopPathIndex = (state, getters) =>
  getters.currentOrLastValidVehicleInfo?.stopPathIndex;

export const currentStopId = (state, getters) => {
  const currentVehicleInfo = getters.currentOrLastValidVehicleInfo;
  if (currentVehicleInfo?.nextStopId == null) {
    return null;
  }
  return currentVehicleInfo.nextStopId;
};

export const currentStopLatLonArray = (state, getters) => {
  const currentRouteStopsById = getters.currentRouteStopsById;
  const currentStopId = getters.currentStopId;
  if (
    currentRouteStopsById == null ||
    currentStopId == null ||
    currentRouteStopsById[currentStopId] == null ||
    !Number.isFinite(currentRouteStopsById[currentStopId].lat) ||
    !Number.isFinite(currentRouteStopsById[currentStopId].lon)
  ) {
    return null;
  }
  return [
    currentRouteStopsById[currentStopId].lat,
    currentRouteStopsById[currentStopId].lon,
  ];
};

export const metersToCurrentStop = (state, getters) => {
  const gpsLatLonArray = getters.gpsLatLonArray;
  const currentStopLatLonArray = getters.currentStopLatLonArray;
  if (gpsLatLonArray == null || currentStopLatLonArray == null) {
    return null;
  }
  return _getDistanceInMeters(gpsLatLonArray, currentStopLatLonArray);
};

export const vehicleId = (state, getters) => {
  const IS_UNASSIGNED = '';
  let currentVehicleInfo = getters.currentVehicleInfo;
  const currentVehicleInfoTimestamp = getters.currentVehicleInfoTimestamp;
  const lastValidVehicleInfo = getters.lastValidVehicleInfo;
  // If currentVehicleInfo is missing, fallback to lastValidVehicleInfo for up to 60 seconds
  if (_.isNull(currentVehicleInfo)) {
    if (_.isFinite(currentVehicleInfoTimestamp)) {
      const vehicleInfoAgeInSeconds =
        (Date.now() - currentVehicleInfoTimestamp) / 1000;
      if (vehicleInfoAgeInSeconds < 60) {
        currentVehicleInfo = lastValidVehicleInfo;
      }
    }
  }
  const vehicleId = currentVehicleInfo?.id ?? IS_UNASSIGNED;
  const isAssigned = currentVehicleInfo?.blockId != null;

  if (typeof isAssigned === 'boolean' && !isAssigned) {
    return IS_UNASSIGNED;
  }
  return vehicleId;
};

export const isVehicleAtStop = (state, getters) => {
  // First, use all relevant getters to ensure proper Vuex cache behavior
  const gpsSpeedInMilesPerHour = getters.gpsSpeedInMilesPerHour;
  const metersToCurrentStop = getters.metersToCurrentStop;
  const isVehicleAtStopServerStatus = getters.isVehicleAtStopServerStatus;

  // 1. Do we know a vehicle is NOT at stop because it is moving?
  const hasSpeedInformation = Number.isFinite(gpsSpeedInMilesPerHour);
  if (
    hasSpeedInformation &&
    gpsSpeedInMilesPerHour > VEHICLE_IS_MOVING_MPH_THRESHOLD
  ) {
    return false;
  }
  // 2. Can we use distance information derived from local GPS data?
  const hasDistanceInformation = Number.isFinite(metersToCurrentStop);
  if (hasDistanceInformation) {
    return metersToCurrentStop < VEHICLE_IS_AT_STOP_METERS_THRESHOLD;
  }
  // 3. As a final fallback, use server-side information
  return isVehicleAtStopServerStatus;
};

export const isVehicleAtStopServerStatus = (state, getters) => {
  const vehicleInfo = getters.currentOrLastValidVehicleInfo;
  return vehicleInfo?.isAtStop ?? false;
};

export const vehicleIsAtWaitStop = (state, getters) => {
  const DEFAULT_VALUE = false;
  return getters.currentOrLastValidVehicleInfo?.isAtWaitStop ?? DEFAULT_VALUE;
};

export const vehicleStopName = (state, getters) => {
  const DEFAULT_VALUE = '';
  return getters.currentOrLastValidVehicleInfo?.nextStopName ?? DEFAULT_VALUE;
};

export const vehicleOptimalDepartureTime = (state, getters) => {
  if (!getters.isVehicleAtStopServerStatus || !getters.vehicleIsAtWaitStop) {
    return null;
  }
  const vehicleState = getters.currentOrLastValidVehicleInfo;
  const isFirstStop =
    getters.currentTripSchedule?.[0]?.stopId === vehicleState?.nextStopId;
  return getters.headwayMode
    ? isFirstStop
      ? getters.currentOrLastValidVehicleInfo?.oaOptimalDepartureTimeEpochMsecs
      : null
    : vehicleState?.stopScheduleTimeEpochMsecs;
};

export const vehicleModifiedDepartureTime = (state, getters) => {
  const DEFAULT_VALUE = null;
  const adjustments = getters.getAdjustmentsForCurrentStopId;
  if (!getters.isVehicleAtStopServerStatus) {
    return DEFAULT_VALUE;
  }
  const adjustment = _.get(adjustments, 0);
  const adjustmentType = _.get(adjustment, ['details', 'adjustmentType']);
  if (adjustmentType !== 'MODIFY_DEPARTURE_TIME') {
    return DEFAULT_VALUE;
  }
  return _.get(adjustment, ['details', 'departureTime'], DEFAULT_VALUE);
};

export const vehicleModifiedDepartureEpochMilliseconds = (state, getters) => {
  const DEFAULT_VALUE = null;
  const vehicleModifiedDepartureTime = getters.vehicleModifiedDepartureTime;
  if (!vehicleModifiedDepartureTime) {
    return DEFAULT_VALUE;
  }
  const adjustedDepatureEpochMilliseconds = _parseDateStringToEpochMilliseconds(
    vehicleModifiedDepartureTime,
  );
  if (!_.isFinite(adjustedDepatureEpochMilliseconds)) {
    return DEFAULT_VALUE;
  }
  return adjustedDepatureEpochMilliseconds;
};

export const vehicleModifiedDepartureReadable = (state, getters) => {
  const DEFAULT_VALUE = null;
  const vehicleModifiedDepartureTime = getters.vehicleModifiedDepartureTime;
  if (!vehicleModifiedDepartureTime) {
    return DEFAULT_VALUE;
  }

  return new Date(vehicleModifiedDepartureTime).toLocaleTimeString(
    state.userLocale,
    { timeStyle: 'short' },
  );
};

export const departureTimeEpochMilliseconds = (state, getters) => {
  const DEFAULT_VALUE = null;
  const vehicleModifiedDepartureTime =
    getters.vehicleModifiedDepartureEpochMilliseconds;
  const optimalDepartureTime = getters.vehicleOptimalDepartureTime;
  if (_.isFinite(vehicleModifiedDepartureTime)) {
    return vehicleModifiedDepartureTime;
  }
  if (_.isFinite(optimalDepartureTime)) {
    return optimalDepartureTime;
  }
  return DEFAULT_VALUE;
};

export const vehicleDepartureTime = (state, getters) => {
  const DEFAULT_VALUE = '';
  return getters.headwayMode
    ? getters.currentOrLastValidVehicleInfo?.oaOptimalDepartureTimeStr ??
        DEFAULT_VALUE
    : getters.currentOrLastValidVehicleInfo?.stopScheduleTimeStr ??
        DEFAULT_VALUE;
};

export const vehicleScheduleAdherenceSeconds = (state, getters) => {
  const DEFAULT_VALUE = false;
  return getters.currentOrLastValidVehicleInfo?.schAdhSecs != null
    ? getters.currentOrLastValidVehicleInfo.schAdhSecs
    : DEFAULT_VALUE;
};

export const vehicleIsOnDetour = (state, getters) =>
  getters.currentOrLastValidVehicleInfo?.isOnDetour ?? false;

export const vehicleHeadwayCategory = (state, getters) => {
  const DEFAULT_VALUE = 'unknown';
  const vehicleInfo = getters.currentOrLastValidVehicleInfo;
  if (vehicleInfo == null) {
    return DEFAULT_VALUE;
  }
  const actualHeadway = vehicleInfo.headwaySecs;
  const scheduledHeadway = vehicleInfo.scheduledHeadwaySecs;
  if (actualHeadway == null || scheduledHeadway == null) {
    return DEFAULT_VALUE;
  }
  if (actualHeadway < 0.75 * scheduledHeadway) {
    return 'bunched';
  }
  if (actualHeadway > 1.25 * scheduledHeadway) {
    return 'gapped';
  }
  return 'expected';
};

export const vehicleScheduledHeadwayMinutes = (state, getters) => {
  const DEFAULT_VALUE = '...';
  const headwaySecs =
    getters.currentOrLastValidVehicleInfo?.scheduledHeadwaySecs;
  if (!Number.isInteger(headwaySecs)) {
    return DEFAULT_VALUE;
  }
  return Math.round(headwaySecs / 60).toFixed(0);
};

export const vehicleHeadwaySeconds = (state, getters) => {
  const DEFAULT_VALUE = '';
  return getters.currentOrLastValidVehicleInfo?.headwaySecs ?? DEFAULT_VALUE;
};

export const vehicleHeadwayMinutes = (state, getters) => {
  const DEFAULT_VALUE = '';
  const headwaySecs = getters.vehicleHeadwaySeconds;
  if (!Number.isFinite(headwaySecs)) {
    return DEFAULT_VALUE;
  }
  return Math.round(headwaySecs / 60).toFixed(0);
};

export const isSimulating = (state, getters) =>
  !getters.sendGeolocationUpdates && state.isSimulating;

export const email = (state) => state.email;

export const optimizelyAttributes = (state, getters) => {
  const {
    currentAgencyKey: selectedAgency,
    currentOperator,
    currentRouteKey,
    email,
  } = getters;
  return {
    selectedAgency,
    operatorId: currentOperator?.key ?? null, // Optimizely complains about undefined
    routeShortName: currentRouteKey || null,
    email,
  };
};

export const userHasFeatureAccess = (state, getters) => (key) =>
  Optimizely.isFeatureEnabled(key, getters.optimizelyAttributes);

export const enabledFeatures = (state, getters) =>
  Optimizely.getEnabledFeatures(getters.optimizelyAttributes);

export const isZeroTouchLoginEnabled = (state, getters) =>
  getters.currentAgencyConfig?.defaultSettings.enableZeroTouchLogin;

export const isZeroTouchLoginPossible = (state, getters) =>
  !getters.sendGeolocationUpdates && getters.hasPermanentAssignment;

export const vehicleIsAssigned = (state, getters) =>
  getters.currentVehicleInfo?.blockId != null ||
  getters.currentVehicleInfo?.offRouteDetails?.blockId != null;

export const blockAssignmentInfo = (state, { currentOrLastValidVehicleInfo }) =>
  currentOrLastValidVehicleInfo?.blockAssignmentInfo;

export const userSettings = (state, getters) => {
  const {
    developerMode,
    headwayMode,
    showOperatorLogin,
    permanentlyAssignedVehicleId,
    sendGeolocationUpdates,
    showRouteStops,
    showTimepointsOnlyInOtpView,
    showTimepointsOnlyInMapView,
    isSimulating,
  } = getters;
  return {
    'developer-mode': developerMode,
    'headway-mode': headwayMode,
    'operator-login-enabled': showOperatorLogin,
    'permanently-assigned-vehicle-id': permanentlyAssignedVehicleId,
    'send-gps-updates': sendGeolocationUpdates,
    'show-route-stops': showRouteStops,
    'show-timepoints-only-in-otp-view': showTimepointsOnlyInOtpView,
    'show-timepoints-only-in-map-view': showTimepointsOnlyInMapView,
    'simulation-mode': isSimulating,
    'disable-safe-driving-mode': state.disableSafeDrivingMode,
  };
};

export const analyticsUserTraits = (state, getters) => {
  const {
    currentAgencyKey,
    currentAgencyConfig,
    currentOperator,
    currentVehicleId,
    email,
    isNativeApp,
    userSettings,
    vehicleId,
  } = getters;
  return {
    'agency': currentAgencyKey,
    'environment': isNativeApp ? 'native' : 'pwa',
    'nativeAppVersion': state.nativeAppVersion,
    'settings': userSettings,
    'operator': currentOperator
      ? formatOperator(currentOperator, TRANSITIME_OPERATOR_FORMAT.ID)
      : null,
    'vehicle': currentVehicleId,
    vehicleId,
    'email': email == null ? 'N/A' : fixPwsEmail(email),
    'oa-vehicles-licensed': currentAgencyConfig?.vehiclesLicensed ?? 'N/A',
    'oa-active-vehicles-goal': currentAgencyConfig?.activeVehiclesGoal ?? 'N/A',
    'screenDimensions': {
      width: window.screen.width,
      height: window.screen.height,
    },
  };
};

export const analyticsEventProperties = (state, getters) => {
  const { currentAppHash, gpsLatLonArray } = getters;
  return {
    appHash: currentAppHash,
    position: JSON.stringify(gpsLatLonArray),
  };
};

export const isOffRoute = ({
  distancesFromRouteLines: { original, detour, accuracy },
}) => {
  const accuracyWithBuffer = accuracy + 25;
  const distances = [original, detour].filter((distance) => distance != null);
  return (
    distances.length !== 0 &&
    distances.every((distance) => distance > accuracyWithBuffer)
  );
};

export const distancesFromRouteLines = (state) => state.distancesFromRouteLines;
