<template>
  <div class="component-digital-clock">
    <div class="component-digital-clock__time">{{ time }}</div>
  </div>
</template>
<script>
import format from 'date-fns/format';

export default {
  computed: {
    time() {
      return format(this.$store.getters['currentTime/date'], 'HH:mm');
    },
  },
};
</script>
<style lang="stylus" scoped>
@require "../../styl/_colors.styl"
@require "../../styl/_variables.styl"

.component-digital-clock {
  background-color transparent
  height $status-header-height
  display flex
  align-items center

  &__time {
    color $white
    font-size 2.8rem
    font-weight 600
  }
}
</style>
