// See https://github.com/SwiftlyInc/Transitime/blame/master/core/src/main/java/org/transitime/core/BlockAssignmentInfo.java#L21
const assignedStrings = [
  'Block assignment',
  'Route assignment',
  'Route short name assignment',
  'Auto assigner',
];
const unassignedStrings = [
  'Assignment terminated',
  'Vehicle timed out',
  'Could not match',
  'Bad schedule adherence',
  'Vehicle making no progress',
  'Vehicle had too many consecutive bad assignments',
  "Vehicle was unpredictable and a trusted assignment hasn't been seen in too long",
  'No assignment',
];
const assignmentBlockCanceledString = 'Block canceled';
const assignmentCompletedString = 'Assignment completed';
const assignmentStolenString = 'Assignment grabbed';

export const calculateUnassignmentReason = (blockAssignmentInfo) => {
  if (blockAssignmentInfo == null) {
    return 'unknown';
  } else if (assignedStrings.some((str) => blockAssignmentInfo.includes(str))) {
    throw new Error('Vehicle is assigned!');
  } else if (blockAssignmentInfo.includes(assignmentCompletedString)) {
    return 'completed';
  } else if (blockAssignmentInfo.includes(assignmentBlockCanceledString)) {
    return 'canceled';
  } else if (blockAssignmentInfo.includes(assignmentStolenString)) {
    return 'stolen';
  } else if (
    unassignedStrings.some((str) => blockAssignmentInfo.includes(str))
  ) {
    return 'unassigned';
  }
  return 'unknown';
};
