export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    classes: String,
  },
  render(h) {
    let svg = require(`../../images/svg-icons/${this.name}.svg`);
    // Unlike in production, our Jest raw-loader transformer returns
    // an SVG string directly, not in the default property of an object.
    svg = svg.default ?? svg;
    if (this.classes) {
      // Applying classes to svg if defined
      svg = svg.replace('<svg', `<svg class="${this.classes}"`);
    }
    return h('i', {
      domProps: {
        innerHTML: svg,
      },
    });
  },
};
