var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AnimatedBottomDrawer',{attrs:{"expandDrawer":_vm.showExpandedMessageList},on:{"close":_vm.closeDrawer}},[[_c('ul',{class:{
        'message-list': true,
        'message-list--expanded': _vm.showExpandedMessageList,
      }},[_vm._l((_vm.messagesList),function(messageItem){return _c('li',{key:messageItem.message,class:{
          'message-list__item': true,
          'message-list__item--expanded': _vm.showExpandedMessageList,
        }},[_c('IconButton',{attrs:{"iconName":messageItem.icon,"iconClass":"message-icon-small","text":messageItem.message,"customTextClasses":{ 'message-body': true }},on:{"click":function($event){return _vm.handleMessageButtonClick(
              messageItem.category,
              messageItem.message
            )}}})],1)}),_vm._v(" "),(!_vm.showExpandedMessageList)?_c('li',{staticClass:"message-list__item"},[_c('IconButton',{attrs:{"iconName":"more","iconClass":"message-icon-small","text":"All messages","customTextClasses":{ 'message-body': true }},on:{"click":_vm.expandDrawer}})],1):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }