import { Client } from '@twilio/conversations';

// TODO(haysmike)
// - Extract this into a library
// - Add tests

const ALL_EVENTS = [
  'connectionError',
  'connectionStateChanged',
  'conversationAdded',
  'conversationJoined',
  'conversationLeft',
  'conversationRemoved',
  'conversationUpdated',
  'initFailed',
  'initialized',
  'messageAdded',
  'messageRemoved',
  'messageUpdated',
  'participantJoined',
  'participantLeft',
  'participantUpdated',
  'pushNotification',
  'tokenAboutToExpire',
  'tokenExpired',
  'typingEnded',
  'typingStarted',
  'userSubscribed',
  'userUnsubscribed',
  'userUpdated',
];
const shouldLogAllEvents = false;
const logLevel = 'warn';

let twilioClient = null;

// TODO(haysmike) Is this wrapper still useful?
export const initialize = async ({
  authCallback,
  initializedCallback,
  conversationJoinedCallback,
  messageAddedCallback,
  connectionStateChangeCallback,
  errorCallback,
}) => {
  try {
    const jwt = await authCallback();
    twilioClient = new Client(jwt, { logLevel });
  } catch (error) {
    errorCallback('initialize', error);
    return;
  }
  if (shouldLogAllEvents) {
    window.twilioClient = twilioClient;
    for (const event of ALL_EVENTS) {
      twilioClient.on(event, (...args) => console.log(event, ...args));
    }
  }
  twilioClient.on('connectionStateChanged', connectionStateChangeCallback);
  twilioClient.on('initialized', initializedCallback);
  twilioClient.on('conversationJoined', conversationJoinedCallback);
  twilioClient.on('messageAdded', messageAddedCallback);
  twilioClient.on('tokenAboutToExpire', async () => {
    try {
      const jwt = await authCallback();
      await twilioClient.updateToken(jwt);
    } catch (error) {
      // Almost always caused by disconnects, no need to send to Sentry
      console.warn('Error in `tokenAboutToExpire` event handler', error);
    }
  });
  twilioClient.on('tokenExpired', async () => {
    try {
      const jwt = await authCallback();
      await twilioClient.updateToken(jwt);
    } catch (error) {
      errorCallback('tokenExpired', error);
    }
  });
  twilioClient.on('connectionError', () => errorCallback('connectionError'));
};

export const terminate = async () => {
  await twilioClient.shutdown();
  twilioClient.removeAllListeners();
  twilioClient = null;
};

// TODO(haysmike) Could probably decompose this further.
export const findOrCreateConversation = async (uniqueName, attributes) => {
  let conversation = null;

  try {
    conversation = await twilioClient.getConversationByUniqueName(uniqueName);
  } catch (error) {
    if (error.status !== 404) {
      throw error;
    }
  }

  if (conversation == null) {
    conversation = await twilioClient.createConversation({
      uniqueName,
      attributes,
    });
  }

  // We probably only need to do this if creating a new conversation, but it
  // would make adding attributes to existing conversations inconvenient.
  await conversation.updateAttributes(attributes);

  // We probably only need to do this if creating a new conversation, but let's
  // be safe for now.
  const participant = await conversation.getParticipantByIdentity(
    twilioClient.user.identity,
  );
  if (participant == null) {
    await conversation.join();
  }

  return conversation;
};

export const ensureParticipants = async (conversation, identities) => {
  for (const identity of identities) {
    let participant = null;
    try {
      // TODO(haysmike) It seems like this sometimes returns `null` and sometimes throws an error.
      participant = await conversation.getParticipantByIdentity(identity);
    } catch (error) {
      if (!error.message.includes('not found')) {
        throw error;
      }
    }
    if (participant == null) {
      await conversation.add(identity);
    }
  }
};

export const updateParticipantAttributes = async (conversation, attributes) => {
  // It seems like Twilio client doesn't load participants without this call.
  await conversation.getParticipants();
  const participant = await conversation.getParticipantByIdentity(
    twilioClient.user.identity,
  );
  await participant.updateAttributes(attributes);
};

export const sendMessage = async (uniqueName, body, attributes = {}) => {
  const conversation = await twilioClient.getConversationByUniqueName(
    uniqueName,
  );
  await conversation
    .prepareMessage()
    .setBody(body)
    .setAttributes(attributes)
    .build()
    .send();
};

export const isInitialized = () => twilioClient != null;
