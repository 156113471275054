<template>
  <div class="message-view">
    <div class="title">Messages</div>
    <IconButton
      v-show="isRestartMessagingButtonEnabled"
      iconName="reset"
      class="restart"
      :isDisabled="isMessagesRestarting"
      @click="restartMessaging"
    />
    <div class="messages" ref="messages" @scroll="handleScroll">
      <div v-if="messages.length === 0" class="placeholder">
        No previous messages. Tap the new message button below to start a
        conversation.
      </div>
      <div
        :class="{
          'message': true,
          'message--operator': isOperatorMessage(message),
        }"
        v-for="(message, index) in messages"
        :key="message.id"
      >
        <div
          v-if="shouldShowDateSeparator(index, messages)"
          class="message__date-separator"
        >
          {{ getDateSeparatorText(message.dateCreated) }}
        </div>
        <div>
          <span class="message__participant">
            {{
              isOperatorMessage(message) ? `Vehicle ${vehicleId}` : 'Dispatch'
            }}
          </span>
          <span class="message__timestamp">
            {{ getFormattedTime(message.dateCreated) }}
          </span>
        </div>
        <div class="message__body">
          {{ message.body }}
        </div>
      </div>
    </div>
    <div
      v-if="shouldHideReplyButtons && !showDrawer"
      class="new-message-button"
    >
      <IconButton
        iconName="plus"
        iconClass="svg-plus-medium"
        text="New message"
        :customTextClasses="{ 'new-message-button__text': true }"
        :isDisabled="isMessagesRestarting"
        @click="toggleDrawer"
      />
    </div>
    <div v-else-if="!showDrawer" class="reply-buttons">
      <IconButton
        iconName="plus"
        iconClass="svg-plus-big"
        :isDisabled="isMessagesRestarting"
        @click="toggleDrawer"
      />
      <button
        v-for="message in replyMessages"
        :disabled="isMessagesRestarting"
        :key="message"
        @click="handleSendMessage(message)"
      >
        {{ message }}
      </button>
    </div>
    <CannedMessageDrawer
      v-else
      @close-drawer="toggleDrawer"
      @send-message="handleSendMessage"
    />
  </div>
</template>
<script>
import { isSameDay, isToday } from 'date-fns';
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

import * as Segment from '../../analytics/segment';

import CannedMessageDrawer from './CannedMessageDrawer.vue';

export default {
  components: { CannedMessageDrawer },
  props: {
    vehicleIsMoving: {
      type: Boolean,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      userLocale: this.$store.state.userLocale,
      replyMessages: ['10-4 OK', 'Yes', 'No'],
      showDrawer: false,
    };
  },
  computed: {
    ...mapState({
      messages: (state) => state.messages.messages,
    }),
    ...mapGetters({ isMessagesRestarting: 'messages/isRestarting' }),
    vehicleId() {
      return this.$store.getters.hasPermanentAssignment
        ? this.$store.getters.permanentlyAssignedVehicleId
        : this.$store.getters.currentVehicleId;
    },
    shouldHideReplyButtons() {
      return (
        this.messages.length === 0 ||
        this.messages[this.messages.length - 1].participant.attributes.role ===
          'operator'
      );
    },
    //(timl)Hardcoded because this is an experimental feature
    //for a problem specifically for bsoob
    isRestartMessagingButtonEnabled() {
      return this.$store.getters.currentAgencyKey === 'bsoob';
    },
  },
  watch: {
    messages: {
      handler() {
        // Wait until after render to allow visual properties like `scrollHeight` to update.
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      },
      immediate: true,
    },
    isVisible() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
  methods: {
    shouldShowDateSeparator(index, messages) {
      return (
        index === 0 ||
        !isSameDay(
          new Date(messages[index].dateCreated),
          new Date(messages[index - 1].dateCreated),
        )
      );
    },
    getDateSeparatorText(dateCreated) {
      const date = new Date(dateCreated);
      return isToday(date)
        ? `Today ${this.getFormattedTime(dateCreated)}`
        : `${date.toLocaleDateString(this.userLocale, {
            month: 'short',
            weekday: 'short',
            day: 'numeric',
          })}, ${this.getFormattedTime(dateCreated)}`;
    },
    getFormattedTime(dateCreated) {
      return new Date(dateCreated)
        .toLocaleTimeString(this.userLocale, {
          timeStyle: 'short',
        })
        .toLowerCase();
    },
    handleSendMessage(message) {
      const { currentAgencyKey: agencyId, currentOperator } =
        this.$store.getters;
      Segment.track('canned-message-sent', {
        agencyId,
        operatorId: currentOperator != null ? currentOperator.key : null,
        message,
        timestamp: new Date().toISOString(),
      });
      this.$store.dispatch('messages/sendChatMessage', message);
    },
    handleScroll: _.debounce(
      function () {
        const { messages } = this.$refs;
        getComputedStyle(messages)
          .getPropertyValue('--scrollbar-visibility')
          .trim() === 'hidden'
          ? messages.style.setProperty('--scrollbar-visibility', 'visible')
          : messages.style.setProperty('--scrollbar-visibility', 'hidden');
      },
      500,
      { leading: true, trailing: true },
    ),
    isOperatorMessage(message) {
      return message.participant.attributes.role === 'operator';
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    scrollToBottom() {
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
    },
    restartMessaging: _.debounce(
      function () {
        const {
          currentAgencyKey: agencyId,
          currentOperator,
          currentVehicleId,
        } = this.$store.getters;
        Segment.track('restart-messages-clicked', {
          agencyId,
          operatorId: currentOperator != null ? currentOperator.key : null,
          vehicleId: currentVehicleId,
        });
        this.$store.dispatch('messages/restart');
      },
      1000,
      { leading: true, trailing: true },
    ),
  },
};
</script>
<style lang="stylus" scoped>
@require "../../styl/_colors.styl"
@require "../../styl/_variables.styl"

.message-view {
  position absolute
  top 0
  right 0
  width 100%
  height 100%
  padding-bottom $nav-bar-height

  display flex
  flex-direction column
  color $white
  background $background-color
}

.placeholder {
  margin 40px auto
  color $white-trnsp-060
  font-size 2.8rem
  line-height 1.5
}

.title {
  display flex
  justify-content center
  align-items center
  min-height 5.4rem
  margin-top 20px
  font-size 2.8rem
  font-weight 400
}

.restart {
  position absolute
  top 0
  right 0
  margin 20px
}

.back-button {
  padding 0
  position absolute
  top 2rem
  left 2rem
  width 5rem
  height 5rem
}

.messages,
.new-message-button {
  width clamp(300px, 75%, 1000px)
  margin 0 auto
}

.messages {
  flex-basis 100%
  --scrollbar-visibility visible
  padding 0 16px
  overflow-y auto

  &::before {
    content ''
    display block
    position sticky
    top 0
    width 100%
    height 28px
    background linear-gradient($background-color, transparent)
  }

  &::-webkit-scrollbar {
    display block
    width 8px

    &-thumb {
      visibility var(--scrollbar-visibility)
      background $scrollbar-color
      border-radius 12px
    }
  }
}

.message {
  display flex
  flex-direction column
  align-items flex-start
  margin-bottom 56px
  font-size 2.8rem
  line-height 1.5

  &--operator {
    align-items flex-end
    text-align right

    & ^[0]__body {
      background-color $color-primary-blue
    }
  }

  &__date-separator {
    align-self center
    margin-bottom 28px
    font-size 2rem
    line-height 4.2rem
  }

  &__participant {
    font-weight 700
  }

  &__timestamp {
    margin-left 16px
  }

  &__body {
    margin 16px 0 0
    padding 16px 24px
    max-width 70%
    font-weight 400
    background-color $white-trnsp-014
    border-radius 16px
  }
}

.new-message-button {
  display flex
  justify-content start
  margin-bottom 5%

  >>>&__text {
    margin 0
  }

  button {
    background $white-trnsp-010
    border 1px solid $border-default
    // Hack - If border-radius causes corner curves to overlap, the radius is reduced until they don't.
    // See https://drafts.csswg.org/css-backgrounds/#corner-overlap
    border-radius 500px
    color $white
    font-size 2.8rem
    padding 1.6rem 3.6rem
    display flex
    flex-direction row
    justify-content center
    align-items center

    >>>i{
      height 3.5rem
      margin-right 0.5rem
    }
  }
}

.reply-buttons {
  display flex
  justify-content space-evenly
  margin 0 5% 5%

  button {
    background $white-trnsp-010
    border 1px solid $border-default
    // Hack - If border-radius causes corner curves to overlap, the radius is reduced until they don't.
    // See https://drafts.csswg.org/css-backgrounds/#corner-overlap
    border-radius 500px
    color $white
    font-size 2.8rem
    padding 16px 6px
    width 170px

    &:disabled {
      opacity 0.38
    }
  }
}
</style>
