<template>
  <div>
    <div class="banner">
      <inline-svg name="cached" classes="spin cached"></inline-svg>
      <span>{{ partialErrorMessage }}</span>
    </div>
    <div class="error-overlay" />
  </div>
</template>

<script>
export default {
  name: 'PartialError',
  props: { showPartialError: { type: String, required: true } },
  computed: {
    partialErrorMessage() {
      switch (this.showPartialError) {
        case 'connection':
          return 'Internet connection lost. Reconnecting...';
        case 'server':
          return 'Server connection lost. Reconnecting...';
      }
      return '';
    },
  },
};
</script>

<style lang="stylus">
@require "../../styl/_colors.styl"
@require "../../styl/_variables.styl"
@require "../../styl/_svg-icon-adjustments.styl"

.error-overlay {
    position absolute
    top $partial-error-height
    left 0
    bottom 0
    width 100%
    background-color $black-trnsp-067
}

.banner {
    position absolute
    z-index 2
    left 0
    top 0
    width 100%
    color $cerulean
    font-size 3rem
    font-weight 400
    background-color $swans-down

    display flex
    flex-direction row
    justify-content center

    > i {
        font-size 4.2rem
        margin-right 0.6rem
        display flex
        flex-direction column
        justify-content center
    }

    > span {
        height $partial-error-height
        line-height $partial-error-height
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
    }
}

// 600px is fairly arbitrary, revisit perhaps?
@media screen and (max-width 600px) {
    .banner {
        font-size 2rem
    }
}
</style>
