<template>
  <div class="route-stops" @click="incrementListPositionOffset">
    <div class="fade top"></div>
    <div class="headsign">TO {{ headsign }}</div>
    <div class="stage-wrap" v-show="isAnimating">
      <div id="animation-stage" class="rows animation-stage">
        <template v-for="(stop, index) in animationStageStops">
          <div
            v-if="stop"
            :key="index"
            :class="getRowCssClassForStop(index, stop)"
          >
            <div class="stop-name">{{ stop.stopName }}</div>
            <div v-if="showAnimationStageLine(index)" class="line-wrap">
              <div class="line"></div>
              <div v-if="stop.isFirstStop" class="line-blocker top"></div>
              <div v-if="stop.isLastStop" class="line-blocker bottom"></div>
            </div>
            <div class="dot-wrap" v-if="stop.stopName">
              <div
                class="dot"
                :class="{ 'wait-stop': stop.isWaitStopStyle }"
              ></div>
            </div>
            <div v-if="stop.adjustments" class="alert">
              <span v-if="adjustmentsAlertText(stop.adjustments)">
                {{ adjustmentsAlertText(stop.adjustments) }}
              </span>
            </div>
            <div
              v-else-if="stop.departureTimeHumanReadable != null"
              class="alert scheduled-departure"
            >
              <span>Depart at {{ stop.departureTimeHumanReadable }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="stage-wrap" v-show="!isAnimating">
      <div class="rows main-stage">
        <template v-for="(stop, index) in mainStageStops">
          <div
            v-if="stop"
            :key="index"
            :class="getRowCssClassForStop(index, stop)"
          >
            <div class="stop-name">{{ stop.stopName }}</div>
            <div v-if="showMainStageLine(index)" class="line-wrap">
              <div class="line"></div>
              <div v-if="stop.isFirstStop" class="line-blocker top"></div>
              <div v-if="stop.isLastStop" class="line-blocker bottom"></div>
            </div>
            <div class="dot-wrap" v-if="stop.stopName">
              <div
                class="dot"
                :class="{ 'wait-stop': stop.isWaitStopStyle }"
              ></div>
            </div>
            <div v-if="stop.adjustments" class="alert">
              <span v-if="adjustmentsAlertText(stop.adjustments)">
                {{ adjustmentsAlertText(stop.adjustments) }}
              </span>
            </div>
            <div
              v-else-if="stop.departureTimeHumanReadable != null"
              class="alert scheduled-departure"
            >
              <span>Depart at {{ stop.departureTimeHumanReadable }}</span>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="fade bottom"></div>
  </div>
</template>

<script>
import { TweenMax } from 'gsap/all';
import _ from 'lodash';

import { buildStopsList } from '../utils/build-stops-list';
import convertAdjustmentsToAlertText from '../utils/convert-adjustments-to-alert-text';
import sleep from '../utils/sleep';

export default {
  name: 'RouteStops',
  data() {
    return {
      isAnimating: false,
      mainStageStops: [],
      animationStageStops: [],
      listPositionOffset: 0,
      lastListOfStops: [],
      lastListPosition: null,
    };
  },
  computed: {
    headsign() {
      return this.$store.getters.currentVehicleHeadsign;
    },
    currentStopId() {
      return (
        this.$store.getters.currentOrLastValidVehicleInfo?.nextStopId ?? ''
      );
    },
    limitToTimepoints() {
      return this.$store.getters.showTimepointsOnlyInOtpView;
    },
    listOfStops() {
      return buildStopsList({
        limitToPriorityStops: this.limitToTimepoints,
        currentTripStopPathsByStopId:
          this.$store.getters.currentTripStopPathsByStopId,
        fullSchedule: this.$store.getters.currentTripSchedule || [],
      });
    },
    listPosition() {
      return this.listPositionRaw + this.listPositionOffset;
    },
    listPositionRaw() {
      let currentStopScheduleInfoIndex = _.findIndex(
        this.listOfStops,
        (stopScheduleInfo) =>
          _.get(stopScheduleInfo, 'stopId') === this.currentStopId,
      );
      if (this.limitToTimepoints && currentStopScheduleInfoIndex === -1) {
        const nextTimepointStopId = this.getNextTimepointStopIdForStopId(
          this.currentStopId,
        );
        currentStopScheduleInfoIndex = _.findIndex(
          this.listOfStops,
          (stopScheduleInfo) =>
            _.get(stopScheduleInfo, 'stopId') === nextTimepointStopId,
        );
        if (currentStopScheduleInfoIndex !== -1) {
          return currentStopScheduleInfoIndex;
        }
        return null;
      }
      return currentStopScheduleInfoIndex;
    },
  },
  watch: {
    listPositionRaw() {
      const vc = this;
      if (!vc.$store.getters.developerMode) {
        return;
      }
      if (
        vc.listPositionRaw + vc.listPositionOffset >
        vc.listOfStops.length - 1
      ) {
        vc.listPositionOffset = vc.listPositionRaw * -1;
      }
    },
    listPosition() {
      const vc = this;
      vc.animate();
    },
    listOfStops() {
      const vc = this;
      vc.animate();
    },
  },
  async mounted() {
    const vc = this;
    await sleep(100);
    vc.animate();
  },
  methods: {
    getStop(positionIndex) {
      const vc = this;
      return _.get(vc.listOfStops, vc.listPosition + positionIndex, {
        stopId: positionIndex + 1,
        stopName: '',
      });
    },
    getCurrentVersionOfStop(stopId) {
      const vc = this;
      return _.find(vc.listOfStops, (stop) => stop.stopId === stopId);
    },
    getLastVersionOfStop(stopId) {
      const vc = this;
      return _.find(vc.lastListOfStops, (stop) => stop.stopId === stopId);
    },
    showMainStageLine(index) {
      const vc = this;
      return vc.mainStageStops[index] && vc.mainStageStops[index].stopName;
    },
    showAnimationStageLine(index) {
      const vc = this;
      return (
        vc.animationStageStops[index] && vc.animationStageStops[index].stopName
      );
    },
    getRowCssClassForStop(index) {
      const classes = {};
      classes.row = true;
      classes[`row-${index}`] = true;
      return classes;
    },
    getNextTimepointStopIdForStopId(stopId) {
      const vc = this;
      const currentTripStopPaths = vc.$store.getters.currentTripStopPaths;
      let hasFoundStop = false;
      for (const [index, stopPathInfo] of currentTripStopPaths.entries()) {
        const currentStopId = _.get(stopPathInfo, 'stopId');
        let currentStopIsWaitStop = _.get(stopPathInfo, 'waitStop');
        if (index === 0) {
          currentStopIsWaitStop = true;
        }
        if (index === currentTripStopPaths.length - 1) {
          currentStopIsWaitStop = true;
        }
        if (
          !currentStopIsWaitStop &&
          vc.$store.getters.getAdjustmentsForStopId(currentStopId)
        ) {
          currentStopIsWaitStop = true;
        }
        if (!hasFoundStop && currentStopId === stopId) {
          hasFoundStop = true;
          if (currentStopIsWaitStop) {
            return currentStopId;
          }
        } else if (hasFoundStop) {
          if (currentStopIsWaitStop) {
            return currentStopId;
          }
        }
      }
      return null;
    },
    adjustmentsAlertText(adjustments) {
      const vc = this;
      return convertAdjustmentsToAlertText(
        adjustments,
        vc.$store.state.userLocale,
      );
    },
    incrementListPositionOffset() {
      const vc = this;
      if (vc.isAnimating) {
        return;
      }
      if (!vc.$store.getters.developerMode) {
        return;
      }
      if (
        vc.listPositionRaw + (vc.listPositionOffset + 1) >
        vc.listOfStops.length - 1
      ) {
        vc.listPositionOffset = vc.listPositionRaw * -1;
      } else {
        vc.listPositionOffset = vc.listPositionOffset + 1;
      }
    },
    animate: _.debounce(
      async function () {
        const vc = this;

        if (vc.isAnimating) {
          return;
        }

        const firstStopIdHasChanged =
          _.get(_.first(vc.lastListOfStops), 'stopId') !==
          _.get(_.first(vc.listOfStops), 'stopId');
        const lastStopIdHasChanged =
          _.get(_.last(vc.lastListOfStops), 'stopId') !==
          _.get(_.last(vc.listOfStops), 'stopId');
        const hasNewStopList = firstStopIdHasChanged || lastStopIdHasChanged;

        const listPositionHasChanged = vc.lastListPosition !== vc.listPosition;
        const listPositionHasIncreasedByOne =
          vc.lastListPosition + 1 === vc.listPosition;
        const listOfStopsLengthHasIncreasedByOne =
          _.get(vc.lastListOfStops, 'length') + 1 ===
          _.get(vc.listOfStops, 'length');
        const listOfStopsLengthHasDecreasedByOne =
          _.get(vc.lastListOfStops, 'length') - 1 ===
          _.get(vc.listOfStops, 'length');

        const stopsWithAddedAdjustments = [];
        const stopsWithRemovedAdjustments = [];
        for (const slotIndex of [0, 1, 2, 3]) {
          const currentStop = _.get(vc.listOfStops, [
            vc.listPosition + slotIndex,
          ]);
          const currentStopId = _.get(currentStop, 'stopId');
          const currentAdjustment = _.get(currentStop, 'adjustments');
          const previousStop = vc.getLastVersionOfStop(currentStopId);
          const previousAdjustment = _.get(previousStop, 'adjustments');
          const hasNewAdjustment =
            Boolean(currentAdjustment) &&
            !_.isEqual(currentAdjustment, previousAdjustment);
          const hasRemovedAdjustment =
            !currentAdjustment && Boolean(previousAdjustment);
          if (hasNewAdjustment) {
            stopsWithAddedAdjustments.push(currentStop);
          } else if (hasRemovedAdjustment) {
            stopsWithRemovedAdjustments.push(currentStop);
          }
        }
        for (const oldStop of vc.lastListOfStops) {
          if (_.isEmpty(_.get(oldStop, 'adjustments'))) {
            continue;
          }
          const currentStop = vc.getCurrentVersionOfStop(
            _.get(oldStop, 'stopId'),
          );
          if (!currentStop) {
            stopsWithRemovedAdjustments.push(oldStop);
          }
        }

        const hasNewAdjustments = !_.isEmpty(stopsWithAddedAdjustments);
        const hasRemovedAdjustments = !_.isEmpty(stopsWithRemovedAdjustments);
        const hasUpdatedAdjustments =
          hasNewAdjustments || hasRemovedAdjustments;
        const newAdjustmentsAhead = _.filter(
          stopsWithAddedAdjustments,
          (stop) => stop.gtfsStopSeq > _.get(vc.getStop(0), 'gtfsStopSeq'),
        );
        const hasNewAdjustmentsAhead = !_.isEmpty(newAdjustmentsAhead);

        // Case: initial slide up
        if (hasNewStopList && !hasRemovedAdjustments) {
          // As the offset is only relative to a given set of stops, reset any offset
          vc.listPositionOffset = 0;
          await vc.animateForwardsAndSync();
          vc.lastListOfStops = _.cloneDeep(vc.listOfStops);
          vc.lastListPosition = vc.listPosition;
          return;
        }

        // Case: standard increment by 1
        if (
          (!hasUpdatedAdjustments && listPositionHasIncreasedByOne) ||
          (listPositionHasChanged && vc.listPosition === 0)
        ) {
          await vc.animateForwardsAndSync();
          vc.lastListOfStops = _.cloneDeep(vc.listOfStops);
          vc.lastListPosition = vc.listPosition;
          return;
        }

        // Case: has removed single adjustment but the list position is the same
        if (
          hasRemovedAdjustments &&
          listOfStopsLengthHasDecreasedByOne &&
          !listPositionHasChanged &&
          vc.listPosition !== 0
        ) {
          await vc.animateForwardsAndSync();
          vc.lastListOfStops = _.cloneDeep(vc.listOfStops);
          vc.lastListPosition = vc.listPosition;
          return;
        }

        // Case: has added single adjustment
        if (
          hasNewAdjustments &&
          !hasNewAdjustmentsAhead &&
          listOfStopsLengthHasIncreasedByOne &&
          !listPositionHasChanged
        ) {
          await vc.animateBackwardsAndSync();
          vc.lastListOfStops = _.cloneDeep(vc.listOfStops);
          vc.lastListPosition = vc.listPosition;
          return;
        }

        // Catchall for other updates
        if (hasNewStopList || hasUpdatedAdjustments || listPositionHasChanged) {
          await vc.syncMainStageStops();
          vc.lastListOfStops = _.cloneDeep(vc.listOfStops);
          vc.lastListPosition = vc.listPosition;
        }
      },
      250,
      {
        leading: false,
        trailing: true,
      },
    ),
    syncMainStageStops() {
      const vc = this;
      return new Promise(async (resolve) => {
        vc.animationStageStops[0] = vc.mainStageStops[0];
        vc.animationStageStops[1] = vc.mainStageStops[1];
        vc.animationStageStops[2] = vc.mainStageStops[2];
        vc.animationStageStops[3] = vc.mainStageStops[3];
        vc.isAnimating = true;
        vc.mainStageStops[0] = vc.getStop(0);
        vc.mainStageStops[1] = vc.getStop(1);
        vc.mainStageStops[2] = vc.getStop(2);
        vc.mainStageStops[3] = vc.getStop(3);
        vc.isAnimating = false;
        resolve();
      });
    },
    animateBackwardsAndSync() {
      const vc = this;
      return new Promise(async (resolve) => {
        // TODO: Dynamicize the amount to animate backwards
        const STEPS_TO_ANIMATE_BACK = 1;

        vc.animationStageStops[0] = vc.getStop(STEPS_TO_ANIMATE_BACK - 1);
        vc.animationStageStops[1] = vc.getStop(STEPS_TO_ANIMATE_BACK + 0);
        vc.animationStageStops[2] = vc.getStop(STEPS_TO_ANIMATE_BACK + 1);
        vc.animationStageStops[3] = vc.getStop(STEPS_TO_ANIMATE_BACK + 2);

        const slot0Pixels = vc.getSlotHeight(vc.animationStageStops[0]);

        const tween = { bottomValue: slot0Pixels };

        const $stage = document.getElementById('animation-stage');
        if ($stage) {
          $stage.style.bottom = `${(tween.bottomValue / 10).toFixed(3)}rem`;
        }

        vc.isAnimating = true;
        await sleep(0);

        TweenMax.to(tween, 2, {
          ease: 'power2',
          bottomValue: tween.bottomValue - slot0Pixels,
          onUpdate: () => {
            if ($stage) {
              $stage.style.bottom = `${(tween.bottomValue / 10).toFixed(3)}rem`;
            }
          },
          onComplete: async () => {
            vc.mainStageStops[0] = vc.getStop(0);
            vc.mainStageStops[1] = vc.getStop(1);
            vc.mainStageStops[2] = vc.getStop(2);
            vc.mainStageStops[3] = vc.getStop(3);
            vc.isAnimating = false;
            resolve();
          },
        });
      });
    },
    getSlotHeight(stop) {
      const ROW_HEIGHT_DEFAULT = 80;
      const ROW_HEIGHT_ADJUSTMENT = 99;
      const ROW_HEIGHT_SCHEDULED_DEPARTURE = 107;
      // Since adjustment takes precedence in UI, important it comes first
      const hasAdjustment = _.isArray(_.get(stop, 'adjustments'));
      if (hasAdjustment) {
        return ROW_HEIGHT_ADJUSTMENT;
      }
      const hasScheduledDeparture = _.isString(
        _.get(stop, 'departureTimeHumanReadable'),
      );
      if (hasScheduledDeparture) {
        return ROW_HEIGHT_SCHEDULED_DEPARTURE;
      }
      return ROW_HEIGHT_DEFAULT;
    },
    animateForwardsAndSync() {
      const vc = this;
      return new Promise(async (resolve) => {
        vc.animationStageStops[0] = vc.getStop(-1);
        vc.animationStageStops[1] = vc.getStop(0);
        vc.animationStageStops[2] = vc.getStop(1);
        vc.animationStageStops[3] = vc.getStop(2);

        const incrementalAnimation = vc.listPosition !== 0;
        const animationSeconds = incrementalAnimation ? 0.75 : 2;

        const slot0Pixels = vc.getSlotHeight(vc.animationStageStops[0]);
        const slot1Pixels = vc.getSlotHeight(vc.animationStageStops[1]);
        const slot2Pixels = vc.getSlotHeight(vc.animationStageStops[2]);
        const slot3Pixels = vc.getSlotHeight(vc.animationStageStops[3]);

        const pixelsToAnimate = slot0Pixels;

        const bottomValue = incrementalAnimation
          ? 0
          : (slot0Pixels + slot1Pixels + slot2Pixels + slot3Pixels) * -1;

        const $stage = document.getElementById('animation-stage');

        const tween = { bottomValue };
        if ($stage) {
          $stage.style.bottom = `${(tween.bottomValue / 10).toFixed(3)}rem`;
        }

        vc.isAnimating = true;
        await sleep(0);

        TweenMax.to(tween, animationSeconds, {
          ease: 'power2',
          bottomValue: pixelsToAnimate,
          onUpdate: () => {
            if ($stage) {
              $stage.style.bottom = `${(tween.bottomValue / 10).toFixed(3)}rem`;
            }
          },
          onComplete: () => {
            vc.mainStageStops[0] = vc.getStop(0);
            vc.mainStageStops[1] = vc.getStop(1);
            vc.mainStageStops[2] = vc.getStop(2);
            vc.mainStageStops[3] = vc.getStop(3);
            vc.isAnimating = false;
            resolve();
          },
        });
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../styl/_colors.styl"

.route-stops {
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    overflow hidden

    .headsign {
        z-index 4
        position absolute
        top 0
        left 0
        width 100%
        height 3rem
        line-height 3rem
        font-size 2.4rem
        font-weight 500
        color $white-trnsp-080
        text-align left
        text-transform uppercase
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        background-color $cod-gray
    }

    .fade {
        z-index 3
        position absolute
        left 0
        width 100%

        background $cod-gray
        &.top {
            height 4rem
            top 2rem
            background linear-gradient(180deg, $cod-gray 30%, transparent 100%)
        }
        &.bottom {
            height 3.5rem
            bottom 0
            background linear-gradient(0deg, $cod-gray 10%, transparent 100%)
        }
    }

    .rows {
        position absolute
        bottom 0
        left 0
        width 100%
        height calc( 100% - 4rem )

        &.animation-stage {
            .row {
                &.row-0 {
                    font-weight 300
                }
                &.row-1 {
                    font-weight 500
                    color $white
                    .dot-wrap .dot {
                        width 2rem
                        height 2rem
                    }
                    .stop-name {
                        font-size 2.6rem
                    }
                }
            }
        }

        .row {
            position relative
            width 100%
            color $alto
            text-align left
            text-indent 3rem
            font-weight 300
            white-space nowrap

            &.row-0 {
                font-weight 500
                color $white
                .dot-wrap .dot {
                    width 2rem
                    height 2rem
                }
                .stop-name {
                    font-size 2.6rem
                }
            }

            .stop-name {
                z-index 2
                height 8rem
                line-height 8rem
                font-size 2.4rem
                background-color $cod-gray
                white-space nowrap
                overflow hidden
                text-overflow ellipsis
            }

            .line-wrap {
                position absolute
                top 0
                left 1.1rem
                width 1px
                height 100%
                background-color $white

                .line-blocker {
                    position absolute
                    bottom 0
                    left 0
                    width 2px
                    height 45%
                    background-color $cod-gray
                    &.top {
                      top 0
                    }
                    &.bottom {
                      bottom 0
                    }
                }
            }

            .dot-wrap {
                position absolute
                left 0
                top 0
                height 8rem
                width 2.3rem
                margin 0
                display flex
                align-items center
                justify-content center

                .dot {
                    z-index 2
                    width 1.4rem
                    height 1.4rem
                    border-radius 50%
                    background-color $black
                    box-shadow inset 0px 0px 0px 1px $white

                    &.wait-stop {
                        background-color $white
                    }
                }
            }

            .alert {
                position relative
                top -0.7rem
                > span {
                    padding 0.6rem 1rem
                    color $tree-poppy
                    font-size 2.2rem
                    font-weight 300
                    background-color $oil
                    border 1px solid $tree-poppy
                    border-radius 4px
                }
                &.scheduled-departure {
                    top -1rem
                    > span {
                        padding 0
                        background-color transparent
                        border none
                        color $white-trnsp-080
                        font-size 2.4rem
                        font-weight 300
                    }
                }
            }
        }
    }
}
</style>
