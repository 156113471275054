<template>
  <div id="operator-login">
    <FullErrorLogo v-if="showFullError" :showFullError="showFullError" />
    <div
      v-else
      id="selections-wrap"
      class="animated fadeIn"
      :class="{
        'blur': showPartialError,
        'showing-confirmation-info': showConfirmationInfo,
      }"
    >
      <div v-if="!showConfirmationInfo" class="selections">
        <div class="selection-wrap vehicle">
          <div class="icon-wrap">
            <inline-svg
              name="directions_bus"
              classes="icon-smaller"
            ></inline-svg>
          </div>
          <div>
            <input
              id="input-vehicle"
              type="text"
              placeholder="Select Vehicle ID (tap here for search)"
              v-$model-vehicle-filter-text="vehicleFilterText"
            />
          </div>
        </div>
      </div>
      <div v-if="showFilteredResults" id="filtered-results">
        <div
          v-for="result in filteredResults()"
          :class="`result ${result.customClass}`"
          :key="result.key"
          :value="result.value"
          @click="selectResult(result.value)"
        >
          <div class="icon-wrap">
            <inline-svg
              :name="result.iconName"
              classes="icon-smaller"
            ></inline-svg>
          </div>
          <div class="result-value">{{ result.displayName }}</div>
        </div>
      </div>
      <div
        v-else-if="!vehicleId"
        id="filtered-results"
        class="selection-focus-vehicle"
      >
        <div v-if="showLoadingSpinner" class="loading-spinner">
          <LoadingAnimation />
        </div>
        <div
          v-else
          class="no-result-message"
          v-html="noFilteredResultsHtml"
        ></div>
      </div>
      <div
        v-if="showConfirmationInfo"
        id="confirmation-info"
        class="animated fadeIn"
      >
        <div class="confirmation-title">Confirm Assignment</div>
        <div class="confirmation-subtitle" v-html="confirmationTextHtml"></div>
        <div id="next-button" @click="onConfirmAssignment">
          Confirm assignment
        </div>
      </div>
    </div>
    <PartialError
      v-if="showPartialError && !showFullError"
      :showPartialError="showPartialError"
    />
  </div>
</template>

<script>
import _ from 'lodash';

import { track } from '../analytics/segment';
import { sanitizeVehicleIds } from '../utils/sanitize-vehicle-ids';

import LoadingAnimation from './common/LoadingAnimation.vue';

let _loginInfoInterval = undefined;
let _offlineTimeout = undefined;
let _serverErrorTimeout = undefined;

export default {
  name: 'PermanentVehicleAssignment',
  components: { LoadingAnimation },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousRouteName = from.name;
    });
  },
  data() {
    return {
      vehicleFilterText: '',
      vehicleId: '',
      previousRouteName: '',

      forceVehicleLoader: false,
      temporarilyPreventSelections: false,

      hasConnectionError: false,
      hasWaitedForConnectionError: false,

      hasServerError: false,
      hasWaitedForServerError: false,
    };
  },
  computed: {
    showLoadingSpinner() {
      if (this.forceVehicleLoader) {
        return true;
      }
      if (!this.vehicleId) {
        const hasVehicleFilterText = Boolean(this.vehicleFilterText);
        const hasNoVehicleResults = _.isEmpty(this.filteredResults());
        return !(hasVehicleFilterText && hasNoVehicleResults);
      }
      return false;
    },
    showPartialError() {
      const {
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
      } = this;
      if (hasConnectionError && !hasWaitedForConnectionError) {
        return 'connection';
      }
      if (hasServerError && !hasWaitedForServerError) {
        return 'server';
      }
      return '';
    },
    showFullError() {
      const {
        hasConnectionError,
        hasWaitedForConnectionError,
        hasServerError,
        hasWaitedForServerError,
      } = this;
      const hasConnectionErrorFromScreenWhileDriving =
        !hasConnectionError &&
        !hasWaitedForConnectionError &&
        !this.$store.getters.isOnline;
      const hasServerErrorFromScreenWhileDriving =
        !hasServerError &&
        !hasWaitedForServerError &&
        this.$store.getters.serverErrorVehicleInfo;
      if (
        hasConnectionErrorFromScreenWhileDriving &&
        hasServerErrorFromScreenWhileDriving
      ) {
        this.$store.commit('serverErrorVehicleInfo', false);
        return 'connection';
      }
      if (hasServerErrorFromScreenWhileDriving) {
        this.$store.commit('serverErrorVehicles', true);
        return 'server';
      }
      if (
        (hasConnectionError && hasWaitedForConnectionError) ||
        hasConnectionErrorFromScreenWhileDriving
      ) {
        return 'connection';
      }
      if (hasServerError && hasWaitedForServerError) {
        return 'server';
      }
      return '';
    },
    showConfirmationInfo() {
      return this.vehicleId;
    },
    showFilteredResults() {
      // Delay rendering large vehicle list immediately after sign in tap to allow for a snappier UI transition
      if (this.forceVehicleLoader) {
        return false;
      }
      if (this.showConfirmationInfo) {
        return false;
      }
      return !_.isEmpty(this.filteredResults());
    },
    noFilteredResultsHtml() {
      return '<span>No available vehicles found</span>';
    },
    confirmationTextHtml() {
      if (this.vehicleId === 'not-assigned') {
        return '<span>This device will not be permanently assigned to a vehicle. You may update this later in the Settings page.</span>';
      }
      return `<span>This device will be permanently assigned to <b>Vehicle ${this.vehicleId}</b>. You may update this later in the Settings page.</span>`;
    },
  },
  watch: {
    'showPartialError'(showPartialError) {
      this.$emit('show-partial-error-change', Boolean(showPartialError));
    },
    'showFullError'(showFullError) {
      this.$emit('show-full-error-change', Boolean(showFullError));
    },
    '$store.getters.isOnline'(isOnline) {
      if (isOnline) {
        clearTimeout(_offlineTimeout);
        _offlineTimeout = undefined;
        this.clearLoginServerErrors();
        this.hasConnectionError = false;
        this.hasWaitedForConnectionError = false;
        return;
      }
      this.hasConnectionError = true;
      _offlineTimeout = setTimeout(() => {
        this.hasWaitedForConnectionError = true;
      }, 1000 * 60);
    },
    '$store.getters.serverErrorLoginInfo'(hasError) {
      if (!this.$store.getters.isOnline) {
        return;
      }
      const hasErrorFromScreenWhileDriving =
        this.$store.getters.serverErrorVehicleInfo;
      if (hasErrorFromScreenWhileDriving) {
        this.$store.commit('serverErrorVehicleInfo', false);
        this.hasServerError = true;
        this.hasWaitedForServerError = true;
        this.startLoginInfoInterval();
        return;
      }
      if (!hasError) {
        this.stopLoginInfoInterval();
        clearTimeout(_serverErrorTimeout);
        _serverErrorTimeout = undefined;
        this.hasServerError = false;
        this.hasWaitedForServerError = false;
        return;
      }
      this.startLoginInfoInterval();
      this.hasServerError = true;
      _serverErrorTimeout = setTimeout(() => {
        this.hasWaitedForServerError = true;
      }, 1000 * 60);
    },
  },
  beforeMount() {
    this.enforceMinimumVehicleLoading();
    this.refreshLoginData();
    this.$emit('show-partial-error-change', Boolean(this.showPartialError));
    this.$emit('show-full-error-change', Boolean(this.showFullError));
    this.$emit('hide-loading');
    track('permanent-vehicle-assignment-opened');
  },
  beforeDestroy() {
    this.stopLoginInfoInterval();
    this.$emit('show-partial-error-change', false);
    this.$emit('show-full-error-change', false);
  },
  methods: {
    filteredResults() {
      if (this.showConfirmationInfo) {
        return [];
      }

      let results = [];
      const vehicleIds = sanitizeVehicleIds(
        this.$store.getters.currentVehicles,
      );
      vehicleIds.forEach((vehicleId) => {
        // To account for Transitime errors we have seen
        if (!vehicleId || vehicleId === 'null') {
          return;
        }

        const shouldFilterResult =
          this.vehicleFilterText &&
          vehicleId
            .toLowerCase()
            .indexOf(this.vehicleFilterText.toLowerCase()) === -1;
        if (shouldFilterResult) {
          return;
        }

        results.push({
          key: vehicleId,
          value: vehicleId,
          displayName: vehicleId,
          iconName: 'directions_bus',
          customClass: '',
        });
      });
      // Sort by how early the match is in the vehicle ID
      results = _.sortBy(results, (result) =>
        result.key.indexOf(this.vehicleFilterText),
      );
      if (!_.isEmpty(results)) {
        results.unshift({
          key: 'not-assigned',
          value: 'not-assigned',
          displayName: 'Not assigned',
          iconName: 'minus-sign',
          customClass: '',
        });
      }
      return results;
    },
    refreshLoginData() {
      if (!this.$store.getters.isOnline) {
        return;
      }
      this.$store.dispatch('getVehicles');
    },
    startLoginInfoInterval() {
      this.refreshLoginData();
      clearInterval(_loginInfoInterval);
      _loginInfoInterval = setInterval(this.refreshLoginData, 1000 * 10);
    },
    stopLoginInfoInterval() {
      clearInterval(_loginInfoInterval);
    },
    selectResult(vehicleId) {
      // To prevent accidental double tapping per OA-367
      if (this.temporarilyPreventSelections) {
        return;
      }
      this.vehicleId = vehicleId;
      track('permanent-vehicle-assignment-vehicle-tapped', { vehicleId });
    },
    enforceMinimumVehicleLoading() {
      this.forceVehicleLoader = true;
      this.temporarilyPreventSelections = true;
      setTimeout(() => {
        this.forceVehicleLoader = false;
      }, 250);
      setTimeout(() => {
        this.temporarilyPreventSelections = false;
      }, 1000);
    },
    onConfirmAssignment() {
      const vehicleId = this.vehicleId === 'not-assigned' ? '' : this.vehicleId;
      this.$store.commit('permanentlyAssignedVehicleId', vehicleId);
      track('permanent-vehicle-assignment-confirm-tapped', { vehicleId });

      // Zero-touch sign in flow
      if (
        this.$store.getters.isZeroTouchLoginEnabled &&
        vehicleId !== '' &&
        this.previousRouteName !== 'settings'
      ) {
        this.$router.push({ name: 'operator-login' });
      } else {
        this.$router.back();
      }
    },
    clearLoginServerErrors() {
      this.$store.commit('serverErrorVehicles', false);
      this.$store.commit('serverErrorBlocks', false);
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_variables.styl"
@require "../styl/_colors.styl"
@require "../styl/_images.styl"
@require "../styl/_svg-icon-adjustments.styl"

#operator-login {

    #selections-wrap {
        position absolute
        left 0
        bottom 0
        width 100%
        height 100%
        background-color $bunker
        overflow hidden

        display flex
        flex-direction column

        .selections {
            width 100%
            background-color $outer-space2

            .selection-wrap {
                position relative
                width 86%
                max-width $main-content-max-width
                height 4.8rem
                margin 0 auto 1.6rem
                border-radius 0.4rem
                background-color $white-trnsp-016

                &:first-child {
                    margin-top 3rem
                }
                &:last-child {
                    margin-bottom 3rem
                }

                .icon-wrap {
                    padding 0 1.2rem

                    > i {
                        height 4.8rem
                        line-height 4.8rem
                        color $white
                        font-size 2rem
                    }
                }
                #input-operator,
                #input-vehicle,
                #input-route {
                    position absolute
                    top 0
                    left 0
                    width calc(100% - 1rem)
                    height 4.8rem
                    line-height 4.8rem
                    padding 0
                    background-color transparent
                    border none
                    outline none
                    color $white
                    text-indent 4rem
                    font-size 2rem

                    &::placeholder {
                        color $seashell
                        opacity 1
                    }
                    &[placeholder] {
                        text-overflow ellipsis
                    }
                }

                > .label {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    font-size 2rem
                    color $white
                    text-indent 4rem
                    height 4.8rem
                    line-height 4.8rem

                    > .value {
                        width calc( 100% - 4.5rem )
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                    }

                    .edit-button {
                        position absolute
                        right 0
                        top 0
                        width 4.8rem
                        height 4.8rem
                        padding-top 1.2rem
                        cursor pointer
                        color $white-trnsp-033
                        transition color 0.2s ease

                        > i {
                            position absolute
                            right 1.2rem
                        }

                        &:hover {
                            color $white
                        }
                    }
                }

                &.active {
                    background-color $white-trnsp-016
                    .icon-wrap {
                        > i {
                            color $white
                        }
                    }
                    > .label {
                        color $white
                    }
                }

                #vehicleFilterText {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                    visibility hidden
                }
            }
        }

        #filtered-results {
            width 86%
            max-width $main-content-max-width

            margin 0 auto
            background-color transparent
            overflow-x hidden
            overflow-y auto

            &.selection-focus-vehicle {
                min-height calc(100% - 10.8rem)
                &.operator-login-enabled {
                    min-height calc(100% - 17.2rem)
                }
            }

            .no-result-message {
                padding-top 3.6rem
                padding-left 1.8rem
                padding-right 1.8rem
                color $silver-sand2
                font-size 2rem
                font-weight 300
                > span {
                    font-weight 500
                }
            }

            .loading-spinner {
                z-index 1
                position absolute
                top 0
                left 0
                width 100%
                height 100%
                text-align center
                opacity 1

                display flex
                flex-direction column
                justify-content center
            }

            .result {
                position relative
                height 9rem
                line-height 9rem
                color $seashell
                border-bottom 1px solid $mineshaft6
                font-size 2rem

                > .icon-wrap {
                    i {
                        position absolute
                        top 0
                        left 0
                        height 9rem
                        line-height 9rem
                        font-size 2rem
                        color $white
                        margin-left 1.2rem
                    }
                }

                > .result-value {
                    position absolute
                    top 0
                    left 4rem
                    width calc( 100% - 40px )
                    overflow hidden
                    white-space nowrap
                    text-overflow ellipsis
                }

                &.default-option {
                    > .icon-wrap i {
                        opacity 0.5
                    }
                }
            }
        }
    }
    .blur {
        filter blur(2px)
    }

    #confirmation-info {
        position relative
        width 86%
        max-width $main-content-max-width
        margin 0 auto
        height 100%
        color $seashell

        display flex
        flex-direction column
        justify-content flex-start

        .confirmation-title {
            margin 32px 0
            font-size 2.4rem
            font-weight 400
            text-align center
        }

        .confirmation-subtitle {
            font-size 1.8rem
            margin-bottom 1.2rem
        }

        .assignment-options {
            width 100%
            margin 0 auto

            display flex
            flex-direction column
            justify-content center

            .option {
                display flex
                flex-direction row
                justify-content space-between

                position relative
                padding 1.7rem 1.2rem

                font-size 2rem
                font-weight 300
                color $white-trnsp-087
                background-color $outer-space3
                border-radius 0.4rem
                cursor pointer

                &.selectable {
                    padding-left 6.4rem
                    opacity 0.7
                    &.selected {
                        opacity 1
                    }
                }

                &.assigned {
                    margin-bottom 1.2rem
                }

                &.trip-format-miami-style {
                    .option-info.left {
                        display none
                    }
                    .option-seperator {
                        display none
                    }
                    .option-info.right {
                        padding-left 2rem
                    }
                }

                .option-info {
                    line-height 2.667rem
                    > div {
                        overflow hidden
                        white-space nowrap
                        text-overflow ellipsis
                    }
                    &.selection-state {
                        visibility hidden
                        position absolute
                        top 0
                        left 0
                        width 5.2rem
                        height 100%
                        border-top-left-radius 0.4rem
                        border-bottom-left-radius 0.4rem
                        background-color $mako

                        display flex
                        flex-direction column
                        justify-content center

                        > .circle {
                            position relative
                            width 1.8rem
                            height 1.8rem
                            margin-left 1.7rem
                            border-radius 100%
                            border 2px solid $iron

                            > .filling {
                                opacity 0
                                position absolute
                                top 0.2rem
                                left 0.2rem
                                width 1rem
                                height 1rem
                                background-color $color-primary-blue
                                border-radius 100%
                                transition opacity 0.2s ease-in-out
                            }
                        }
                    }
                    .option-label {
                        font-weight 500
                        color $havelock-blue
                    }
                    > .label {
                        margin-bottom 0.8rem

                        &.route {
                            font-weight 500
                        }
                    }
                    &.right {
                        flex-grow 0.667
                        overflow hidden
                    }
                }

                &.selectable {
                    .option-info.selection-state {
                        visibility visible
                    }
                }

                &.selected {
                    .option-info.selection-state {
                        > .circle {
                            border-color $color-primary-blue
                            > .filling {
                                opacity 1
                            }
                        }
                    }
                }
            }

            .option-seperator {
                height 13.6rem
                width 0.1rem
                margin 0 1.6rem
                background-color $bombay
                &.has-multiple-options {
                    height 10.2rem
                }
                &.has-operator-id {
                    height 16.2rem
                }
                &.has-multiple-options.has-operator-id {
                    height 13.6rem
                }
            }
        }

        #next-button {
            position absolute
            bottom 3.8rem
            width 100%
            height 5.5rem
            line-height 5.5rem
            background-color $color-primary-blue
            border-radius 0.4rem
            color $seashell
            font-size 2rem
            font-weight 500
            text-align center
            transition opacity 0.2s ease-in-out
            cursor pointer
        }
    }

    .main-content {
        .actions-wrap {
            .sign-in-button {
                position relative
                padding 1.5rem 0
                font-size 1.6rem
                font-weight 500
                color $white
                background-color $button-color
                border-radius 0.4rem
                cursor pointer

                > input {
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                }

                &.vehicle-is-moving {
                    color $oslo-gray
                    background-color $outer-space4
                }
            }

            .select-wrap {
                position relative
                padding 1rem 0
                color $text-default
                font-weight 300
                border 1px solid $text-default
                border-radius .4rem
                margin-bottom 24px

                .title > span {
                    font-weight 500
                }

                > select {
                    opacity 0
                    position absolute
                    top 0
                    left 0
                    width 100%
                    height 100%
                }
            }

            .headsign,
            .trip,
            .route {
                opacity 0
                transition opacity 0.2s ease
                cursor disabled
                visibility hidden

                &.is-ready {
                    opacity 1
                    cursor pointer
                    visibility visible
                }
            }

            .set {
                display flex
                justify-content center
                padding 10px 0
                color $cod-gray
                background-color $color-primary-blue
                border-radius 0.4rem
                cursor disabled
                opacity 0
                transition opacity 0.2s ease

                &.is-ready {
                    opacity 1
                    cursor pointer
                }
            }
        }

        .hidden-selector {
            visibility hidden
        }

        .is-visible {
            visibility visible
        }
    }
    .blur {
        filter blur(2px)
    }
}


@media screen and (max-width 767px) {
    #operator-login #selections-wrap {
        .selections .selection-wrap {
            max-width $main-content-max-width-smaller-screens
        }
        #filtered-results {
            max-width $main-content-max-width-smaller-screens
        }
    }
}


@media screen and (max-width 600px) {
    #operator-login #selections-wrap {
        height calc(100% - 6rem)
        &.not-showing-login-selections,
        &.showing-confirmation-info {
            height 100%
        }
        .selections .selection-wrap {
            max-width none
            width calc(100% - 4rem)
        }
        #filtered-results {
            max-width none
            width calc(100% - 4rem)
        }
    }
}

@media screen and (max-height 567px) {
    #operator-login {
        position fixed

        #selections-wrap {
            display block
            position static
            width 100%
            height 100%
            overflow-x hidden
            overflow-y scroll

            .selections {
                padding 1.6rem 0
                .selection-wrap {
                    &:first-child {
                        margin-top 0
                    }
                    &:last-child {
                        margin-bottom 0
                    }
                }
            }

            #next-button {
                position static
                margin 1.6rem 0 10rem
            }
        }
    }
}
</style>
