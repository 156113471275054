// For development environment
import './index-development.html';

// For progressive web app (PWA)
// https://developers.google.com/web/fundamentals/web-app-manifest/
import './manifest.webmanifest';
import './service-worker';

import './images/favicon-16x16.png';
import './images/favicon-32x32.png';
import './images/favicon-96x96.png';

import './images/app-icons/icon-32x32.png';
import './images/app-icons/icon-36x36.png';
import './images/app-icons/icon-48x48.png';
import './images/app-icons/icon-60x60.png';
import './images/app-icons/icon-72x72.png';
import './images/app-icons/icon-76x76.png';
import './images/app-icons/icon-96x96.png';
import './images/app-icons/icon-120x120.png';
import './images/app-icons/icon-128x128.png';
import './images/app-icons/icon-144x144.png';
import './images/app-icons/icon-152x152.png';
import './images/app-icons/icon-167x167.png';
import './images/app-icons/icon-180x180.png';
import './images/app-icons/icon-192x192.png';
import './images/app-icons/icon-196x196.png';
import './images/app-icons/icon-256x256.png';
import './images/app-icons/icon-384x384.png';
import './images/app-icons/icon-512x512.png';

import 'normalize.css';
import 'animate.css';
import './styl/main.styl';

import Vue from 'vue';
import VueToggleButton from 'vue-js-toggle-button';
import VueRouter from 'vue-router';
import { VueSimpleAlert } from 'vue-simple-alert';
import Vue2TouchEvents from 'vue2-touch-events';

import * as Segment from './analytics/segment';
import App from './components/App.vue';
import FullErrorLogo from './components/common/FullErrorLogo.vue';
import IconButton from './components/common/IconButton.vue';
import InlineSvg from './components/common/InlineSvg';
import PartialError from './components/common/PartialError.vue';
import * as ErrorReporter from './error-reporter';
import Optimizely from './optimizely';
import { buildRouter } from './router';
import ServiceWorkerInterface from './service-worker-interface';
import store from './store';

ErrorReporter.initialize();

Promise.all([
  Optimizely.initialize({ uuid: window.swiftlyUUID }),
  Segment.init(store.getters),
])
  .then(initVue)
  .catch((error) =>
    ErrorReporter.capture({
      level: 'error',
      messageOrException: error,
    }),
  );

function initVue() {
  Vue.component('IconButton', IconButton);
  Vue.component('InlineSvg', InlineSvg);
  Vue.component('PartialError', PartialError);
  Vue.component('FullErrorLogo', FullErrorLogo);

  Vue.use(VueRouter);
  Vue.use(VueSimpleAlert);
  Vue.use(VueToggleButton);

  //Used for touch gestures
  Vue.use(Vue2TouchEvents);

  // On Mobile Chrome, we encountered the issue described in the link below
  // Link: https://stackoverflow.com/questions/50617865/vue-v-model-input-change-mobile-chrome-not-work
  // With this approach, however, binding.expression can report other binded expressions when used multiple times.
  // Therefore, create separate directives as needed.
  // TODO(haysmike) I feel like this would be more discoverable: https://github.com/vuejs/vue/pull/9814#issuecomment-763266927
  const directivesToAdd = [
    '$model-email',
    '$model-password',
    '$model-operator-filter-text',
    '$model-vehicle-filter-text',
    '$model-route-filter-text',
  ];
  for (const directiveName of directivesToAdd) {
    Vue.directive(directiveName, {
      bind: function (element, binding, vnode) {
        element.oninput = () =>
          (vnode.context[binding.expression] = element.value);
      },
    });
  }

  // If a previous Vue instance exists, destroy it. This can happen, for example,
  // when a native application is downloading a new version of the app's JavaScript
  if (
    typeof window.vc === 'object' &&
    typeof window.vc.$destroy === 'function'
  ) {
    window.vc.$destroy();
  }

  // Old versions require `NativeAPI.initialize()` to be called or other native functions will throw.
  if (window.NativeAPI != null && window.NativeAPI.initialize != null) {
    window.NativeAPI.initialize(ErrorReporter);
  }

  window.vc = new Vue({
    store,
    el: '#main',
    router: buildRouter(store),
    render: (h) => h(App),
  });

  window.addEventListener('load', (event) => {
    ServiceWorkerInterface.init();
  });
}
