const ONBOARD_APP_HOST_PRODUCTION = 'https://onboard.goswift.ly';

const ONBOARD_APP_HOST =
  window.localStorage.getItem('nativeWebViewHost') ||
  process.env.ONBOARD_APP_HOST ||
  ONBOARD_APP_HOST_PRODUCTION;

const sendRequest = (url, opts) =>
  new Promise((resolve, reject) =>
    cordova.plugin.http.sendRequest(url, opts, resolve, (res) =>
      reject(new Error(res.error)),
    ),
  );

const nativeRequest = async (fetchConfig) => {
  const { url, params, ...config } = fetchConfig;
  // Our swiftly-uuid cookie seems to get out of sync occasionally, so use
  // local storage instead.
  const uuid = localStorage.getItem('swiftlyUUID');
  // In a Cordova environment, the protocol is "file://"
  // Therefore, if this a relative URL, prepend the Onboard App's host
  const fullUrl = url[0] === '/' ? `${ONBOARD_APP_HOST}${url}` : url;
  const serverResponse = await sendRequest(fullUrl, {
    params: uuid != null ? { uuid, ...params } : params,
    ...config,
  });
  serverResponse.data = JSON.parse(serverResponse.data);
  return serverResponse;
};

export default nativeRequest;
