<template>
  <button
    class="icon-button"
    :class="{
      'icon-button--active': isActive,
      'icon-button--disabled': isDisabled,
      ...customClasses,
    }"
    :disabled="isDisabled && onDisabledClick == null"
    @click="onClick"
  >
    <inline-svg :name="iconName" :classes="iconClass" />
    <div
      v-if="text"
      class="icon-button__text"
      :class="{ ...customTextClasses }"
    >
      {{ text }}
    </div>
  </button>
</template>

<script>
export default {
  components: {},
  props: {
    isActive: { type: Boolean },
    isDisabled: { type: Boolean },
    onDisabledClick: { type: Function },
    iconName: { type: String, required: true },
    iconClass: { type: String },
    text: { type: String },
    customClasses: { type: Object },
    customTextClasses: { type: Object },
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      if (this.isDisabled && this.onDisabledClick != null) {
        this.onDisabledClick();
      }
      if (!this.isDisabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../../styl/_colors.styl"
@require "../../styl/_svg-icon-adjustments.styl"

.icon-button {
  text-align center
  background-color transparent
  border none
  cursor pointer

  &--active {
    background-color $background-color-3
  }

  &--disabled {
    opacity 0.38
    cursor default
  }

  &__text {
    margin-top 4px
  }
}
</style>
