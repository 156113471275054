<template>
  <div class="full-error-ghost animated fadeIn">
    <div v-if="showGhostImage" class="bg-image-ghost"></div>
    <div class="text-line one" v-html="message.primary"></div>
    <div class="text-line two" v-html="message.secondary"></div>
    <div class="logout" @click="onFullErrorLogoutTap">LOG OUT</div>
  </div>
</template>

<script>
import * as ErrorReporter from '../../error-reporter';
import { calculateUnassignmentReason } from '../../utils/calculate-unassignment-reason';

const messagesByType = {
  connection: {
    primary: 'Lost Internet Connection',
    secondary: 'Reconnecting...',
  },
  server: {
    primary: 'Lost Server Connection',
    secondary: 'Reconnecting...',
  },
  completed: {
    primary: 'All done!',
    secondary: "You've completed your assignment.",
  },
  canceled: {
    primary: 'All done!',
    secondary: 'The remainder of your assignment has been canceled.',
  },
  default: {
    primary: 'Vehicle unassigned',
    secondary:
      'Your vehicle has been unassigned from service. <br>If this is unexpected, please ask for help.',
  },
};

export default {
  props: {
    showFullError: {
      type: String,
      required: true,
    },
  },
  computed: {
    showGhostImage() {
      return (
        this.showFullError === 'connection' || this.showFullError === 'server'
      );
    },
    message() {
      return this.showFullError === 'assignment'
        ? this.unassignMessage
        : messagesByType[this.showFullError];
    },
    unassignMessage() {
      try {
        const unassignReason = calculateUnassignmentReason(
          this.$store.getters.blockAssignmentInfo,
        );
        return messagesByType[unassignReason] ?? messagesByType.default;
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: error,
        });
      }
      return messagesByType.default;
    },
  },
  methods: {
    onFullErrorLogoutTap() {
      const reason = `User tapped the logout button on an error overlay (${this.showFullError})`;
      this.$store.dispatch('logoutVehicle', { vc: this, reason });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require "../../styl/_colors.styl"
@require "../../styl/_images.styl"

.full-error-ghost {
    position absolute
    width 100%
    height 100%
    padding 0 10%
    justify-content center !important
    color $white
    text-align center
    background-color $cod-gray
    display flex
    flex-direction column
    z-index 2

    .bg-image-ghost {
        width 20rem
        height 20rem
        margin 0 auto 2rem
        background-repeat no-repeat
        background-position 50% 0
        background-size contain
    }

    .text-line {
        &.one {
            font-size 5rem
            font-weight 500
        }
        &.two {
            margin-top 2.5rem
            margin-bottom 10rem
            font-size 3rem
            font-weight 300
            opacity 0.8
        }
    }

    .logout {
        position absolute
        bottom 4.8rem
        width 80%
        height 8rem
        line-height 8rem
        color $white-trnsp-075
        font-size 3rem
        font-weight 500
        background-color $white-trnsp-010
        border 0.15rem solid $cerulean
        border-radius 0.4rem
    }
}
</style>
