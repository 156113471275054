import VueRouter from 'vue-router';

import * as Segment from './analytics/segment';
import AgencySelection from './components/AgencySelection.vue';
import MySwiftly from './components/MySwiftly.vue';
import OperatorLogin from './components/OperatorLogin.vue';
import PermanentVehicleAssignment from './components/PermanentVehicleAssignment.vue';
import ScreenWhileDriving from './components/ScreenWhileDriving.vue';
import Settings from './components/Settings.vue';

const RouteOptions = {
  'agency-selection': {
    showTopBar: false,
  },
  'operator-login': {
    showTopBar: true,
  },
  'permanent-vehicle-assignment': {
    showTopBar: true,
  },
  'vehicle': {
    showTopBar: false,
  },
  'my-swiftly': {
    showTopBar: true,
  },
  'settings': {
    showTopBar: true,
  },
};

const shouldGoToAgencySelection = ({ isRegisteredDevice, currentAgencyKey }) =>
  !isRegisteredDevice || currentAgencyKey === '';

export const buildRouter = (store) => {
  const router = new VueRouter({
    routes: [
      {
        name: 'agency-selection',
        path: '/',
        component: AgencySelection,
        beforeEnter: (to, from, next) => {
          if (!shouldGoToAgencySelection(store.getters)) {
            next({ name: 'operator-login' });
          } else {
            next();
          }
        },
      },
      {
        name: 'operator-login',
        path: '/operator-login',
        component: OperatorLogin,
      },
      {
        name: 'permanent-vehicle-assignment',
        path: '/permanent-vehicle-assignment',
        component: PermanentVehicleAssignment,
      },
      {
        name: 'vehicle',
        path: '/vehicle/:vehicleId',
        component: ScreenWhileDriving,
        beforeEnter: (to, from, next) => {
          // We could set this during operator login, but using the URL here also enables reloading
          store.commit('currentVehicleId', to.params.vehicleId);
          Segment.identifyUser();
          next();
        },
      },
      {
        name: 'my-swiftly',
        path: '/my-swiftly',
        component: MySwiftly,
      },
      {
        name: 'settings',
        path: '/settings',
        component: Settings,
      },
      {
        path: '*',
        redirect: { name: 'agency-selection' },
      },
    ],
    // Need to use hash mode in Cordova due to file:// URLs.
    // Also allows us to 404 on unrecognized routes and still support reloading in browser.
    mode: 'hash',
  });
  router.beforeEach((to, from, next) => {
    if (
      to.name !== 'agency-selection' &&
      shouldGoToAgencySelection(store.getters)
    ) {
      next({ name: 'agency-selection' });
    } else {
      store.commit('showTopBar', RouteOptions[to.name].showTopBar);
      next();
    }
  });
  return router;
};
