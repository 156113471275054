import * as ErrorReporter from '../error-reporter';

import { convertTransitimeTimeToHumanFriendly } from './transitime-time';

export const buildStopsList = ({
  limitToPriorityStops,
  currentTripStopPathsByStopId,
  fullSchedule,
}) => {
  const stops = fullSchedule.reduce((acc, stop, index) => {
    const stopScheduleInfo = { ...stop };
    const stopPathInfo = currentTripStopPathsByStopId[stopScheduleInfo.stopId];

    const isFirstStop = index === 0;
    const isLastStop = index === fullSchedule.length - 1;
    const isExplicitWaitStop = stopPathInfo?.waitStop === true;
    const hasServiceAdjustments = stopScheduleInfo.adjustments != null;
    const isWaitStop = isFirstStop || isLastStop || isExplicitWaitStop;
    const isPriorityStop = isWaitStop || hasServiceAdjustments;

    if (!limitToPriorityStops || isPriorityStop) {
      stopScheduleInfo.isWaitStopStyle = isWaitStop;
      const stopLocation = stopPathInfo?.locations.at(-1);
      if (stopLocation != null) {
        stopScheduleInfo.lat = stopLocation.lat;
        stopScheduleInfo.lon = stopLocation.lon;
      }
      stopScheduleInfo.isFirstStop = isFirstStop;
      stopScheduleInfo.isLastStop = isLastStop;
      stopScheduleInfo.isPriorityStop = isPriorityStop;
      acc.push(stopScheduleInfo);
    }
    return acc;
  }, []);

  if (stops.length !== 0) {
    const firstStop = stops[0];
    try {
      firstStop.departureTimeHumanReadable =
        convertTransitimeTimeToHumanFriendly(firstStop.departureTime);
    } catch (error) {
      ErrorReporter.capture({
        level: 'error',
        messageOrException:
          'Failed to calculate human-friendly departure time for first stop',
        extraContext: { error, firstStop },
      });
    }
  }
  return stops;
};
