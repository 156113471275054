import { createInstance, enums } from '@optimizely/optimizely-sdk';

import * as ErrorReporter from '../error-reporter';
import fetch from '../fetch';

let optimizelyClient = null;
let userAttributes = null;

const wrappedOptimizely = {
  initialize: async (user) => {
    initializeUserAttributes(user);
    await initializeOptimizelyClient();
  },
  isFeatureEnabled: (flagName, additionalAttributes = {}) =>
    optimizelyClient
      ? optimizelyClient.isFeatureEnabled(flagName, userAttributes.uuid, {
          ...additionalAttributes,
        })
      : false,
  getEnabledFeatures: (additionalAttributes = {}) =>
    optimizelyClient
      ? optimizelyClient.getEnabledFeatures(userAttributes.uuid, {
          ...additionalAttributes,
        })
      : [],
};

const initializeOptimizelyClient = async () => {
  let datafile = window.optimizelyDatafile;

  try {
    const isNativeEnvironment = typeof window.cordova === 'object';
    if (isNativeEnvironment) {
      if (process.env.OPTIMIZELY_SDK_KEY == null) {
        throw new Error('Missing Optimizely SDK Key');
      }

      const { data } = await fetch({
        url: `https://cdn.optimizely.com/datafiles/${process.env.OPTIMIZELY_SDK_KEY}.json`,
      });
      datafile = data;
    }

    if (datafile == null) {
      throw new Error('Missing datafile');
    }

    optimizelyClient = createInstance({
      datafile,
      logLevel: enums.LOG_LEVEL.ERROR,
    });
  } catch (error) {
    ErrorReporter.capture({
      level: 'error',
      messageOrException: 'Unable to create optimizely instance',
      extraContext: { error, datafile },
    });
  }
};

const initializeUserAttributes = (user) => {
  try {
    userAttributes = { uuid: user.uuid };
  } catch (error) {
    ErrorReporter.capture({
      level: 'error',
      messageOrException: 'Unable to build user attributes for optimizely',
      extraContext: { error, user },
    });
  }
};

export default wrappedOptimizely;

if (window.Cypress != null) {
  window.Optimizely = wrappedOptimizely;
}
