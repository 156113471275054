const buildDisplayName = ({ firstName, lastName, displayName }) => {
  if (displayName != null) {
    return displayName;
  }
  if (firstName == null && lastName == null) {
    return null;
  }
  const firstNameString = firstName != null ? firstName : '';
  const lastNameString = lastName != null ? lastName : '';
  const name = `${firstNameString} ${lastNameString}`;
  return name.trim();
};

const buildDisplayNameWithKey = ({ key, ...operator }) => {
  const name = buildDisplayName(operator);
  return name != null ? `${name} (${key})` : key;
};

const buildDisplayNameWithOperatorTag = (operator) => {
  const displayName = buildDisplayNameWithKey(operator);
  return `Operator ${displayName}`;
};

const buildDisplayNameOrKeyWithOperatorTag = ({ key, ...operator }) => {
  const name = buildDisplayName(operator);
  return name != null ? `${name} (${key})` : `Operator ${key}`;
};

// We want to ignore `displayName` with this format even when agencies specify it.
const buildIdFullName = ({ key, displayName, ...operator }) => {
  const name = buildDisplayName(operator);
  return name != null ? `${key} - ${name}` : key;
};

const applyFormat = {
  DISPLAY_NAME: buildDisplayNameWithKey,
  DISPLAY_NAME_WITH_OPERATOR_TAG: buildDisplayNameWithOperatorTag,
  DISPLAY_NAME_OR_KEY_WITH_OPERATOR_TAG: buildDisplayNameOrKeyWithOperatorTag,
  ID: (operator) => operator.key,
  ID_FULL_NAME: buildIdFullName,
};

export const APP_OPERATOR_FORMAT = {
  DISPLAY_NAME: 'DISPLAY_NAME',
  DISPLAY_NAME_OR_KEY_WITH_OPERATOR_TAG:
    'DISPLAY_NAME_OR_KEY_WITH_OPERATOR_TAG',
  DISPLAY_NAME_WITH_OPERATOR_TAG: 'DISPLAY_NAME_WITH_OPERATOR_TAG',
};

// Agencies can choose one of these operator formats in their config
export const TRANSITIME_OPERATOR_FORMAT = {
  ID: 'ID', // default
  ID_FULL_NAME: 'ID_FULL_NAME',
};

export const formatOperator = (operator, format) => {
  // This is for "Anonymous operator"
  if (operator.key == null) {
    return format in TRANSITIME_OPERATOR_FORMAT ? null : operator.displayName;
  }
  if (format in applyFormat) {
    return applyFormat[format](operator);
  }
  throw new Error(
    `Incorrect format parameter: ${format} passed to formatOperator`,
  );
};
