import * as Segment from '../../analytics/segment';
import * as ErrorReporter from '../../error-reporter';
import fetch from '../../fetch';
import * as twilioWrapper from '../../twilio';

const resettableState = () => ({
  messages: [],
  lastReadMessageDate: new Date(0).toISOString(),
  twilioConnectionState: null,
  isRestarting: false,
  expectingDisconnect: false,
});

// Note: Vue can't handle JS getters, so we can't put Twilio objects directly into the store
const buildMessage = (message, participant) => ({
  id: message.sid,
  body: message.body,
  conversation: {
    attributes: message.conversation.attributes,
  },
  participant: {
    attributes: participant.attributes,
  },
  dateCreated: message.dateCreated.toISOString(),
});

let twilioRetryTimeout = null;

export const messages = {
  namespaced: true,
  state: resettableState(),
  mutations: {
    addMessage(state, message) {
      state.messages.push(message);
    },
    setLastReadMessageDate(state, date) {
      state.lastReadMessageDate = date;
    },
    setRestarting(state, isRestarting) {
      state.isRestarting = isRestarting;
    },
    setTwilioConnectionState(state, connectionState) {
      state.twilioConnectionState = connectionState;
    },
    expectingDisconnect(state, expectingDisconnect) {
      state.expectingDisconnect = expectingDisconnect;
    },
    resetState(state) {
      Object.assign(state, resettableState());
    },
  },
  actions: {
    async start({ commit, dispatch, getters, state, rootGetters }) {
      const {
        currentAgencyKey: agencyId,
        currentVehicleId: vehicleId,
        currentOperator,
      } = rootGetters;
      Segment.track('messages-start', {
        agencyId,
        operatorId: currentOperator?.key ?? null,
      });
      if (twilioWrapper.isInitialized()) {
        return;
      }
      commit('expectingDisconnect', false);
      const identity = `${agencyId}:${vehicleId}`;
      const conversationUniqueName = identity;
      await twilioWrapper.initialize({
        authCallback: async () => {
          try {
            const res = await fetch({
              method: 'POST',
              url: '/twilio/conversations/auth',
              params: { agency: agencyId },
              data: { identity },
            });
            return res.data.jwt;
          } catch (error) {
            ErrorReporter.capture({
              level: 'error',
              messageOrException: 'Failed to fetch Twilio JWT',
              extraContext: { error, agencyId, vehicleId },
            });
            throw error;
          }
        },
        initializedCallback: async () => {
          Segment.track('twilio-initialized', {
            agencyId,
            operatorId: currentOperator?.key ?? null,
          });
          try {
            const conversation = await twilioWrapper.findOrCreateConversation(
              conversationUniqueName,
              { vehicleId },
            );
            await twilioWrapper.ensureParticipants(conversation, [agencyId]);
            if (getters.isRestarting) {
              commit('setRestarting', false);
              Segment.track('restart-messages-complete', {
                agencyId,
                operatorId:
                  currentOperator != null ? currentOperator.key : null,
                vehicleId,
              });
            }
          } catch (error) {
            ErrorReporter.capture({
              level: 'warning',
              messageOrException: 'Failed to set up Twilio conversation',
              extraContext: { error, agencyId, vehicleId },
            });
            throw error;
          }
        },
        conversationJoinedCallback: async (conversation) => {
          Segment.track('twilio-conversation-joined', {
            agencyId,
            operatorId: currentOperator?.key ?? null,
          });
          try {
            await twilioWrapper.updateParticipantAttributes(conversation, {
              role: 'operator',
            });
          } catch (error) {
            ErrorReporter.capture({
              level: 'error',
              messageOrException:
                'Failed to update Twilio participant attributes',
              extraContext: { error, agencyId, vehicleId },
            });
          }
        },
        messageAddedCallback: async (message) => {
          try {
            const participant = await message.conversation.getParticipantBySid(
              message.participantSid,
            );
            if (participant.attributes.role === 'operator') {
              commit('addMessage', buildMessage(message, participant));
            } else {
              const dateReceived = new Date();
              const expiryTimestamp = message.attributes.expiryTimestamp;
              const wasDeliveredToOperator =
                expiryTimestamp == null ||
                dateReceived < new Date(expiryTimestamp);
              if (wasDeliveredToOperator) {
                commit('addMessage', buildMessage(message, participant));
                try {
                  await message.updateAttributes({
                    ...message.attributes,
                    wasDeliveredToOperator,
                  });
                } catch (error) {
                  ErrorReporter.capture({
                    level: 'error',
                    messageOrException:
                      'Failed to mark Twilio message delivered',
                    extraContext: { error, agencyId, vehicleId },
                  });
                }
              }
              Segment.track(
                wasDeliveredToOperator
                  ? 'message-received'
                  : 'message-too-late',
                {
                  agencyId,
                  operatorId: currentOperator?.key ?? null,
                  receivedTimestamp: dateReceived.toISOString(),
                  expiryTimestamp,
                  delayMs: dateReceived - message.dateCreated,
                },
              );
            }
          } catch (error) {
            ErrorReporter.capture({
              level: 'error',
              messageOrException: 'Failed to add Twilio message',
              extraContext: {
                error,
                messageId: message.sid,
                agencyId,
                vehicleId,
              },
            });
          }
        },
        connectionStateChangeCallback: (connectionState) => {
          commit('setTwilioConnectionState', connectionState);
          Segment.track('twilio-connection-state-changed', {
            agencyId,
            operatorId: currentOperator?.key ?? null,
            connectionState,
          });
          if (
            connectionState === 'disconnected' &&
            !state.expectingDisconnect
          ) {
            dispatch('restart');
          }
        },
        errorCallback: async (event, error) => {
          Segment.track('twilio-error-callback', {
            agencyId,
            operatorId: currentOperator?.key ?? null,
            event,
            error: error?.message ?? null,
          });
          try {
            await twilioWrapper.terminate();
            twilioRetryTimeout = setTimeout(() => {
              dispatch('start');
            }, 5000);
          } catch (error) {
            // There is a small chance that we get a `tokenExpired` error and
            // `connectionError` event at the same time, in which case the
            // `terminate()` call could fail. It's async, so we can't really
            // rely on a null check (`twilioWrapper.isInitialized()`).
            ErrorReporter.capture({
              level: 'warning',
              messageOrException:
                'Failed to terminate Twilio client in error callback',
              extraContext: { error, agencyId, vehicleId },
            });
          }
        },
      });
    },
    async stop({ commit, rootGetters }) {
      commit('expectingDisconnect', true);
      const { currentAgencyKey: agencyId, currentOperator } = rootGetters;
      Segment.track('messages-stop', {
        agencyId,
        operatorId: currentOperator?.key ?? null,
      });
      clearTimeout(twilioRetryTimeout);
      if (twilioWrapper.isInitialized()) {
        const { currentAgencyKey: agencyId, currentVehicleId: vehicleId } =
          rootGetters;
        try {
          await twilioWrapper.terminate();
        } catch (error) {
          ErrorReporter.capture({
            level: 'error',
            messageOrException:
              'Failed to terminate Twilio client in `messages/stop` action',
            extraContext: { error, agencyId, vehicleId },
          });
        }
      }
    },
    async restart({ commit, dispatch, rootGetters }) {
      const { currentAgencyKey: agencyId, currentVehicleId: vehicleId } =
        rootGetters;
      try {
        commit('setRestarting', true);
        if (twilioWrapper.isInitialized()) {
          await dispatch('stop');
        }
        dispatch('start');
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException:
            'failed to stop before starting in restart action',
          extraContext: { error, agencyId, vehicleId },
        });
      }
    },
    async sendChatMessage({ dispatch, rootGetters }, body) {
      const { currentAgencyKey: agencyId, currentVehicleId: vehicleId } =
        rootGetters;
      const conversationUniqueName = `${agencyId}:${vehicleId}`;
      try {
        await twilioWrapper.sendMessage(conversationUniqueName, body);
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: 'Failed to send message',
          extraContext: { error, agencyId, vehicleId },
        });
      }
    },
    markLastMessageRead({ commit, state }) {
      const { messages } = state;
      if (messages.length === 0) {
        return;
      }
      const lastMessage = messages[messages.length - 1];
      commit('setLastReadMessageDate', lastMessage.dateCreated);
    },
  },
  getters: {
    hasUnreadMessages(state) {
      const { messages, lastReadMessageDate } = state;
      if (messages.length === 0) {
        return false;
      }
      const lastMessage = messages[messages.length - 1];
      return (
        lastMessage.participant.attributes.role !== 'operator' &&
        lastMessage.dateCreated > lastReadMessageDate
      );
    },
    isRestarting(state) {
      return state.isRestarting;
    },
  },
};

if (window.Cypress != null) {
  window.twilioWrapper = twilioWrapper;
}
