import axios from 'axios';

import nativeRequest from './utils/native-request';

// These are common to both axios and cordova-plugin-advanced-http
const SUPPORTED_OPTIONS = [
  'url',
  'method',
  'headers',
  'params',
  'data',
  'timeout',
  'responseType',
];

export default async (fetchConfig) => {
  const unsupportedOptions = Object.keys(fetchConfig).filter(
    (param) => !SUPPORTED_OPTIONS.includes(param),
  );
  if (unsupportedOptions.length > 0) {
    const unsupportedOptionsStr = unsupportedOptions.join(', ');
    throw new Error(
      `Unsupported option found in fetch config: ${unsupportedOptionsStr}`,
    );
  }
  return window.cordova != null
    ? nativeRequest(fetchConfig)
    : axios(fetchConfig);
};
