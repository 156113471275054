import _ from 'lodash';

// registration
export const uuid = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.uuid = payload;
};

export const registrationStatus = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.registrationStatus = payload;
};

export const isOnline = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.isOnline = payload;
};

export const serverErrorVehicles = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.serverErrorVehicles = payload;
};

export const serverErrorBlocks = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.serverErrorBlocks = payload;
};

export const serverErrorTripInfo = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.serverErrorTripInfo = payload;
};

export const serverErrorVehicleInfo = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.serverErrorVehicleInfo = payload;
};

export const vehicleAssignmentError = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.vehicleAssignmentError = payload;
};

// ui
export const showLoginOverlay = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.showLoginOverlay = payload;
};

export const showLoginSelections = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.showLoginSelections = payload;
};

export const showTopBar = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.showTopBar = payload;
};

export const sendGeolocationUpdates = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  localStorage.setItem('sendGeolocationUpdates', payload);
  state.sendGeolocationUpdates = payload;
};

export const headwayMode = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  localStorage.setItem('headwayMode', payload);
  state.headwayMode = payload;
};

export const developerMode = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  localStorage.setItem('developerMode', payload);
  state.developerMode = payload;
};

export const showRouteStops = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.showRouteStops = payload;
};

export const showTimepointsOnlyInOtpView = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  localStorage.setItem('showTimepointsOnlyInOtpView', payload);
  state.showTimepointsOnlyInOtpView = payload;
};

export const showTimepointsOnlyInMapView = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  localStorage.setItem('showTimepointsOnlyInMapView', payload);
  state.showTimepointsOnlyInMapView = payload;
};

export const enableServiceAdjustments = (state, payload) => {
  if (typeof payload !== 'boolean') {
    return;
  }
  state.enableServiceAdjustments = payload;
};

export const tripDisplayFormat = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.tripDisplayFormat = payload;
};

export const operatorAssignmentFormat = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.operatorAssignmentFormat = payload;
};

export const showOperatorLogin = (state, payload) => {
  if (typeof payload !== 'boolean' && payload != null) {
    return;
  }
  localStorage.setItem('showOperatorLogin', payload);
  state.showOperatorLogin = payload;
};

export const wakeLockStatus = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.wakeLockStatus = payload;
};

// agency
export const agencyKeys = (state, payload) => {
  if (!_.isArray(payload)) {
    return;
  }
  const stringifiedJson = JSON.stringify(payload);
  localStorage.setItem('agencyKeys', stringifiedJson);
  state.agencyKeys = stringifiedJson;
};

export const currentAgencyKey = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  localStorage.setItem('agencyKey', payload);
  state.currentAgencyKey = payload;
};

export const currentAgencyInfo = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentAgencyInfo = stringifiedJson;
};

export const agencyConfigs = (state, agencyConfigs) => {
  const agencyConfigsJson = JSON.stringify(agencyConfigs);
  localStorage.setItem('agencyConfigs', agencyConfigsJson);
  state.agencyConfigs = agencyConfigsJson;
};

export const currentOperatorIds = (state, payload) => {
  if (!_.isArray(payload)) {
    return;
  }
  const stringifiedJson = JSON.stringify(payload);
  state.currentOperatorIds = stringifiedJson;
};

export const currentAgencyTransitimeConfig = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentAgencyTransitimeConfig = stringifiedJson;
};

export const currentBlocks = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentBlocks = stringifiedJson;
};

export const currentRoutes = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentRoutes = stringifiedJson;
};

export const currentVehicles = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentVehicles = stringifiedJson;
};

export const serviceAdjustments = (state, payload) => {
  if (!_.isArray(payload)) {
    return;
  }
  const stringifiedJson = JSON.stringify(payload);
  state.serviceAdjustments = stringifiedJson;
};

export const currentOperator = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  localStorage.setItem('currentOperator', stringifiedJson);
  state.currentOperator = stringifiedJson;
};

export const currentVehicleId = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.currentVehicleId = payload;
};

export const permanentlyAssignedVehicleId = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  localStorage.setItem('permanentlyAssignedVehicleId', payload);
  state.permanentlyAssignedVehicleId = payload;
};

export const currentVehicleInfo = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentVehicleInfo = stringifiedJson;
};

export const lastValidVehicleInfo = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.lastValidVehicleInfo = stringifiedJson;
};

export const currentVehicleInfoTimestamp = (state, payload) => {
  if (!_.isFinite(payload)) {
    return;
  }
  state.currentVehicleInfoTimestamp = payload;
};

export const currentVehicleInfoAttemptTimestamp = (state, payload) => {
  if (!_.isFinite(payload)) {
    return;
  }
  state.currentVehicleInfoAttemptTimestamp = payload;
};

export const currentRouteKey = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.currentRouteKey = payload;
};

export const currentRouteInfo = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentRouteInfo = stringifiedJson;
};

export const currentTripInfo = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.currentTripInfo = stringifiedJson;
};

export const lastTripLastVehicleInfo = (state, lastTripLastVehicleInfo) => {
  state.lastTripLastVehicleInfo = JSON.stringify(lastTripLastVehicleInfo);
};

export const matchedTripPathResponse = (state, payload) => {
  const stringifiedJson = JSON.stringify(payload);
  state.matchedTripPathResponse = stringifiedJson;
};

export const currentTripCoordinates = (state, payload) => {
  state.currentTripCoordinates = JSON.stringify(payload);
};

export const currentBlockId = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.currentBlockId = payload;
};

export const geolocationEnabledState = (state, payload) => {
  if (typeof payload !== 'string') {
    return;
  }
  state.geolocationEnabledState = payload;
};

export const currentPosition = (state, position) => {
  // Properties on DOM objects like `GeolocationCoordinates` are all inherited.
  // They won't be stringified by default, so rebuild explicitly as a POJO.
  const {
    timestamp,
    coords: { latitude, longitude, heading, speed, accuracy },
  } = position;
  state.currentPosition = JSON.stringify({
    timestamp,
    coords: { latitude, longitude, heading, speed, accuracy },
  });
};

export const memoryInfo = (state, payload) => {
  // JSON.stringify() on a memory object yields nothing;
  // We have to pull out each property first
  const totalJSHeapSize = _.get(payload, 'totalJSHeapSize');
  const usedJSHeapSize = _.get(payload, 'usedJSHeapSize');
  const jsHeapSizeLimit = _.get(payload, 'jsHeapSizeLimit');

  const stringifiedJson = JSON.stringify({
    totalJSHeapSize,
    usedJSHeapSize,
    jsHeapSizeLimit,
  });
  state.memoryInfo = stringifiedJson;
};

export const email = (state, email) => {
  state.email = email;
  window.localStorage.setItem('email', email);
};

export const isSimulating = (state, isSimulating) => {
  state.isSimulating = isSimulating;
  window.localStorage.setItem('isSimulating', JSON.stringify(isSimulating));
};

export const nativeAppVersion = (state, nativeAppVersion) => {
  state.nativeAppVersion = nativeAppVersion;
};

export const disableSafeDrivingMode = (state, disableSafeDrivingMode) => {
  state.disableSafeDrivingMode = disableSafeDrivingMode;
  window.localStorage.setItem(
    'disableSafeDrivingMode',
    JSON.stringify(disableSafeDrivingMode),
  );
};

export const useStadiaNavigation = (state, useStadiaNavigation) => {
  state.useStadiaNavigation = useStadiaNavigation;
  window.localStorage.setItem(
    'useStadiaNavigation',
    JSON.stringify(useStadiaNavigation),
  );
};

export const useStadiaPolylines = (state, useStadiaPolylines) => {
  state.useStadiaPolylines = useStadiaPolylines;
  window.localStorage.setItem(
    'useStadiaPolylines',
    JSON.stringify(useStadiaPolylines),
  );
};

export const distancesFromRouteLines = (state, opts) => {
  state.distancesFromRouteLines = opts;
};
