<template>
  <div id="debugging-overlay" @click="close">
    <div class="debugging-info-wrap">
      <div class="debugging-info">
        <h1>Debugging Info:</h1>
        <div class="debugging-item">
          <span class="title">Host: </span>
          <span class="value">{{ $store.getters.host }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">UUID: </span>
          <span class="value">{{ $store.getters.uuid || 'unset' }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Registration Status: </span>
          <span class="value">{{
            $store.getters.registrationStatus || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Agency: </span>
          <span class="value">{{
            $store.getters.currentAgencyKey || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Vehicle: </span>
          <span class="value">{{
            $store.getters.currentVehicleId || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Vehicle Assignment Error: </span>
          <span class="value">{{
            $store.getters.vehicleAssignmentError || 'none'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Route: </span>
          <span class="value">{{
            $store.getters.currentRouteKey || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Trip: </span>
          <span class="value">{{
            $store.getters.currentTripId || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Block: </span>
          <span class="value">{{
            $store.getters.currentBlockId || 'unset'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">GPS System Permission: </span>
          <span class="value">{{
            $store.getters.geolocationEnabledState || '...'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">GPS Lat/Lon: </span>
          <span class="value"
            >{{ $store.getters.gpsLat || '...' }}/{{
              $store.getters.gpsLon || '...'
            }}</span
          >
        </div>
        <div class="debugging-item">
          <span class="title">GPS Heading: </span>
          <span class="value">{{ $store.getters.gpsHeading || '...' }}</span>
        </div>
        <div class="debugging-item">
          <span class="title"
            >GPS Speed (MPS/MPH, above moving threshold?):
          </span>
          <span class="value"
            >{{ gpsSpeed }}/{{ gpsSpeedInMilesPerHour }},
            {{ $store.getters.vehicleIsMoving }}</span
          >
        </div>
        <div class="debugging-item">
          <span class="title">GPS Timestamp: </span>
          <span class="value">{{ $store.getters.gpsTimestamp || '...' }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Next/Current Stop: </span>
          <span class="value">{{
            $store.getters.vehicleStopName || '...'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Meters to Next/Current Stop: </span>
          <span class="value">{{
            $store.getters.metersToCurrentStop || '...'
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Is At Stop? (Client/Server): </span>
          <span class="value">
            {{ $store.getters.isVehicleAtStop || '...' }}/{{
              $store.getters.isVehicleAtStopServerStatus || '...'
            }}</span
          >
        </div>
        <div class="debugging-item">
          <span class="title">Headway Mode: </span>
          <span class="value">{{ $store.getters.headwayMode }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Developer Mode: </span>
          <span class="value">{{ $store.getters.developerMode }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Wake Lock: </span>
          <span class="value">{{ $store.getters.wakeLockStatus }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Messaging connection status: </span>
          <span class="value">{{
            $store.state.messages.twilioConnectionState
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Heap Size Limit: </span>
          <span class="value">{{ $store.getters.heapSizeLimit }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Total Heap Size: </span>
          <span class="value">{{
            $store.getters.totalJavaScriptHeapSize
          }}</span>
        </div>
        <div class="debugging-item">
          <span class="title">Heap Size Used: </span>
          <span class="value">{{ $store.getters.usedJavaScriptHeapSize }}</span>
        </div>
        <button class="deregister-device" @click.stop="onDeregisterDeviceTap">
          Deregister Device
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';

export default {
  name: 'DebuggingOverlay',
  computed: {
    gpsSpeed() {
      const gpsSpeed = this.$store.getters.gpsSpeed;
      if (!_.isFinite(gpsSpeed)) {
        return '...';
      }
      return gpsSpeed.toFixed(1);
    },
    gpsSpeedInMilesPerHour() {
      const gpsSpeedInMilesPerHour = this.$store.getters.gpsSpeedInMilesPerHour;
      if (!_.isFinite(gpsSpeedInMilesPerHour)) {
        return '...';
      }
      return gpsSpeedInMilesPerHour.toFixed(1);
    },
  },
  methods: {
    async onDeregisterDeviceTap() {
      const vc = this;
      Segment.track('device-deregistration-tap');
      try {
        const message =
          'This should only be used by IT/Maintenance. We will send a confirmation email when this occurs. Press OK to deregister this device.';
        if (await vc.$confirm(message)) {
          Segment.track('device-deregistration-confirmed');
          vc.$emit('close-debugging-overlay');
          vc.$store.dispatch('deregisterDevice', vc);
        }
      } catch (error) {
        // `vue-simple-alert` rejects without an error :(
        if (error != null) {
          ErrorReporter.capture({
            level: 'error',
            messageOrException: 'Failed to deregister device',
            extraContext: { error },
          });
        }
      }
    },
    close() {
      const vc = this;
      vc.$emit('close-debugging-overlay');
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_colors.styl"

#debugging-overlay {
    z-index 3
    position fixed
    top 0
    left 0
    width 100%
    height 100%
    background-color $black-trnsp-025
    opacity 0.87
    color $black

    .debugging-info-wrap {
        position absolute
        left 4%
        top 4%
        width 92%
        height 92%
        background-color $white
        padding 2.4rem
        border-radius 4px
        border-radius 0.4rem
        overflow-y scroll
    }

    .debugging-info {
        font-size 14px
        font-size 1.4rem

        h1 {
            margin-top 0
            font-size 20px
            font-size 2rem
            font-weight 300
        }

        .debugging-item {
            margin-bottom 6px
            margin-bottom 0.6rem
            .title {
                font-weight 500
            }
            .value {
                font-weight 300
            }
        }

        .deregister-device {
            position fixed
            bottom calc(4% + 2.4rem)
            right calc(4% + 2.4rem)
            color $black
            text-decoration underline
            cursor pointer
            padding 10px
            background none
            border none
            font inherit
        }
    }
}
</style>
