var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"operator-login"}},[(_vm.showFullError)?_c('FullErrorLogo',{attrs:{"showFullError":_vm.showFullError}}):(_vm.$store.getters.showLoginOverlay)?_c('div',{staticClass:"main-content animated fadeIn",class:{ blur: _vm.showPartialError }},[_c('div',{staticClass:"bg-image-watermark"}),_vm._v(" "),_c('div',{staticClass:"darkening-overlay"}),_vm._v(" "),_c('div',{staticClass:"actions-wrap"},[_c('Logo'),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTrainingModeNotice),expression:"showTrainingModeNotice"}],staticClass:"operator-login__notice"},[_vm._v("\n        Training mode is enabled. Swiftly will not record your sign-in or GPS\n        data\n      ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWaitingForAssignment),expression:"showWaitingForAssignment"}],staticClass:"operator-login__notice operator-login__notice--centered"},[_vm._v("\n        Waiting for vehicle assignment...\n      ")]),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.showZeroTouchLoginNotice),expression:"showZeroTouchLoginNotice"}],staticClass:"operator-login__notice"},[_vm._v("\n        Select a vehicle ID to complete setup.\n      ")]),_vm._v(" "),(_vm.showZeroTouchLoginNotice)?_c('div',{staticClass:"action-button",class:{
          'vehicle-is-moving': _vm.vehicleIsMoving,
        },on:{"click":_vm.onZeroTouchSelectVehicle}},[_vm._v("\n        Select Vehicle ID\n      ")]):_vm._e(),_vm._v(" "),(!_vm.isZeroTouchLoginEnabled)?_c('div',{staticClass:"action-button",class:{
          'vehicle-is-moving': _vm.vehicleIsMoving,
        },on:{"click":_vm.onSignInTap}},[_vm._v("\n        Sign In\n      ")]):_vm._e()],1)]):_c('div',{staticClass:"animated fadeIn",class:{
      'blur': _vm.showPartialError,
      'not-showing-login-selections': !_vm.$store.getters.showLoginSelections,
      'showing-confirmation-info': _vm.showConfirmationInfo,
    },attrs:{"id":"selections-wrap"}},[(_vm.$store.getters.showLoginSelections)?_c('div',{staticClass:"selections"},[(_vm.showOperatorLogin)?_c('div',{staticClass:"selection-wrap operator",class:{ active: _vm.selectionFocus === 'operator' }},[_c('div',{staticClass:"icon-wrap"},[_c('inline-svg',{attrs:{"name":"perm_identity","classes":"icon-smaller"}})],1),_vm._v(" "),(!_vm.operator)?_c('div',[_c('input',{directives:[{name:"$model-operator-filter-text",rawName:"v-$model-operator-filter-text",value:(_vm.operatorFilterText),expression:"operatorFilterText"}],attrs:{"id":"input-operator","type":"text","placeholder":"Enter Operator ID (tap here for search)"}})]):_c('div',{staticClass:"label",on:{"click":_vm.editOperator}},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.operatorIdLabel))]),_vm._v(" "),_c('div',{staticClass:"edit-button",on:{"click":_vm.handleClearOperator}},[_c('inline-svg',{attrs:{"name":"close","classes":"close"}})],1)])]):_vm._e(),_vm._v(" "),(!_vm.showOperatorLogin || _vm.operator)?_c('div',{staticClass:"selection-wrap vehicle",class:{ active: _vm.selectionFocus === 'vehicle' }},[_c('div',{staticClass:"icon-wrap"},[_c('inline-svg',{attrs:{"name":"directions_bus","classes":"icon-smaller"}})],1),_vm._v(" "),(!_vm.vehicleId)?_c('div',[_c('input',{directives:[{name:"$model-vehicle-filter-text",rawName:"v-$model-vehicle-filter-text",value:(_vm.vehicleFilterText),expression:"vehicleFilterText"}],attrs:{"id":"input-vehicle","type":"text","placeholder":"Select Vehicle ID (tap here for search)"}})]):_vm._e(),_vm._v(" "),(_vm.vehicleId)?_c('div',{staticClass:"label",on:{"click":_vm.editVehicle}},[_c('div',{staticClass:"value"},[_vm._v("Vehicle "+_vm._s(_vm.vehicleId))]),_vm._v(" "),(!_vm.$store.getters.hasPermanentAssignment)?_c('div',{staticClass:"edit-button",on:{"click":_vm.handleClearVehicle}},[_c('inline-svg',{attrs:{"name":"close","classes":"close"}})],1):_vm._e()]):_vm._e()]):_vm._e(),_vm._v(" "),(
          (!_vm.showOperatorLogin || _vm.operator) &&
          _vm.vehicleId &&
          !_vm.isPrePopulatingTripInfo
        )?_c('div',{staticClass:"selection-wrap route",class:{ active: _vm.selectionFocus === 'route' }},[_c('div',{staticClass:"icon-wrap"},[_c('inline-svg',{attrs:{"name":"directions","classes":"icon-smaller"}})],1),_vm._v(" "),(_vm.showRouteFilterText)?_c('div',[_c('input',{directives:[{name:"$model-route-filter-text",rawName:"v-$model-route-filter-text",value:(_vm.routeFilterText),expression:"routeFilterText"}],attrs:{"id":"input-route","type":"text","placeholder":"Select Route (tap here for search)"}})]):_c('div',{staticClass:"label",on:{"click":_vm.editRoute}},[_c('div',{staticClass:"value"},[_vm._v("Route "+_vm._s(_vm.routeKey))]),_vm._v(" "),_c('div',{staticClass:"edit-button",on:{"click":_vm.handleClearRoute}},[_c('inline-svg',{attrs:{"name":"close","classes":"close"}})],1)])]):_vm._e(),_vm._v(" "),(_vm.vehicleId && _vm.routeKey && !_vm.isPrePopulatingTripInfo)?_c('div',{staticClass:"selection-wrap trip",class:{ active: _vm.selectionFocus === 'trip' }},[_c('div',{staticClass:"icon-wrap"},[_c('inline-svg',{attrs:{"name":"event_note","classes":"icon-smaller"}})],1),_vm._v(" "),(_vm.tripId)?_c('div',{staticClass:"label"},[_c('div',{staticClass:"value"},[_vm._v(_vm._s(_vm.selectedTripDisplayName))]),_vm._v(" "),_c('div',{staticClass:"edit-button",on:{"click":_vm.handleClearTrip}},[_c('inline-svg',{attrs:{"name":"close","classes":"close"}})],1)]):_c('div',{staticClass:"label"},[_vm._v("Select Trip")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.showFilteredResults)?_c('div',{attrs:{"id":"filtered-results"}},_vm._l((_vm.filteredResults),function(result){return _c('div',{key:result.key,class:("result " + (result.customClass)),attrs:{"value":result.value},on:{"click":function($event){return _vm.selectResult(result)}}},[(result.iconName != null)?_c('div',{staticClass:"icon-wrap"},[_c('inline-svg',{attrs:{"name":result.iconName,"classes":"icon-smaller"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"result-value"},[_vm._v(_vm._s(result.displayName))])])}),0):(
        (!_vm.vehicleId && _vm.selectionFocus === 'vehicle') ||
        (!_vm.routeKey && _vm.selectionFocus === 'route') ||
        (!_vm.tripId && _vm.selectionFocus === 'trip')
      )?_c('div',{class:_vm.classFilteredResults,attrs:{"id":"filtered-results"}},[(_vm.showLoadingSpinner)?_c('div',{staticClass:"loading-spinner"},[_c('LoadingAnimation')],1):_c('div',{staticClass:"no-result-message",domProps:{"innerHTML":_vm._s(_vm.noFilteredResultsHtml)}})]):_vm._e(),_vm._v(" "),(_vm.showConfirmationInfo)?_c('div',{staticClass:"animated fadeIn",attrs:{"id":"confirmation-info"}},[_c('div',{staticClass:"confirmation-title"},[_vm._v("Confirm Assignment")]),_vm._v(" "),(_vm.showAssignedTripInfo)?_c('div',{staticClass:"confirmation-subtitle",domProps:{"textContent":_vm._s(
          ("Vehicle " + _vm.vehicleId + " already has an assigned block. Tap the correct assignment.")
        )}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"assignment-options"},[(_vm.showAssignedTripInfo)?_c('div',{staticClass:"option assigned",class:_vm.classAssignedTrip,on:{"click":function($event){_vm.selectedTripCategory = 'assigned'}}},[_c('div',{staticClass:"option-info selection-state"},[_c('div',{staticClass:"circle"},[_c('div',{staticClass:"filling"})])]),_vm._v(" "),_c('div',{staticClass:"option-info left"},[_c('div',{staticClass:"option-label"},[_vm._v("Current")]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.tripTimeDisplay(_vm.assignedTripInfo)))])]),_vm._v(" "),_c('div',{staticClass:"option-separator",class:{
              'has-operator-id': _vm.showOperatorLabel,
              'has-multiple-options': !_vm.hasSingleTripOption,
            }}),_vm._v(" "),_c('div',{staticClass:"option-info right"},[_c('div',{staticClass:"label route"},[_vm._v("\n              Route\n              "+_vm._s(_vm.$store.getters.routeFullName(_vm.assignedTripInfo.routeShortName))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"label first-line"},[_vm._v("\n              "+_vm._s(_vm.tripFirstLine(_vm.assignedTripInfo))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"label second-line"},[_vm._v("\n              "+_vm._s(_vm.tripSecondLine(_vm.assignedTripInfo))+"\n            ")]),_vm._v(" "),(_vm.showOperatorLabel)?_c('div',{staticClass:"operator-id-label"},[_vm._v("\n              "+_vm._s(_vm.operatorIdLabel)+"\n            ")]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.showNewTripInfo)?_c('div',{staticClass:"option desired",class:_vm.classNewTrip,on:{"click":function($event){_vm.selectedTripCategory = 'desired'}}},[_c('div',{staticClass:"option-info selection-state"},[_c('div',{staticClass:"circle"},[_c('div',{staticClass:"filling"})])]),_vm._v(" "),_c('div',{staticClass:"option-info left"},[_c('div',{staticClass:"option-label"},[_vm._v(_vm._s(_vm.newTripOptionLabelText))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.tripTimeDisplay(_vm.newTripInfo)))])]),_vm._v(" "),_c('div',{staticClass:"option-separator",class:{
              'has-operator-id': _vm.showOperatorLabel,
              'has-multiple-options': !_vm.hasSingleTripOption,
            }}),_vm._v(" "),_c('div',{staticClass:"option-info right"},[_c('div',{staticClass:"label route"},[_vm._v("\n              Route\n              "+_vm._s(_vm.$store.getters.routeFullName(_vm.newTripInfo.routeShortName))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"label first-line"},[_vm._v("\n              "+_vm._s(_vm.tripFirstLine(_vm.newTripInfo))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"label second-line"},[_vm._v("\n              "+_vm._s(_vm.tripSecondLine(_vm.newTripInfo))+"\n            ")]),_vm._v(" "),(!_vm.showAssignedTripInfo || _vm.assignmentToBeTaken != null)?_c('div',{staticClass:"label vehicle-id"},[(_vm.assignmentToBeTaken != null)?_c('div',{staticClass:"vehicle-id--taken"},[_vm._v("\n                Vehicle "+_vm._s(_vm.assignmentToBeTaken.vehicleId)+"\n              ")]):_vm._e(),_vm._v("\n              Vehicle "+_vm._s(_vm.vehicleId)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.showOperatorLabel)?_c('div',{staticClass:"operator-id-label"},[(
                  _vm.assignmentToBeTaken != null &&
                  _vm.assignmentToBeTaken.operatorId != null
                )?_c('div',{staticClass:"operator-id--taken"},[_vm._v("\n                Operator "+_vm._s(_vm.assignmentToBeTaken.operatorId)+"\n              ")]):_vm._e(),_vm._v("\n              "+_vm._s(_vm.operatorIdLabel)+"\n            ")]):_vm._e()])]):_vm._e()]),_vm._v(" "),(_vm.showConfirmAssignmentButton)?_c('div',{staticClass:"confirm-assignment-button",on:{"click":_vm.onConfirmAssignment}},[_vm._v("\n        Confirm assignment\n      ")]):(_vm.showTakeAssignmentButton)?_c('div',{staticClass:"take-assignment-confirmation"},[_c('p',{staticClass:"take-assignment-confirmation__message"},[_vm._v("\n          This trip is already assigned to Vehicle\n          "+_vm._s(_vm.assignmentToBeTaken.vehicleId)+".\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          Taking this trip assignment will un-assign Vehicle\n          "+_vm._s(_vm.assignmentToBeTaken.vehicleId)+".\n        ")]),_vm._v(" "),_c('div',{staticClass:"take-assignment-confirmation__buttons"},[_c('button',{staticClass:"take-assignment-confirmation__back-button",on:{"click":_vm.onTakeAssignmentGoBack}},[_vm._v("\n            Go Back\n          ")]),_vm._v(" "),_c('button',{staticClass:"take-assignment-confirmation__take-assignment-button",on:{"click":_vm.onConfirmAssignment}},[_vm._v("\n            Take Assignment\n          ")])])]):_vm._e()]):_vm._e()]),_vm._v(" "),(_vm.showPartialError && !_vm.showFullError)?_c('PartialError',{attrs:{"showPartialError":_vm.showPartialError}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }