<template>
  <nav class="nav-bar">
    <div class="nav-bar__button-container">
      <IconButton
        :isActive="activeView === NAV_VIEWS.TIMEPOINTS"
        :isDisabled="isButtonDisabled"
        :onDisabledClick="
          () => onDisabledButtonClick('timepoints-button-clicked')
        "
        :iconName="timepointsIconName"
        text="Timepoints"
        @click="onNavButtonTap(NAV_VIEWS.TIMEPOINTS)"
      />
      <IconButton
        v-if="showNativeMapControls"
        :isActive="activeView === NAV_VIEWS.MAP"
        :isDisabled="isButtonDisabled"
        :onDisabledClick="() => onDisabledButtonClick('map-button-clicked')"
        :iconName="mapIconName"
        text="Map"
        @click="onNavButtonTap(NAV_VIEWS.MAP)"
      />
      <IconButton
        v-if="isMessagingEnabled"
        :isActive="activeView === NAV_VIEWS.MESSAGES"
        :isDisabled="isButtonDisabled"
        :onDisabledClick="() => onDisabledButtonClick('message-button-clicked')"
        iconName="google-fonts-chat-bubble-40x40"
        text="Messages"
        @click="onNavButtonTap(NAV_VIEWS.MESSAGES)"
        :customClasses="{
          'icon-button--new': hasNewMessages,
          'icon-button--unread': showUnreadMessagesDot,
        }"
      />
      <IconButton
        v-if="isPassengerCountingEnabled"
        :isActive="activeView === NAV_VIEWS.PASSENGER_COUNTING"
        :isDisabled="isButtonDisabled"
        :onDisabledClick="() => onDisabledButtonClick('count-button-clicked')"
        :iconName="passengerCountIconName"
        text="Count"
        @click="onNavButtonTap(NAV_VIEWS.PASSENGER_COUNTING)"
      />
      <IconButton
        :isActive="activeView === NAV_VIEWS.MY_SWIFTLY"
        :isDisabled="isButtonDisabled"
        :onDisabledClick="() => onDisabledButtonClick('menu-button-clicked')"
        iconName="google-fonts-settings-40x40"
        text="My Swiftly"
        @click="onNavButtonTap(NAV_VIEWS.MY_SWIFTLY)"
      />
    </div>
  </nav>
</template>

<script>
import { debounce } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import * as Segment from '../../analytics/segment';
import { OPERATOR_DISPATCH_MESSAGING } from '../../optimizely/feature-flags';
import { NAV_VIEWS } from '../../store/modules/navigation';

export default {
  props: {
    hasConnectionError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNativeMapShowing: {
      type: Boolean,
      required: false,
      default: false,
    },
    showFullError: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      NAV_VIEWS,
      hasNewMessages: false,
    };
  },
  computed: {
    ...mapGetters({
      activeView: 'navigation/activeView',
      lastView: 'navigation/lastView',
      hasUnreadMessages: 'messages/hasUnreadMessages',
    }),
    ...mapState({
      numMessagesFromOthers: ({ messages }) =>
        messages.messages.filter(
          (message) => message.participant.attributes.role !== 'operator',
        ).length,
    }),
    isButtonDisabled() {
      return this.$store.getters.vehicleIsMoving || this.hasConnectionError;
    },
    isMessagingEnabled() {
      return this.$store.getters.userHasFeatureAccess(
        OPERATOR_DISPATCH_MESSAGING,
      );
    },
    isPassengerCountingEnabled() {
      return (
        this.$store.getters.isPassengerCountingEnabled &&
        this.$store.getters.agencyPassengerCountingButtons != null
      );
    },
    mapIconName() {
      return this.activeView === NAV_VIEWS.MAP
        ? 'google-fonts-map-filled-40x40'
        : 'google-fonts-map-40x40';
    },
    passengerCountIconName() {
      return this.activeView === NAV_VIEWS.PASSENGER_COUNTING
        ? 'google-fonts-person-filled-40x40'
        : 'google-fonts-person-40x40';
    },
    timepointsIconName() {
      return this.activeView === NAV_VIEWS.TIMEPOINTS
        ? 'google-fonts-schedule-filled-40x40'
        : 'google-fonts-schedule-40x40';
    },
    showNativeMapControls() {
      if (
        this.showFullError ||
        !this.$store.getters.isNativeAppWithMapSupport
      ) {
        return false;
      }
      return true;
    },
    showUnreadMessagesDot() {
      return this.hasUnreadMessages && this.activeView !== NAV_VIEWS.MESSAGES;
    },
    vehicleIsMoving() {
      return this.$store.getters.vehicleIsMoving;
    },
    navViewConfig() {
      return JSON.stringify({
        activeView: this.activeView,
        buttonsEnabled: !this.isButtonDisabled,
        isVehicleMoving: this.vehicleIsMoving,
        showMap: this.showNativeMapControls,
        showMessages: this.isMessagingEnabled,
        showPassengerCounting: this.isPassengerCountingEnabled,
        hasNewMessages: this.hasNewMessages,
        hasUnreadMessages: this.showUnreadMessagesDot,
      });
    },
  },
  watch: {
    numMessagesFromOthers() {
      if (!this.hasNewMessages) {
        setTimeout(() => {
          this.hasNewMessages = false;
        }, 5000); // Should be longer than the duration of the CSS animation
        this.hasNewMessages = true;
      }
    },
    lastView(lastView) {
      if (lastView === NAV_VIEWS.MESSAGES) {
        this.$store.dispatch('messages/markLastMessageRead');
      }
    },
    navViewConfig(config) {
      this.$store.dispatch('navigation/setViewConfig', {
        showNavBar: true,
        ...JSON.parse(config),
      });
    },
  },
  beforeMount() {
    this.$store.dispatch('navigation/setViewConfig', {
      showNavBar: true,
      ...JSON.parse(this.navViewConfig),
    });
  },
  beforeDestroy() {
    this.$store.dispatch('navigation/setViewConfig', {
      showNavBar: false,
      ...JSON.parse(this.navViewConfig),
    });
  },
  methods: {
    onDisabledButtonClick: debounce(
      function (event) {
        if (this.isNativeMapShowing) {
          this.$emit('nativeMapTouchWhileDriving');
        }
        Segment.track(event, {
          isVehicleMoving: this.vehicleIsMoving,
        });
      },
      500,
      {
        leading: false,
        trailing: true,
      },
    ),
    onNavButtonTap: debounce(
      function (view) {
        this.$store.dispatch('navigation/setActiveView', view);
        Segment.track('menu-button-clicked', {
          isVehicleMoving: this.vehicleIsMoving,
          view,
        });
      },
      500,
      {
        leading: true,
        trailing: false,
      },
    ),
  },
};
</script>

<style lang="stylus" scoped>
@require "../../styl/_variables.styl"
@require "../../styl/_colors.styl"
.nav-bar {
  border-top 1px solid $background-color-3
  background-color $background-color
  text-align center
  z-index 3

  &__button-container {
    height $nav-bar-height
    display flex
    > .icon-button {
      flex-grow 1
    }
  }
  >>> .icon-button {
    &--new {
      i {
        transform-origin 50% right
        animation rotate 0.25s 2
      }
    }

    &--unread {
      i {
        display inline-block
        position relative

        &::after {
          content ''
          position absolute
          top -6px
          right -6px
          width 20px
          height 20px
          border-radius 50%
          background-color $color-ui-error
        }
      }
    }

    &__text {
      min-width 100px
      color $white
      font-size 2.2rem
      font-weight 400
      white-space nowrap
    }
  }

  @keyframes rotate {
    0%, 100% {
      transform rotate(0)
    }

    25% {
      transform rotate(25deg)
    }

    75% {
      transform rotate(-25deg)
    }
  }
}

// Conditional styles for screens smaller than 600 pixels (small tablets)
@media (max-width:599px) {
  .nav-bar__button-container {
    >>> .icon-button {
      .icon-button__text {
        font-size 1.9rem
      }
    }

    // Only if all 5 buttons are present, hide button text altogether
    >>> .icon-button:first-child:nth-last-child(5),
    >>> .icon-button:first-child:nth-last-child(5) ~ .icon-button {
      .icon-button__text {
        display none
      }
    }
  }
}

// Conditional styles for screens smaller than 458 pixels (phones)
@media (max-width:457px) {
  .nav-bar__button-container {
    >>> .icon-button {
      .icon-button__text {
        display none
      }
    }
  }
}
</style>
