const resettableState = () => ({
  nowTimestamp: Date.now(),
});

let tickingInterval = null;

export const currentTime = {
  namespaced: true,
  state: resettableState(),
  mutations: {
    nowTimestamp(state, timestamp) {
      state.nowTimestamp = timestamp;
    },
  },
  actions: {
    start({ commit, state }) {
      if (tickingInterval != null) {
        return;
      }

      commit('nowTimestamp', Date.now());

      tickingInterval = setInterval(() => {
        commit('nowTimestamp', Date.now());
      }, 1000);
    },
    stop() {
      clearInterval(tickingInterval);
      tickingInterval = null;
    },
  },
  getters: {
    date(state) {
      return new Date(state.nowTimestamp);
    },
  },
};
