const timeRegExp = /^(\d{1,2}):(\d{2})(?::(\d{2}))?$/;

// exported for testing
export const _parseTransitimeTime = (transitimeTimeString) => {
  const match = timeRegExp.exec(transitimeTimeString);
  if (match == null) {
    throw new Error(`Cannot parse "${transitimeTimeString}" as a time`);
  }
  const [, hourString, minuteString] = match;
  return { hourString, minuteString };
};

export const convertTransitimeTimeToHumanFriendly = (transitimeTimeString) => {
  const { hourString, minuteString } =
    _parseTransitimeTime(transitimeTimeString);
  // Times can be >= 24:00 for a given service date
  const transitimeHour = parseInt(hourString) % 24;
  const hour = transitimeHour % 12 === 0 ? 12 : transitimeHour % 12;
  const period = transitimeHour >= 12 ? 'pm' : 'am';
  return `${hour}:${minuteString}${period}`;
};

const HOUR_DIFF_THRESHOLD = 18;
export const isTransitimeTimeInPast = (transitimeTimeString) => {
  const { hourString, minuteString } =
    _parseTransitimeTime(transitimeTimeString);
  const transitimeHour = parseInt(hourString);
  const now = new Date();
  let currentHour = now.getHours();
  // Times can be >= 24:00 for a given service date.
  // This threshold is a heuristic, but works fine for Transitime times around
  // the current time, which is generally what we're interested in.
  // TODO(haysmike) Pass in a service date so we know for sure?
  if (transitimeHour - currentHour >= HOUR_DIFF_THRESHOLD) {
    currentHour += 24;
  }
  return currentHour === transitimeHour
    ? now.getMinutes() >= parseInt(minuteString)
    : currentHour > transitimeHour;
};
