<template>
  <div class="full-error-logo animated fadeIn">
    <Logo />
    <h1 class="title">Swiftly</h1>
    <div class="error-container">
      <h2 class="error-msg">
        <span class="lds-dual-ring"></span> {{ fullErrorLineOneHtml }}
      </h2>
    </div>
  </div>
</template>
<script>
import Logo from '../Logo.vue';

export default {
  name: 'FullErrorLogo',
  components: { Logo },
  props: { showFullError: { type: String, required: true } },
  computed: {
    fullErrorLineOneHtml() {
      switch (this.showFullError) {
        case 'connection':
          return 'No Internet connection. Please check device settings.';
        case 'server':
          return 'Lost Server Connection. Reconnecting...';
      }
      return '';
    },
  },
};
</script>
<style lang="stylus">
@require "../../styl/_colors.styl"

.full-error-logo {
    color $white
    display flex
    flex-direction column
    align-items center
    position fixed
    top 45%
    left 50%
    transform translate(-50%, -50%)
    text-align center
    .title{
        font-size 2.5rem
        font-weight 300
        margin-top -2rem
    }
    .error-container {
        .error-msg {
            font-size 1.8rem
            font-weight 300

            .lds-dual-ring {
                display inline-block
                width 1.8rem
                height 1.8rem
                margin-right 1rem
            }
            .lds-dual-ring:after {
                content " "
                display block
                width 1.8rem
                height 1.8rem
                border-radius 50%
                border 0.2rem solid $white
                border-color $white transparent $white transparent
                animation lds-dual-ring 1.2s linear infinite
            }
        }
    }

}

@keyframes lds-dual-ring {
  0% {
      transform rotate(0deg)
  }
  100% {
      transform rotate(360deg)
  }
}
</style>
