<template>
  <div id="settings">
    <div class="title-bar">Settings</div>
    <div class="main-content">
      <div class="setting change-agency" v-if="$store.getters.currentAgencyKey">
        <div class="category">Agency</div>
        <div class="value">{{ $store.getters.currentAgencyDisplayName }}</div>
        <div class="action-wrap" @click="onTapAgencySetting">
          <inline-svg name="arrow_forward_ios"></inline-svg>
        </div>
      </div>
      <div class="setting permanent-vehicle-assignment">
        <div class="category">Vehicle ID</div>
        <div class="value">
          {{ permanentAssignmentOrCurrentVehicleIdDisplay }}
        </div>
        <div
          class="action-wrap"
          @click="onTapPermanentVehicleAssignmentSetting"
        >
          <inline-svg name="arrow_forward_ios"></inline-svg>
        </div>
      </div>
      <div v-if="!this.isZeroTouchLoginEnabled" class="setting training-mode">
        <div class="category">Training Mode</div>
        <div class="value">
          Status: {{ isTrainingModeEnabled ? 'on' : 'off' }}
        </div>
        <ToggleButton
          v-model="isTrainingModeEnabled"
          color="#0D4CE4"
          :sync="true"
          :disabled="hasConfirmedActiveAssignment"
        />
      </div>
      <div
        v-if="$store.getters.hasOperatorIds"
        class="setting enable-operator-login"
      >
        <div class="category">Sign in with Operator ID</div>
        <div class="value">
          Status:
          {{ $store.getters.showOperatorLogin ? 'enabled' : 'disabled' }}
        </div>
        <ToggleButton
          v-model="showOperatorLogin"
          color="#0D4CE4"
          :sync="true"
          :disabled="hasConfirmedActiveAssignment"
        />
      </div>
      <div class="setting desired-feedback">
        <div class="category">Headway Mode</div>
        <div class="value">
          Status: {{ $store.getters.headwayMode ? 'enabled' : 'disabled' }}
        </div>
        <ToggleButton v-model="headwayMode" color="#0D4CE4" :sync="true" />
      </div>
      <template v-if="shouldShowSecretSettings">
        <div class="setting secret developer-mode">
          <div class="category">Developer Mode</div>
          <div class="value">
            Status: {{ $store.getters.developerMode ? 'enabled' : 'disabled' }}
          </div>
          <ToggleButton v-model="developerMode" color="#0D4CE4" :sync="true" />
        </div>
        <div class="setting secret native-webview-host">
          <div class="category">Native WebView Host</div>
          <div class="value">{{ currentNativeWebViewHost }}</div>
          <div class="inputs">
            <select v-model="nativeWebViewHost">
              <option value="" disabled>Select a host</option>
              <option value="https://onboard.goswift.ly">Production</option>
              <option value="https://onboard-next.goswift.ly">Staging</option>
              <option value="https://onboard-next-prod.goswift.ly">
                Staging w/ Production Agencies
              </option>
              <option value="https://driver-inter-xyz.herokuapp.com">
                Heroku Review App
              </option>
              <option value="http://192.168.x.y:1337">Local Server</option>
            </select>
            <input v-model="nativeWebViewHost" />
            <button @click="handleHostChange">Go</button>
          </div>
        </div>
        <div class="setting secret show-stops-otp-view">
          <div class="category">Show Only Timepoint Stops in OTP View</div>
          <div class="value">
            Status:
            {{
              $store.getters.showTimepointsOnlyInOtpView
                ? 'enabled'
                : 'disabled'
            }}
          </div>
          <ToggleButton
            v-model="showTimepointsOnlyInOtpView"
            color="#0D4CE4"
            :sync="true"
          />
        </div>
        <div class="setting secret show-stops-map-view">
          <div class="category">Show Only Timepoint Stops in Map View</div>
          <div class="value">
            Status:
            {{
              $store.getters.showTimepointsOnlyInMapView
                ? 'enabled'
                : 'disabled'
            }}
          </div>
          <ToggleButton
            v-model="showTimepointsOnlyInMapView"
            color="#0D4CE4"
            :sync="true"
          />
        </div>
        <div class="setting secret show-route-stops">
          <div class="category">Show Upcoming Timepoints</div>
          <div class="value">
            Status: {{ $store.getters.showRouteStops ? 'enabled' : 'disabled' }}
          </div>
          <ToggleButton v-model="showRouteStops" color="#0D4CE4" :sync="true" />
        </div>
        <div class="setting secret simulation-mode">
          <div class="category">Simulation Mode</div>
          <div class="help">Requires training mode</div>
          <div class="value">Status: {{ isSimulating ? 'on' : 'off' }}</div>
          <ToggleButton
            v-model="isSimulating"
            color="#0D4CE4"
            :sync="true"
            :disabled="!isTrainingModeEnabled"
          />
        </div>
        <div class="setting secret disable-safe-driving-mode">
          <div class="category">Disable Safe Driving Mode</div>
          <div class="value">
            Status: {{ disableSafeDrivingMode ? 'on' : 'off' }}
          </div>
          <ToggleButton
            v-model="disableSafeDrivingMode"
            color="#0D4CE4"
            :sync="true"
          />
        </div>
        <div class="setting secret use-stadia-navigation">
          <div class="category">Use Stadia Navigation</div>
          <div class="value">
            Status: {{ useStadiaNavigation ? 'on' : 'off' }}
          </div>
          <ToggleButton
            v-model="useStadiaNavigation"
            color="#0D4CE4"
            :sync="true"
          />
        </div>
        <div class="setting secret use-stadia-polylines">
          <div class="category">Use Stadia Polylines</div>
          <div class="value">
            Status: {{ useStadiaPolylines ? 'on' : 'off' }}
          </div>
          <ToggleButton
            v-model="useStadiaPolylines"
            color="#0D4CE4"
            :sync="true"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import * as Segment from '../analytics/segment';
import { ONBOARD_APP_SECRET_MENU } from '../optimizely/feature-flags';

const ONBOARD_APP_HOST_PRODUCTION = 'https://onboard.goswift.ly';

export default {
  data() {
    return {
      isTrainingModeEnabled: !JSON.parse(
        localStorage.getItem('sendGeolocationUpdates'),
      ),
      currentNativeWebViewHost:
        localStorage.getItem('nativeWebViewHost') ||
        process.env.ONBOARD_APP_HOST ||
        ONBOARD_APP_HOST_PRODUCTION,
      nativeWebViewHost: '',
    };
  },
  computed: {
    ...mapGetters(['isZeroTouchLoginEnabled', 'hasConfirmedActiveAssignment']),
    permanentAssignmentOrCurrentVehicleIdDisplay() {
      // We show the current vehicle ID if logged in
      // or the permanently assigned vehicle ID if not logged in
      const currentVehicleId = this.$store.getters.currentVehicleId;
      const permanentlyAssignedVehicleId =
        this.$store.getters.permanentlyAssignedVehicleId;
      if (currentVehicleId) {
        return currentVehicleId;
      }
      if (permanentlyAssignedVehicleId) {
        return permanentlyAssignedVehicleId;
      }
      return 'Not assigned';
    },
    shouldShowSecretSettings() {
      return this.$store.getters.userHasFeatureAccess(ONBOARD_APP_SECRET_MENU);
    },
    headwayMode: {
      get() {
        return this.$store.getters.headwayMode;
      },
      set(newValue) {
        this.$store.commit('headwayMode', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Headway Mode',
          'setting-value': newValue,
        });
      },
    },
    developerMode: {
      get() {
        return this.$store.getters.developerMode;
      },
      set(newValue) {
        this.$store.commit('developerMode', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Developer Mode',
          'setting-value': newValue,
        });
      },
    },
    showRouteStops: {
      get() {
        return this.$store.getters.showRouteStops;
      },
      set(newValue) {
        this.$store.commit('showRouteStops', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Show Upcoming Timepoints',
          'setting-value': newValue,
        });
      },
    },
    showTimepointsOnlyInOtpView: {
      get() {
        return this.$store.getters.showTimepointsOnlyInOtpView;
      },
      set(newValue) {
        this.$store.commit('showTimepointsOnlyInOtpView', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Show Only Timepoint Stops in Otp View',
          'setting-value': newValue,
        });
      },
    },
    showTimepointsOnlyInMapView: {
      get() {
        return this.$store.getters.showTimepointsOnlyInMapView;
      },
      set(newValue) {
        this.$store.commit('showTimepointsOnlyInMapView', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Show Only Timepoint Stops in Map View',
          'setting-value': newValue,
        });
      },
    },
    showOperatorLogin: {
      get() {
        return this.$store.getters.showOperatorLogin;
      },
      set(newValue) {
        this.$store.commit('showOperatorLogin', newValue);
        Segment.track('setting-changed', {
          'setting-name': 'Enable Operator ID Login',
          'setting-value': newValue,
        });
      },
    },
    isSimulating: {
      get() {
        return this.$store.getters.isSimulating;
      },
      set(isSimulating) {
        this.$store.commit('isSimulating', isSimulating);
        Segment.track('setting-changed', {
          'setting-name': 'Simulation Mode',
          'setting-value': isSimulating,
        });
        const setLocation = window.NativeAPI?.setLocation;
        if (!isSimulating && setLocation != null) {
          setLocation({ isSimulating: false });
        }
      },
    },
    disableSafeDrivingMode: {
      get() {
        return this.$store.state.disableSafeDrivingMode;
      },
      set(disableSafeDrivingMode) {
        this.$store.commit('disableSafeDrivingMode', disableSafeDrivingMode);
        Segment.track('setting-changed', {
          'setting-name': 'Disable Safe Driving Mode',
          'setting-value': disableSafeDrivingMode,
        });
      },
    },
    useStadiaNavigation: {
      get() {
        return this.$store.state.useStadiaNavigation;
      },
      set(useStadiaNavigation) {
        this.$store.commit('useStadiaNavigation', useStadiaNavigation);
        Segment.track('setting-changed', {
          'setting-name': 'Use Stadia Navigation',
          'setting-value': useStadiaNavigation,
        });
      },
    },
    useStadiaPolylines: {
      get() {
        return this.$store.state.useStadiaPolylines;
      },
      set(useStadiaPolylines) {
        this.$store.commit('useStadiaPolylines', useStadiaPolylines);
        Segment.track('setting-changed', {
          'setting-name': 'Use Stadia Polylines',
          'setting-value': useStadiaPolylines,
        });
      },
    },
  },
  watch: {
    'isTrainingModeEnabled': {
      handler: async function (isOnTrainingMode) {
        const shouldSendGeolocationUpdates = !isOnTrainingMode;
        const isGeolocationEnabled = await this.isGeolocationEnabled();

        if (shouldSendGeolocationUpdates && !isGeolocationEnabled) {
          this.isTrainingModeEnabled = true;
          this.$store.dispatch('showPermissionDeniedInstructions');
          return;
        }
        this.$store.commit(
          'sendGeolocationUpdates',
          shouldSendGeolocationUpdates,
        );
        Segment.track('setting-changed', {
          'setting-name': 'Send GPS Updates',
          'setting-value': shouldSendGeolocationUpdates,
        });
      },
    },
    '$store.getters.sendGeolocationUpdates'(sendGeolocationUpdates) {
      this.isTrainingModeEnabled = !sendGeolocationUpdates;
    },
  },
  beforeDestroy() {
    Segment.identifyUser();
  },
  methods: {
    onTapAgencySetting() {
      const reason = 'User tapped change on AGENCY setting';
      const hasVehicle = Boolean(this.$store.getters.currentVehicleId);
      if (!hasVehicle) {
        this.$store.dispatch('logoutAgencyNoWarning', { vc: this, reason });
        return;
      }
      this.$store.dispatch('logoutAgency', { vc: this, reason });
    },
    onTapPermanentVehicleAssignmentSetting() {
      // Is the tablet is actively assigned to a block, changing this setting should
      // log the user out. Only when logged out can the permanent vehicle assignment
      // be changed
      if (this.$store.getters.hasConfirmedActiveAssignment) {
        const reason = 'User tapped change on VEHICLE setting';
        this.$store.dispatch('logoutVehicle', {
          vc: this,
          reason,
          shouldClearPermanentlyAssignedVehicle: true,
        });
        return;
      }
      this.$router.push({ name: 'permanent-vehicle-assignment' });
    },
    onTapVehicleSetting() {
      const reason = 'User tapped change on VEHICLE setting';
      this.$store.dispatch('logoutVehicle', { vc: this, reason });
    },
    handleHostChange() {
      if (this.nativeWebViewHost !== this.currentNativeWebViewHost) {
        // Our Cordova JS loader code loads the client JS bundle from this host
        global.localStorage.setItem(
          'nativeWebViewHost',
          this.nativeWebViewHost.replace(/\/$/, ''),
        );
        this.$router.go();
      }
    },
    async isGeolocationEnabled() {
      const geolocationEnabledState = await this.$store.dispatch(
        'checkPwaGeolocationEnabledState',
        { vc: this },
      );
      return geolocationEnabledState !== 'denied';
    },
  },
};
</script>

<style lang="stylus">
@require "../styl/_colors.styl"

#settings {
  position absolute
  top 0
  right 0
  width 100%
  height 100%
  background $background-color
  z-index 3

  display flex
  flex-direction column
  justify-content center

  .title-bar {
    display flex
    justify-content center
    align-items center
    min-height 5.4rem
    margin-top 20px
    color $white
    font-size 2.8rem
    font-weight 400
  }

  .main-content {
    display flex
    flex-direction column
    justify-content flex-start
    padding 32px 62px 0
    max-height calc( 100% - 6rem )
    overflow-x hidden
    overflow-y auto

    > .setting {
      position relative
      padding 24px 0
      border-bottom 1px solid $border-default
      cursor pointer

      .category {
        font-size 2.4rem
        font-weight 400
        color $text-default
      }

      .help {
        font-size 1.6rem
        color $silver-sand
      }

      .value {
        font-size 2.4rem
        font-weight 300
        padding-top 8px
        color $text-default
      }

      > .vue-js-switch {
        position absolute
        right 3rem
        top 4.6rem
      }

      .action-wrap {
        position absolute
        top 0
        right 0
        width 12rem
        height 100%

        > i {
          position absolute
          right 3.2rem
          top 4.3rem
          font-size 2.8rem
          color $border-default
        }
      }

      &.secret {
        .category {
            color $sea-buckthorn
        }

        .inputs {
          display flex
          flex-wrap wrap
          font-size 2.0rem

          > * {
            margin-top 10px
          }
        }

        select, input {
          margin-right 20px
        }

        input {
          flex-grow 2
        }
      }
    }
  }
}
</style>
