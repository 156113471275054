let _serviceWorkerRegistration = undefined;
let _serviceWorker = undefined;

const _hash = undefined;

const _initServiceWorker = async () => {
  const supportsServiceWorkers = 'serviceWorker' in navigator;
  if (!supportsServiceWorkers) {
    throw new Error(
      'Unabled to initialize service worker: missing client-side API support',
    );
  }

  // <debug>
  if (process.env.NODE_ENV === 'development') {
    return;
  }
  // </debug>

  console.log('initServiceWorker called...');

  _serviceWorkerRegistration = await navigator.serviceWorker.register(
    '/service-worker.js',
    {
      scope: './',
      updateViaCache: 'none',
    },
  );

  _serviceWorker =
    _serviceWorkerRegistration.installing ||
    _serviceWorkerRegistration.waiting ||
    _serviceWorkerRegistration.active;
  _sendServiceWorkerStatusUpdate(_serviceWorker);

  navigator.serviceWorker.addEventListener(
    'controllerchange',
    _onServiceWorkerControllerChange,
  );
  navigator.serviceWorker.addEventListener('message', _onServiceWorkerMessage);
};

const _sendServiceWorkerStatusUpdate = (target) => {
  const status = { status: { hash: _hash } };
  _sendServiceWorkerMessage(status, target);
};

const _sendServiceWorkerMessage = (message, target) => {
  const recipient =
    target || _serviceWorker || navigator.serviceWorker.controller;

  let validRecipient = true;
  if (typeof recipient !== 'object') {
    validRecipient = false;
  }
  if (typeof recipient.postMessage !== 'function') {
    validRecipient = false;
  }
  if (!validRecipient) {
    console.log('Unable to send service worker message: invalid recipient');
    return;
  }

  recipient.postMessage(message);
};

const _onServiceWorkerControllerChange = (event) => {
  console.log('_onServiceWorkerControllerChange called');
  _serviceWorker = navigator.serviceWorker.controller;
};

const _onServiceWorkerMessage = (event) => {
  const { data } = event;
  console.log('_onServiceWorkerMessage called', data);
};

const ServiceWorkerInterface = {};

ServiceWorkerInterface.init = () => {
  // If we are in an Cordova environment rather than a
  // PWA environment, there is no need to use a service worker
  if (window.cordova) {
    return;
  }
  _initServiceWorker().catch(console.error);
};

export default ServiceWorkerInterface;
