import * as Sentry from '@sentry/browser';

const ignoreErrors = [
  // The following are all different errors thrown when there's a connection error
  /Network Error/,
  /Request timed out/,
  /Host could not be resolved/,
  /There was an error with the request: unexpected end of stream/,
  /There was an error with the request: Failed to connect to/,
  /There was an error with the request: Connection reset/,
  /There was an error with the request: Connection timed out/,
  /No network connection available/,
  /^disconnected$/,
  /Software caused connection abort/,
  /SSL_HANDSHAKE_FAILURE/,
  /SSLHandshakeException/,
  /Request failed with status code 524/,
  /Twilsock: request timeout:/,
  // Occurs when trying to access the same route on the front-end.
  /NavigationDuplicated/,
];

export const initialize = () => {
  const environment =
    process.env.IS_PRODUCTION === 'true' ? 'production' : 'staging';
  // TODO(haysmike) Set release ID
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment,
    ignoreErrors,
  });
};

const sentryToConsoleLevelMap = {
  fatal: 'error',
  error: 'error',
  warning: 'warn',
  log: 'log',
  info: 'info',
  debug: 'debug',
  critical: 'error',
};

// TODO(haysmike) Either allow both a message and an error, or serialize errors within `extraContext` when logging
export const capture = ({
  level,
  messageOrException,
  extraContext = {},
  tags = {},
  logToConsole = true, // For HTTP requests, which browsers will already log
}) => {
  if (logToConsole) {
    console[sentryToConsoleLevelMap[level]](
      messageOrException,
      extraContext,
      tags,
    );
  }
  Sentry.withScope((scope) => {
    scope.setUser({ id: window.swiftlyUUID });
    scope.setLevel(level);
    scope.setExtra('appHash', window.appHash);
    for (const [key, value] of Object.entries(extraContext)) {
      scope.setExtra(key, value);
    }
    for (const [key, value] of Object.entries(tags)) {
      scope.setTag(key, value);
    }
    if (typeof messageOrException === 'string') {
      Sentry.captureMessage(messageOrException);
    } else {
      Sentry.captureException(messageOrException);
    }
  });
};

/*
 * @deprecated
 */
export const captureException = (error) =>
  capture({ level: 'error', messageOrException: error });

/*
 * @deprecated (used in native-api.js, which is deployed with the native app)
 */
export const captureMessage = (level, message) =>
  capture({ level, messageOrException: message });
