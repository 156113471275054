import _ from 'lodash';

const convertAdjustmentsToAlertText = (adjustments, userLocale) => {
  const adjustment = _.get(adjustments, 0, null);
  const adjustmentType = _.get(adjustment, 'adjustmentType', '');
  switch (adjustmentType) {
    case 'CANCEL_BLOCK':
      return 'Block canceled';
    case 'CLOSE_STOPS':
      return 'Skip stop';
    case 'MODIFY_DEPARTURE_TIME': {
      const adjustmentTime = _.get(adjustment, ['details', 'departureTime']);
      if (!_.isString(adjustmentTime)) {
        return '';
      }
      const readableAdjustmentTime = new Date(
        adjustmentTime,
      ).toLocaleTimeString(userLocale, {
        timeStyle: 'short',
      });
      return `Depart at ${readableAdjustmentTime} (adjusted)`;
    }
    case 'DETOUR_EXIT':
      return 'Detour';
    case 'DETOUR_ENTRY':
      // For now, convention is to show nothing for DETOUR_ENTRY
      return '';
    default:
      return '';
  }
};

export default convertAdjustmentsToAlertText;
