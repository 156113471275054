<template>
  <div id="passenger-counting">
    <div class="content-wrapper">
      <div class="content">
        <div class="passenger-count">
          <span>Pax. Count</span>
          <div
            v-touch:tap="onResetDrawerSwipeOrTap"
            v-touch:swipe="onResetDrawerSwipeOrTap"
            id="swipeReset"
            class="passenger-count-reset"
          >
            <span class="passenger-count-total">{{ passengerCountTotal }}</span>
            <div class="passenger-count-swipe">&#8942;</div>
            <div class="passenger-count-reset-button" @click="onResetTap">
              <inline-svg name="reset" classes="passenger-count-reset-icon" />
              Reset
            </div>
          </div>
        </div>
        <div class="buttons-wrapper">
          <div class="fare-buttons">
            <div
              v-for="button in passengerCountButtons"
              :key="button.type"
              :class="{
                'fare-button': true,
                'selected': fareCountSelected(button.type),
              }"
              @click="onAddFareTap(button.type)"
            >
              {{ button.displayName }}
              <div v-if="passengerCounts[button.type] !== 0" class="fare-count">
                <span>{{ getFareCount(button.type) }}</span>
                <button
                  type="button"
                  class="remove-fare-button"
                  @click.stop="onRemoveFareTap(button.type)"
                >
                  -
                </button>
              </div>
            </div>
          </div>
          <div class="passenger-off" @click="onPassengerOffTap()">Pax. Off</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatInTimeZone } from 'date-fns-tz';
import { mapGetters } from 'vuex';

import * as Segment from '../analytics/segment';
import * as ErrorReporter from '../error-reporter';
import fetch from '../fetch';

export default {
  name: 'PassengerCounting',
  components: {},
  data() {
    return {
      isResetOpen: false,
      passengerCounts: this.buildInitialPassengerCounts(),
      passengerAlightings: 0,
      // This needs to be stored as data because stop/trip can be updated
      // before `isVehicleAtStop`.
      vehicleInfo: this.$store.getters.lastValidVehicleInfo,
    };
  },
  computed: {
    ...mapGetters({
      currentServiceDate: 'passengerCounting/currentServiceDate',
      passengerCountButtons: 'agencyPassengerCountingButtons',
      passengerCountTotal: 'passengerCounting/passengerCountTotal',
    }),
  },
  // TODO(will): Would prefer for the passenger counting store to dispatch
  // a sendMpc action rather than this component
  destroyed() {
    this.sendMpcData();
  },
  methods: {
    buildInitialPassengerCounts() {
      return (this.$store.getters.agencyPassengerCountingButtons ?? []).reduce(
        (acc, button) => {
          acc[button.type] = 0;
          return acc;
        },
        {},
      );
    },
    fareCountSelected(fare) {
      if (this.passengerCounts[fare] !== 0) {
        return 'selected';
      }
    },
    getFareCount(fare) {
      if (this.passengerCounts[fare] !== 0) {
        return this.passengerCounts[fare];
      }
    },
    onAddFareTap(fare) {
      this.passengerCounts[fare]++;
      this.$store.dispatch('passengerCounting/increment');
      Segment.track('passenger-counting-add-fare-tapped', {
        fare,
      });
    },
    onPassengerOffTap() {
      if (this.passengerCountTotal > 0) {
        this.passengerAlightings++;
      }
      this.$store.dispatch('passengerCounting/decrement');
      Segment.track('passenger-counting-off-tapped');
    },
    onRemoveFareTap(fare) {
      if (this.passengerCounts[fare] > 0) {
        this.passengerCounts[fare]--;
      }
      this.$store.dispatch('passengerCounting/decrement');
      Segment.track('passenger-counting-remove-fare-tapped', {
        fare,
      });
    },
    onResetDrawerSwipeOrTap() {
      const transition = this.isResetOpen
        ? '0'
        : window.innerHeight < 599 || window.innerWidth < 599
        ? '-12rem'
        : '-15rem';
      document.getElementById('swipeReset').animate(
        [
          {
            transform: `translateX(${transition})`,
          },
        ],
        {
          duration: 100,
          easing: 'linear',
          fill: 'forwards',
        },
      );
      this.isResetOpen = !this.isResetOpen;
    },
    onResetTap() {
      this.$store.dispatch('passengerCounting/reset');
      Segment.track('passenger-counting-reset-tapped');
    },
    async sendMpcData() {
      const { lastValidVehicleInfo, lastTripLastVehicleInfo } =
        this.$store.getters;

      // This is very unlikely to happen since Transitime switches to the next
      // trip around 50m away from the last stop.
      const tripChangedWhileOpen =
        lastValidVehicleInfo.tripId != null &&
        this.vehicleInfo.tripId !== lastValidVehicleInfo.tripId;
      const isFirstStopOfNewTripAndLastStopOfLastTrip =
        this.vehicleInfo.nextStopPathIndex === 0 &&
        lastTripLastVehicleInfo != null &&
        this.vehicleInfo.nextStopId === lastTripLastVehicleInfo.nextStopId;

      const savedInfoBody = this.buildPostBody(this.vehicleInfo);
      let bodies;
      if (tripChangedWhileOpen) {
        bodies = [
          {
            ...savedInfoBody,
            alightings: this.passengerAlightings,
            boardingsByFareType: this.buildInitialPassengerCounts(),
          },
          {
            ...this.buildPostBody(lastValidVehicleInfo),
            alightings: 0,
            boardingsByFareType: this.passengerCounts,
          },
        ];
      } else if (isFirstStopOfNewTripAndLastStopOfLastTrip) {
        bodies = [
          {
            ...this.buildPostBody(lastTripLastVehicleInfo),
            alightings: this.passengerAlightings,
            boardingsByFareType: this.buildInitialPassengerCounts(),
          },
          {
            ...savedInfoBody,
            alightings: 0,
            boardingsByFareType: this.passengerCounts,
          },
        ];
      } else {
        bodies = [
          {
            ...savedInfoBody,
            alightings: this.passengerAlightings,
            boardingsByFareType: this.passengerCounts,
          },
        ];
      }
      await Promise.all(bodies.map(this.postData));
    },
    buildPostBody(vehicleInfo) {
      const {
        id: vehicleId,
        tripPattern: tripPatternId,
        nextStopPathIndex: stopPathIndex,
        serviceDate,
        tripId,
      } = vehicleInfo;
      const { currentAgencyInfo } = this.$store.getters;

      const dateInTimezone = formatInTimeZone(
        new Date(),
        currentAgencyInfo.timezone,
        "yyyy-MM-dd'T'HH:mm:ssXXX",
      );

      return {
        vehicleId,
        serviceDate,
        // `configRev` isn't actually used anywhere - explicitly setting to -1
        // so it's clear we're setting it to a nonsense value intentionally.
        configRev: -1,
        tripId,
        tripPatternId,
        stopPathIndex,
        time: dateInTimezone,
      };
    },
    async postData(data) {
      try {
        if (this.$store.getters.sendGeolocationUpdates) {
          await fetch({
            method: 'POST',
            url: `/sendMpcData/${this.$store.getters.agencyKeyForRequest}`,
            data,
          });
        }
        Segment.track('passenger-counting-sent-data', data);
      } catch (error) {
        ErrorReporter.capture({
          level: 'error',
          messageOrException: 'Failed to send MPC data to apc-intake',
          extraContext: { error },
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@require "../styl/_variables.styl"
@require "../styl/_colors.styl"
@require "../styl/_svg-icon-adjustments.styl"

#passenger-counting {
  position absolute
  top 0
  right 0
  width 100%
  height 100%

  display flex
  flex-direction column

  .content-wrapper {
    height 100%
    display flex
    flex-direction column
  }

  .content {
    display flex
    flex-direction column
    height 100%
    margin 0 1.6rem
    padding-top 1.6rem
  }

  .passenger-count {
    display flex
    justify-content space-between
    align-items center
    background-color $white-trnsp-010
    border-radius 0.4rem
    font-size 2.2rem
    font-weight 400
    color $white
    padding-left 2.4rem
    overflow hidden
  }

  .passenger-count-reset {
    display flex
    align-items center
    font-size 4.9rem
    font-weight 800
    margin-right -15.6rem
  }

  .passenger-count-total {
    margin-right 0.8rem
  }

  .passenger-count-swipe {
    font-size 2.8rem
    color $white-trnsp-033
    padding 1.8rem 0 1.8rem 1.8rem
  }
  .passenger-count-reset-button {
    display flex
    flex-direction column
    background-color red
    font-size 1.8rem
    font-weight 500
    margin-left 1rem
    padding 1.8rem 5.5rem
  }
  .passenger-count-reset-icon {
    color $white
  }
  .buttons-wrapper {
    display flex
    height 100%
    padding 1.6rem 0 1.2rem 0
  }

  .fare-buttons {
    position relative
    flex-grow 4
    display grid
    grid-auto-rows 1fr
    column-gap 1rem

    .fare-button {
      position relative
      display flex
      flex-direction row
      justify-content space-between
      background-color $black-trnsp-025
      color $white
      border 2px solid $white-trnsp-033
      border-radius 0.4rem
      font-size 2.2rem
      font-weight 400
      z-index 3
      margin-bottom 1rem
      padding 1.4rem 1.4rem 0 1.4rem
      text-align left
    }
    .selected {
      background $white-trnsp-025
    }
    .fare-count {
      position absolute
      bottom 1rem
      right 1rem
      padding 1.2rem
      font-weight 700
      font-size 3rem
    }
    .remove-fare-button {
      height 4rem
      width 4rem
      z-index 4
      background-color $white-trnsp-090
      border none
      border-radius 0.4rem
      color $black
    }
  }

  .passenger-off {
    display flex
    flex-grow 1
    align-items center
    justify-content center
    background-color $red-trnsp-22
    border 2px solid $red-trnsp-64
    border-radius 0.4rem
    color $white
    font-size 2.2rem
    font-weight 400
    padding 2rem
  }
}

@media (orientation: portrait) {
  #passenger-counting {
    .buttons-wrapper {
      flex-direction column
    }
    .fare-buttons {
      grid-template-columns 1fr 1fr
      // For odd number of elements, have the first button span 2 columns
      .fare-button:first-child:nth-last-child(1),
      .fare-button:first-child:nth-last-child(1) ~ .fare-button:first-child,
      .fare-button:first-child:nth-last-child(3),
      .fare-button:first-child:nth-last-child(3) ~ .fare-button:first-child,
      .fare-button:first-child:nth-last-child(5),
      .fare-button:first-child:nth-last-child(5) ~ .fare-button:first-child,
      .fare-button:first-child:nth-last-child(7),
      .fare-button:first-child:nth-last-child(7) ~ .fare-button:first-child {
          grid-column span 2
      }
    }
  }

  @media (min-width:800px) {
    #passenger-counting {
      .passenger-count {
        font-size 3.8rem
      }
      .fare-buttons {
        .fare-button {
          font-size 3.8rem
        }
      }
      .passenger-off {
        font-size 3.8rem
      }
    }
  }

  @media (max-width:599px) {
    #passenger-counting {
      .passenger-count {
        padding 0 1rem

        .passenger-count-reset {
          padding-right 2.5rem
        }
        .passenger-count-reset-button {
          padding 1.8rem 3.6rem
        }
      }
      .fare-buttons {
        .fare-button {
          font-size 1.8rem
          padding 0.8rem 0.8rem 0 0.8rem
        }
        .fare-count {
          display flex
          bottom 0
          right 0
          padding 0.4rem 0
          font-size 2.4rem
          margin-right 0.8rem
          justify-content center

          .remove-fare-button {
            display flex
            align-items center
            justify-content center
            height 2.6rem
            width 2.6rem
            margin-left 0.4rem
          }
          .count {
            position relative
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  #passenger-counting {
    .buttons-wrapper {
      flex-direction row
    }
    .fare-buttons {
      grid-template-columns repeat(12, 1fr)
      .fare-button:first-child:nth-last-child(2),
      .fare-button:first-child:nth-last-child(2) ~ .fare-button,
      .fare-button:first-child:nth-last-child(3),
      .fare-button:first-child:nth-last-child(3) ~ .fare-button,
      .fare-button:first-child:nth-last-child(4),
      .fare-button:first-child:nth-last-child(4) ~ .fare-button {
        grid-column span 6
      }
      .fare-button:first-child:nth-last-child(1),
      .fare-button:first-child:nth-last-child(1) ~ .fare-button:first-child
      .fare-button:first-child:nth-last-child(3),
      .fare-button:first-child:nth-last-child(3) ~ .fare-button:first-child {
        grid-column span 12
      }
      .fare-button:first-child:nth-last-child(5),
      .fare-button:first-child:nth-last-child(5) ~ .fare-button,
      .fare-button:first-child:nth-last-child(6),
      .fare-button:first-child:nth-last-child(6) ~ .fare-button {
        grid-column span 4
      }
      .fare-button:first-child:nth-last-child(5),
      .fare-button:first-child:nth-last-child(5) ~ .fare-button:first-child {
        grid-column span 8
      }
      .fare-button:first-child:nth-last-child(7),
      .fare-button:first-child:nth-last-child(7) ~ .fare-button,
      .fare-button:first-child:nth-last-child(8),
      .fare-button:first-child:nth-last-child(8) ~ .fare-button {
        grid-column span 3
      }
      .fare-button:first-child:nth-last-child(7),
      .fare-button:first-child:nth-last-child(7) ~ .fare-button:first-child {
        grid-column span 6
      }
    }
    .passenger-off {
      margin 0 0 1rem 1rem
    }
  }

  @media (min-height:800px) {
    #passenger-counting {
      .passenger-count {
        font-size 3.8rem
      }
      .fare-buttons {
        .fare-button {
          font-size 3.8rem
        }
      }
      .passenger-off {
        font-size 3.8rem
        padding 2rem
      }
    }
  }

  @media (max-height:599px) {
    #passenger-counting {
      .passenger-count {
        font-size 2.0rem
        padding 0 1rem

        .passenger-count-reset {
          padding-right 2.5rem
        }

        .passenger-count-reset-button {
          padding 1.8rem 3.6rem
        }
      }
      .fare-buttons {
        .fare-button {
          font-size 1.8rem
          padding 0.8rem 0.8rem 0 0.8rem
        }
        .fare-count {
          display flex
          bottom 0
          right 0
          padding 0.4rem 0
          font-size 2.4rem
          margin-right 0.8rem
          justify-content center

          .remove-fare-button {
            display flex
            align-items center
            justify-content center
            height 2.6rem
            width 2.6rem
            margin-left 0.4rem
          }
          .count {
            position relative
          }
        }
      }
    }
  }
}
</style>
